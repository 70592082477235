import React from "react";
import PropTypes from "prop-types";
import {
  Col,
  Form,
  FormGroup,
  InputGroup,
  ControlLabel,
  FormControl,
  Modal,
  Fade,
  utils,
  Button,
} from "react-bootstrap";
import { FieldGroup } from "../bootstrap/Forms.FieldGroup";
import { ENDPOINTS } from "../../constants/endpoints";
import { formDataToJSON } from "../../adukku_utils/array";

export default class AddLayout extends React.Component {
  constructor(props) {
    super(props);
    this.showCreateLayoutOverlay = this.showCreateLayoutOverlay.bind(this);
    this.hideCreateLayoutOverlay = this.hideCreateLayoutOverlay.bind(this);
    this.state = {
      product: props.product,
      widthI: 12,
      heightI: 10,
      width: 1200,
      height: 1000,
      overlay: props.overlay || { visible: false },
      layoutCategories: [],
    };
    this._handleValidSubmit = this._handleValidSubmit.bind(this);
    this._handleInputChange = this._handleInputChange.bind(this);

    // this.handleOrderChange = this.handleOrderChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps !== this.state) {
      this.setState(nextProps);
    }
  }

  showCreateLayoutOverlay(e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.showOverlay(this.state.overlay_id);
  }

  hideCreateLayoutOverlay(e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.hideOverlay(this.state.overlay_id);
  }


  _handleInputChange(e) {
    e.preventDefault();
    e.stopPropagation();
    const target = e.target;
    if (target.value>24) {
      alert("Value cannot be greater than 24 Inches!");
    }
    else if
     (target.value<1) {
      alert("Value cannot be less than 1 Inches!");
    }
    else if
     (target.name == "widthI") {
      this.setState({
        ["width"]: Math.round(target.value * 100),
        ["widthI"]: target.value
      });
    }
    else if (target.name == "heightI") {
      this.setState({
        ["height"]: Math.round(target.value * 100),
        ["heightI"]: target.value 
      });
    }
  }

  _handleValidSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    let formData = formDataToJSON(new FormData(e.target));
    formData.desc = formData.desc.trim()
    let endpoint = ENDPOINTS["layouts"]["create"];
    let url = endpoint.uri;
    let method = endpoint.method;
    this.props.createLayout(url, method, formData);
  }

  render() {
    const isVisible = this.state.overlay.visible;
    const product = this.state.product;

    return (
      <ul>
        <li>
          <a onClick={this.showCreateLayoutOverlay}> Create New layout </a>
          <Modal show={isVisible} onHide={this.hideCreateLayoutOverlay}>
            <Form className="override_form" onSubmit={this._handleValidSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>Create New layout</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <h5>Width (in Inches): </h5>
              <div className="input-group">
                <input type="number" min="1" max="24" step=".01"
                  id="formControlName"
                //  type="number" 
                  label="Width (in Inches):"
                  name="widthI"
                  placeholder="WidthI"
                  required="required"
                  className="form-control"
                  value={this.state.widthI}
                onChange={this._handleInputChange}
                ></input>
                <span className="input-group-addon">
                Width in Pixels :</span>
                <span className="input-group-addon"
                id="formControlName"
                type="number"
                label="Width (in pixels):"
                name="width"
                placeholder="Width" aria-readonly
                // className="form-control"
                value={this.state.width}
              >{this.state.width}</span>

                </div>
                <br></br>
                
                <h5>Height (in Inches): </h5>
                <div className="input-group">
                <input  id="formControlName"
                  type="number" min="1" max="24" step=".01"
                  label="Height (in pixels):"
                  name="heightI"
                  placeholder="Height"
                  required="required"
                  className="form-control"
                  value={this.state.heightI}
                  onChange={this._handleInputChange}
                ></input>
                <span className="input-group-addon">
                Height in Pixels :</span>
                <span className="input-group-addon"
                  type="number"
                  label="Height (in pixels):"
                  name="height"
                  placeholder="Height" aria-readonly
                  //className="form-control"
                  value={this.state.height}
                >{this.state.height}</span>
                </div>
                <br></br>
                <FieldGroup
                  id="formControlPages"
                  type="text"
                  pattern="[a-zA-Z0-9_\-' ]*"
                  title="Only Alphabets,numbers,',-,_ and space allowed"                  
                  label="Description:"
                  name="desc"
                  placeholder="Description"
                  required="required"
                />

                <FieldGroup type="hidden" name="product_id" defaultValue={this.state.product_id} />
                <FieldGroup type="hidden" name="width" defaultValue={this.state.width} />
                <FieldGroup type="hidden" name="height" defaultValue={this.state.height} />
                <FieldGroup
                  id="formControlPages"
                  type="color"
                  label="Background Color:"
                  name="bgcolor"
                  defaultValue="#949599"
                  placeholder="Background Color"
                  required="required"
                />
              </Modal.Body>
              <Modal.Footer>
                <Button type="submit">Save</Button>
                {/* <Button onClick={this.hideCreateLayoutOverlay}>Cancel</Button> */}
              </Modal.Footer>
            </Form>
          </Modal>
        </li>
      </ul>
    );
  }
}
