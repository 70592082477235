import React from 'react';
import { Col, Row } from 'react-bootstrap';

export default class EditLyDlRel extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state !== nextProps) {
      this.setState(nextProps);
    }
  }

  render() {
    const attribute = this.state.attribute;
    const dlRelKey = this.state.dlRelKey;
    return <div>
      <Row>
        <Col md={2}>{dlRelKey.key || attribute.name}</Col>
        <Col md={2}>
          <FormControl
            componentClass="input"
            placeholder="please select"
            required="required"
            defaultValue={dlRelKey.order}
            name={"lydlrelkey[" + this.state.index + "][order]"} />
          <FormControl
            componentClass="hidden"
            required="required"
            defaultValue={dlRelKey.key}
            name={"lydlrelkey[" + this.state.index + "][key]"} />
        </Col>
        <Col md={2}>
          <input type="checkbox"
            className="form-control"
            name={"lydlrel[" + this.state.index + "][selected]"}
            defaultValue={1}
            defaultChecked={dlRelKey.key == null} />
        </Col>
      </Row>
    </div>;
  }
};