import React, { Component } from "react";
import { TOGGLE_PRODUCT_EXPAND } from "../../actions";
import PaymentContainer from "../../containers/orders/PaymentContainer";

class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }
  onExpandToggle = e => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ expand: !this.state.expand });
    this.props.toggleView({
      reducerKey: TOGGLE_PRODUCT_EXPAND,
      id: this.props.product.id
    });
    this.props.getSets(this.props.product.id);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.product !== this.state.product) {
      this.setState({ product: nextProps.product });
    }
  }

  componentDidMount() {
    this.props.getPrice(this.props.product.id);
  }

  getPaymentDetails = (order, product) => {
    if (
      (order && order.status == "paid") ||
      (product.pricing && product.pricing.price_for_files === 0)
    ) {
      return (
        <ul>
          <li>Download</li>
        </ul>
      );
    } else if (order && order.status == "pending") {
      return (
        <ul>
          <PaymentContainer
            product_id={product.id}
            pg_order_id={order.pg_order_id}
          />
        </ul>
      );
    } else {
      return (
        <ul>
          <PaymentContainer product_id={product.id} />
        </ul>
      );
    }
  };

  getCartDetails = (order, product) => {
    if (
      (order && order.status == "paid") ||
      (product.pricing && product.pricing.price_for_files === 0)
    ) {
      return (
        <ul>
          <li>Download</li>
        </ul>
      );
    } else {
      return (
        <ul>
          <li>
            {this.isProductAddToCart(product.id) ? (
              "Added to cart"
            ) : (
              <a
                className="no-textcolor"
                onClick={() => {
                  this.props.addToCart({ product_id: product.id });
                }}
              >
                Add to Cart
              </a>
            )}
          </li>
        </ul>
      );
    }
  };

  isProductAddToCart = product_id => {
    const { cart_product } = this.state;
    if (cart_product) {
      let sets =
        cart_product &&
        cart_product.order_details &&
        cart_product.order_details.sets;
      if (Object.keys(sets).includes("all")) return true;
      return false;
    }
    return false;
  };

  getOrderDetails(product) {
    const pricing = product.pricing;
    const order = product.order;
    if (order && order.status === "paid") {
      return (
        <ul>
          <li>No. of files: {order.num_files}</li>
          <li>Total Amount: {order.total_amount}</li>
        </ul>
      );
    } else if (pricing) {
      return (
        <ul>
          <li>
            {pricing && pricing.pages_count && pricing.pages_count.total} Files
          </li>
          <li>Total Amount: {pricing.price_total}</li>
        </ul>
      );
    }
  }

  render() {}
}

export default Cart;
