import {connect} from 'react-redux';
import {getProductDatalevels} from '../../actions';
import {getKeys ,deleteKey} from '../../actions/product_keys';
import ProductKey from '../../components/product_keys/ProductKey';
import {bindActionCreators} from 'redux';
import {
 showOverlay
 ,selectDatalevel
} from '../../actions/misc_actions';

const mapStateToProps = (state, ownProps) => {
  let datalevels = state.product_datalevels;
  let product = state.products.filter(p => p.id == ownProps.product_id)[0];
  let datalevel = datalevels.filter(
    datalevel => (datalevel.product_id == ownProps.product_id && datalevel.dltype == 'main')
  )[0] || {};
  const product_keys = ((state.product_keys[ownProps.product_id] || {})[datalevel.dlname] || []);
  return Object.assign({}, ownProps, {product_keys: product_keys, product: product, product_datalevel: datalevel, overlay: (state.overlays[ownProps.product_id] || { visible: false } ) });
};

const mapDispatchToProps = (dispatch) =>bindActionCreators({
  deleteKey
 ,getProductDatalevels
 ,showOverlay
 ,selectDatalevel
},dispatch);


const ProductKeyContainer = connect(mapStateToProps, mapDispatchToProps)(ProductKey);

export default ProductKeyContainer