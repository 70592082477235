import React from "react";
import PaymentContainer from "../../containers/orders/PaymentContainer";

export default class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      setno: props.setno,
      product: props.product,
      pageno: props.pageno,
      layout: props.layout
    };
  }
  onExpandToggle = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ expand: !this.state.expand });
  };

  UNSAFE_componentWillReceiveProps() {}

  UNSAFE_componentWillMount() {}

  getOrderDetails(pricing, pageno) {
    let charges =
      pricing.pg_charges.transaction_fee + pricing.pg_charges.fixed_fee;
    let first_page_price = pricing.charges.first_page;
    let non_first_page_price = pricing.charges.non_first_page;
    let page_price = pageno === 1 ? first_page_price : non_first_page_price;
    if (pricing && pricing.has_main_page) {
      return (
        <React.Fragment>
          <li>Price for page: {page_price}</li>
          <li>Total Amount: {page_price} + Additional charges</li>
          <li>{this.getCartDetails(pageno)}</li>
        </React.Fragment>
      );
    }
  }

  getCartDetails = pageno => {
    pageno = pageno.toString();
    let product_order = this.state.product.order;
    let product_set = this.props.product_set;
    if (product_order.status === "paid") {
      return "Download";
    } else if (product_set && product_set.order) {
      let page_order_status = product_set.order.page_no;
      let page_nos =
        product_set.order && product_set.order.page_no
          ? Object.keys(product_set.order.page_no)
          : null;

      if (
        (page_nos &&
          (page_nos.includes(pageno) &&
            page_order_status[pageno] === "paid")) ||
        (page_nos && page_nos.length === 0)
      ) {
        return "Download";
      } else {
        return "Add to Cart";
      }
    } else {
      return "Add to Cart";
    }
  };

  getOrderStatus(pageno) {
    pageno = pageno.toString();
    let product_order = this.state.product.order;
    let product_set = this.props.product_set;
    if (product_order.status === "paid") {
      return "Download";
    } else if (product_set && product_set.order) {
      let page_order_status = product_set.order.page_no;
      let page_nos =
        product_set.order && product_set.order.page_no
          ? Object.keys(product_set.order.page_no)
          : null;

      if (
        (page_nos &&
          (page_nos.includes(pageno) &&
            page_order_status[pageno] === "paid")) ||
        (page_nos && page_nos.length === 0)
      ) {
        return "Download";
      } else {
        return (
          <PaymentContainer
            product_id={this.props.product_id}
            setno={product_set.setno}
            pageno={pageno}
          />
        );
      }
    } else {
      return (
        <PaymentContainer
          product_id={this.props.product_id}
          setno={product_set.setno}
          pageno={pageno}
        />
      );
    }
  }

  render() {
    const product = this.state.product;
    const pricing = product.pricing;
    const set_layout = this.state.layout;
    const pageno = this.state.pageno;
    let expandView = [];

    if (set_layout.layout_id) {
      let t = (
        <li key={set_layout.layout_id}>
          <span>{"Page no " + pageno}</span>
          <table>
            <tbody>
              <tr>
                <td rowSpan="8">{/* <img src="bla" width="200" /> */}</td>
              </tr>
              <tr>
                <td>
                  <a
                    href={
                      "/products/" +
                    product.id +
                    "/sets/" +
                    set_layout.setno +
                    "/page_preview"
                      // "/products/" +
                      // product.id +
                      // "/sets/" +
                      // set_layout.setno +
                      // "/" +
                      // set_layout.pageno +
                      // "/page_preview"
                    }
                  >
                    Preview final product
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </li>
      );
      expandView.push(t);
    }
    return <React.Fragment key={pageno}>{expandView}</React.Fragment>;
  }
}
