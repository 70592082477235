import { connect } from "react-redux";
import LayoutListComponent from "../../components/layouts/LayoutList";
import { bindActionCreators } from "redux";
import { getLayouts } from "../../actions/layouts";

const mapStateToProps = (state, ownProps) => {
  const product = state.products.filter((p) => p.id == ownProps.product_id)[0];
  const layouts = state.layouts.filter(
    (p) => p.product_id == ownProps.product_id
  );
  return Object.assign({}, ownProps, { layouts: layouts, product: product });
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getLayouts,
    },
    dispatch
  );

const LayoutListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LayoutListComponent);

export default LayoutListContainer;
