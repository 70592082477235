import { connect } from "react-redux";
import { getKeys } from "../../actions/product_keys";
import ProductKeyList from "../../components/product_keys/ProductKeyList";
import { bindActionCreators } from "redux";

const mapStateToProps = (state, ownProps) => {
  const role = ownProps.role;
  const product = state.products.filter(p => p.id == ownProps.product_id)[0];
  const product_role = product && product.product_role;
  const product_keys = state.product_keys[ownProps.product_id] || [];
  return {
    product: product,
    product_keys: product_keys,
    product_role: product_role,
    role: role
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getKeys
    },
    dispatch
  );

const ProductKeyListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductKeyList);

export default ProductKeyListContainer;
