import React from "react";
import { Col, Row } from "react-bootstrap";
import { ENDPOINTS } from "../../../constants/endpoints";

export default class LyDlRel extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
    this.onDelete = this.onDelete.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state !== nextProps) {
      this.setState(nextProps);
    }
  }

  componentDidMount() {
    let presetProductId = localStorage.getItem("preset_product_id");
    const isPresetProduct =
      !presetProductId || presetProductId == "null" || presetProductId == "undefined"
        ? false
        : true;
    this.setState({
      isPresetProduct: isPresetProduct,
    });
  }

  onDelete(e) {
    const layout = this.state.layout;
    const product = this.state.product;
    const dlRel = this.state.dlRel;
    const dlRelKey = this.state.dlRelKey;
    let formData = Object.assign({}, dlRelKey, {});
    let endpoint = Object.assign({}, ENDPOINTS.layout_datalevels_keys.delete, {});
    let url = endpoint.uri.replace(":layout_id", layout.id);
    url = url.replace(":pdlname", dlRel.pdlname);
    url = url.replace(":cdlname", dlRel.cdlname);
    url = url.replace(":key", formData.key);
    formData.pdlname = dlRel.pdlname;
    formData.cdlname = dlRel.cdlname;
    formData.product_id = product.id;
    formData.layout_id = layout.id;
    this.props.deleteLyDLRelKey(url, endpoint.method, formData);
  }
  render() {
    const dlRelKey = this.state.dlRelKey;
    const isPresetProduct = this.state.isPresetProduct;
    const product = this.state.product;
    return (
      <div>
        <Row>
          <Col md={3}>{dlRelKey.key}</Col>
          <Col md={3}>{dlRelKey.order}</Col>
          <Col md={3}>
            {!isPresetProduct && product.product_state == "edit" && (
              <a onClick={this.onDelete} title="Delete" style={{ marginLeft: "10px" }}>
                <img src="/icons/delete.png" alt="deleteIcon" style={{ width: "17px" }} />
              </a>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}
