export const GET_PRICE = "GET_PRICE";
export const ADD_PRICE = "ADD_PRICE";
export const GENERATE_IMAGE = "GENERATE_IMAGE";
export const GENERATE_IMAGE_SAGA = "GENERATE_IMAGE_SAGA";
export const GET_VIEW_PRODUCTS = "GET_VIEW_PRODUCTS";
export const ADD_VIEW_PRODUCTS = "ADD_VIEW_PRODUCTS";
export const SHARE_PRODUCT = "SHARE_PRODUCT";
export const ADD_SHARE_PRODUCT = "ADD_SHARE_PRODUCT";
export const GET_ORDERS = "GET_ORDERS";
export const GET_ORDERS_SAGA = "GET_ORDERS_SAGA";
export const ADD_TO_CART = "ADD_TO_CART";
export const ADD_TO_CART_SAGA = "ADD_TO_CART_SAGA";

export const getPrice = (product_id, setno) => {
  setno = setno === undefined || setno === null ? "" : setno;
  return { type: GET_PRICE, product_id: product_id, setno: setno };
};

export const generateImage = (product_id, setno) => {
  return {
    type: GENERATE_IMAGE_SAGA,
    payload: Object.assign({}, {}, { product_id: product_id, setno: setno }),
  };
};

export const getViewProducts = () => {
  return { type: GET_VIEW_PRODUCTS };
};

export const shareProduct = (payload) => {
  return { type: SHARE_PRODUCT, payload: payload };
};

export const addToCart = (payload) => {
  return { type: ADD_TO_CART_SAGA, payload: payload };
};

export const getOrders = (payload) => {
  return { type: GET_ORDERS_SAGA, payload: payload };
};
