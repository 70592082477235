import { connect } from 'react-redux'
import LyDlRelKeyList from '../../../components/layouts/lydlrel/LyDlRelKeyList';

import {bindActionCreators} from 'redux';
const mapStateToProps = (state, ownProps) => {
  const product = state.products.filter(p => p.id == ownProps.product_id)[0];
  const layout = state.layouts.filter(p => p.id == ownProps.layout_id)[0];
  let dlRel = layout.datalevel_relations.filter(rel => rel.id == ownProps.dlrel_id)[0];
  return Object.assign({}, ownProps, {layout: layout, product: product, dlRel: dlRel});
};

const mapDispatchToProps = (dispatch) =>bindActionCreators({
},dispatch);

const LyDlRelKeyListContainer = connect(mapStateToProps, mapDispatchToProps)(LyDlRelKeyList);

export default LyDlRelKeyListContainer;