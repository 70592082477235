import React from "react";
import { FormControl, Modal, Button, Form } from "react-bootstrap";

import { ENDPOINTS } from "../../constants/endpoints";
// import { formDataToJSON } from "../../adukku_utils/array";

export default class EditProductKey extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
    this.hideOverlay = this.hideOverlay.bind(this);
    this.showOverlay = this.showOverlay.bind(this);
    this.saveProductKeys = this.saveProductKeys.bind(this);
    this.deleteAttribute = this.deleteAttribute.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state !== nextProps) {
      this.setState(nextProps);
    }
  }

  showOverlay(e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.showOverlay(this.state.product.id + "/keys");
  }

  hideOverlay(e) {
    // e.preventDefault();
    // e.stopPropagation();
    this.props.hideOverlay(this.state.product.id + "/keys");
  }

  saveProductKeys(e) {
    e.preventDefault();
    e.stopPropagation();
    // let formData = formDataToJSON(new FormData(e.target));
    // const product_keys = this.state.product_keys;
    const selectedKeys = this.state.selectedKeys;
    const attributes = this.state.product_datalevel.attributes;
    // let endpoint = ENDPOINTS["keys"]["create"];
    // let url = endpoint.uri;
    // let method = endpoint.method;
    let data = {};
    attributes.map((attr, i) => {
      // data["order"] = this.state.product_key_order;
      // data["attribute"] = formData["key[" + i + "][attribute]"];
      data["attribute"] = selectedKeys[attr.name] ? attr.name : null;
      data["product_id"] = this.state.product_id;
      data["dlname"] = this.state.product_datalevel.dlname;
      data["order"] = selectedKeys[data["attribute"]].order;
      if (data.attribute != null && data.order > 0) {
        this.createProductKey(data);
      }
    });
  }

  deleteAttribute(attribute) {
    const product_id = this.state.product.id;
    const dlname = this.state.product_datalevel.dlname;
    const payload = {
      product_id: product_id,
      dlname: dlname,
      attribute: attribute,
    };
    const endpoint = ENDPOINTS["keys"]["delete"];
    const url = endpoint.uri;
    const method = endpoint.method;
    this.props.deleteKey(url, method, payload);
  }

  createProductKey(formData) {
    let endpoint = ENDPOINTS["keys"]["create"];
    let url = endpoint.uri;
    let method = endpoint.method;
    this.props.createKey(url, method, formData);
  }

  handleCheck(attribute, e) {
    let { selectedKeys, currentKeyOrder } = this.state;

    selectedKeys[attribute].checked = !selectedKeys[attribute].checked;

    if (selectedKeys[attribute].checked == false && selectedKeys[attribute].saved == true) {
      this.deleteAttribute(attribute);
    } else if (selectedKeys[attribute].checked == true && selectedKeys[attribute].order == 0) {
      currentKeyOrder += 1;
      selectedKeys[attribute].order = currentKeyOrder;
      this.setState({
        selectedKeys: selectedKeys,
        currentKeyOrder: currentKeyOrder,
      });
    } else if (selectedKeys[attribute].checked == false && selectedKeys[attribute].order > 0) {
      currentKeyOrder -= 1;
      selectedKeys[attribute].order = 0;
      this.setState({
        selectedKeys: selectedKeys,
        currentKeyOrder: currentKeyOrder,
      });
    }
  }

  render() {
    if (this.state.product_datalevel == null || this.state.product_datalevel.attributes == null) {
      return null;
    }
    const isVisible = this.state.overlay.visible;
    const dlname = this.state.product_datalevel.dlname;
    const attributes = this.state.product_datalevel.attributes;
    const product_keys = this.state.product_keys;
    const anchorDLDeleteStyle = {
      paddingLeft: "20px",
    };
    let tbodyDOM = null;
    let selectedKeys = this.state.selectedKeys;
    tbodyDOM = attributes.map((attr, i) => {
      let product_key =
        this.state.product_keys.filter((key) => key.attribute == attr.name)[0] || {};
      let attribute = product_key.attribute || attr.name;
      let defaultChecked = this.state.selectedKeys[attribute];
      if (defaultChecked == null) {
        return null;
      }
      return (
        <div className="each_attribute" key={attribute}>
          <p>{attribute}</p>
          <div className="right_attribute">
            <input
              type="checkbox"
              className="form-control override_checkbox"
              name={"key[" + i + "][selected]"}
              onChange={this.handleCheck.bind(this, attribute)}
              defaultChecked={defaultChecked.checked}
            />

            <FormControl
              type="number"
              name={"key[" + i + "][order]"}
              // disabled={true}
              value={selectedKeys[attribute].order}
              min={0}
              max={attributes.length}
              required="required"
              placeholder="key order"
              className="override_formcontrol"
            />
            <FormControl
              name={"key[" + i + "][attribute]"}
              type="hidden"
              disabled={defaultChecked.checked != true}
              defaultValue={attribute}
            />
          </div>
        </div>
      );
    });

    return (
      <li key={this.state.product.id + "/keys/li"}>
        <a onClick={this.showOverlay}>{this.props.labelText}</a>
        <Modal show={isVisible} onHide={this.hideOverlay}>
          <Form onSubmit={this.saveProductKeys} className="override_form">
            <Modal.Header closeButton>
              <Modal.Title>Modify Product Set Keys</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <label>Main Data Level Name:</label>
              <input
                type="text"
                className="form-control"
                readOnly
                placeholder="Main Data Level Name"
                value={dlname}
              />
              <div className="attributes_container">
                <div className="attributes_header">
                  <p>Attributes (Key)</p>
                  <p>Order</p>
                </div>
                <div className="attributes_body">{tbodyDOM}</div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button type="submit">Save</Button>
              {/* <Button onClick={this.hideOverlay}>Cancel</Button> */}
            </Modal.Footer>
          </Form>
        </Modal>
      </li>
    );
  }
}
