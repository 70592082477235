import { connect } from "react-redux";
import Payment from "../../components/orders/Payment";
import { bindActionCreators } from "redux";
import { getPrice, generateImage } from "../../actions/orders";

const mapStateToProps = (state, ownProps) => {
  return {
    product_id: ownProps.product_id,
    setno: ownProps.setno,
    pageno: ownProps.pageno,
    prtype: ownProps.prtype,
    numpages: ownProps.numpages
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getPrice,
      generateImage
    },
    dispatch
  );

const PaymentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Payment);

export default PaymentContainer;
