import { connect } from "react-redux";
import CartList from "../../components/cart/CartList";
import { getOrders, get } from "../../actions/orders";

import { getProducts } from "../../actions";
import { bindActionCreators } from "redux";

const mapStateToProps = (state) => {
  return {
    carts: state.carts,
    products: state.products,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getOrders,
      getProducts,
    },
    dispatch
  );

const CartListContainer = connect(mapStateToProps, mapDispatchToProps)(CartList);

export default CartListContainer;
