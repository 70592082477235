import { connect } from 'react-redux'
import EditLyDlRelList from '../../../components/layouts/lydlrel/EditLyDlRelList';
import {
 hideOverlay
 ,showOverlay
} from '../../../actions/misc_actions';
import { createLyDLRel, deleteLyDLRel } from '../../../actions/layouts';


import {bindActionCreators} from 'redux';
const mapStateToProps = (state, ownProps) => {
  const product = state.products.filter(p => p.id == ownProps.product_id)[0];
  const layout = state.layouts.filter(p => p.id == ownProps.layout_id)[0];
  let product_datalevels = state.product_datalevels.filter(dl => dl.product_id == ownProps.product_id);
  // let layout_datalevels = layout.layout_datalevels.filter(dl => dl.layout_id == ownProps.layout_id);
  return Object.assign({}, ownProps, {isAddVisible: false, layout: layout, product: product, product_datalevels: product_datalevels });
};

const mapDispatchToProps = (dispatch) =>bindActionCreators({
  hideOverlay
  ,showOverlay
  ,createLyDLRel
  ,deleteLyDLRel
},dispatch);

const EditLyDlRelListContainer = connect(mapStateToProps, mapDispatchToProps)(EditLyDlRelList);

export default EditLyDlRelListContainer;