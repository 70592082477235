import React, { Fragment } from "react";
import { Table, Form, Modal, Button } from "react-bootstrap";

import { ENDPOINTS } from "../../constants/endpoints";
import { FieldGroup } from "../bootstrap/Forms.FieldGroup";
import { formDataToJSON } from "../../adukku_utils/array";
import { authorized, Authorized } from "../../adukku_utils/authorizeUser";

export default class ProductSetKeyVal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props, editableField: null };
    this.onEditClick = this.onEditClick.bind(this);
    this.onExpandToggle = this.onExpandToggle.bind(this);
    this.hideAddSetOverlay = this.hideAddSetOverlay.bind(this);
    this._handleValidSubmit = this._handleValidSubmit.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state !== nextProps) {
      this.setState(nextProps);
    }
  }

  hideAddSetOverlay(e, dlval) {
    e.preventDefault();
    e.stopPropagation();
    this.props.hideOverlay(dlval.id);
  }

  onEditClick(e, dlval) {
    e.preventDefault();
    e.stopPropagation();
    this.props.showOverlay(dlval.id);
  }

  _handleValidSubmit(e, dlval) {
    e.preventDefault();
    e.stopPropagation();
    let formData = formDataToJSON(new FormData(e.target));
    if (dlval.dlcount == null) {
      let endpoint = ENDPOINTS["sets"]["create_dlval"];
      let url = endpoint.uri;
      let method = endpoint.method;
      this.props.createSetDLVal(url, method, formData);
    } else {
      let endpoint = ENDPOINTS["sets"]["update_dlval"];
      let url = endpoint.uri;
      let method = endpoint.method;
      this.props.updateSetDLVal(url, method, formData);
    }
    this.hideAddSetOverlay(e, dlval);
  }

  onExpandToggle(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ expand: !this.state.expand });
  }

  getExpandJSX = (expandFlag) => {
    return (
      <span>
        {expandFlag && <i className="icon-collapse" aria-hidden="true"></i>}
        {!expandFlag && <i className="icon-expand" aria-hidden="true"></i>}
      </span>
    );
  };

  render() {
    const { role, product_role } = this.state;
    const dlvals = this.state.dlvals;
    const isExpand = this.state.expand;
    let that = this;
    let isVisible = null;
    let view = [
      <a className="no-textcolor" onClick={this.onExpandToggle}>
        {this.getExpandJSX(isExpand)} {"PageNo " + this.state.pageno}
      </a>,
    ];
    if (isExpand) {
      let t = (
        <ul>
          <li>
            <Table hover className="TreeTable">
              <tbody>
                {dlvals.map((dlval, i) => {
                  return (
                    <tr>
                      <td>{dlval.dlname}</td>
                      <td>{dlval.dlcount < 0 ? "NA" : dlval.dlcount}</td>
                      <Authorized
                        userRoles={[role, product_role]}
                        requiredRoles={["admin", "owner"]}
                        roleType="ANY"
                      >
                        <td>
                          {!dlval.layout_id ? (
                            <a
                              onClick={(e) => this.onEditClick(e, dlval)}
                              style={{ paddingLeft: "15px" }}
                              title="Edit"
                            >
                              <img src="/icons/edit.png" style={{ width: "17px" }} />
                            </a>
                          ) : (
                            <Fragment></Fragment>
                          )}
                        </td>
                      </Authorized>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </li>
        </ul>
      );
      view.push(t);
      dlvals.map((dlval, i) => {
        isVisible = (that.state.overlays[dlval.id] || { visible: false }).visible;
        t = (
          <Modal
            show={isVisible}
            onHide={(e) => {
              that.hideAddSetOverlay(e, dlval);
            }}
          >
            <Form
              className="override_form"
              onSubmit={(e) => {
                that._handleValidSubmit(e, dlval);
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title>Add Occurence Count</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FieldGroup
                  id="formControlKeyName"
                  type="text"
                  label="Key:"
                  readonly="readonly"
                  name="dlname"
                  placeholder="Enter set no"
                  defaultValue={dlval.dlname}
                  required="required"
                />
                <FieldGroup
                  id="formControlKeyName"
                  type="hidden"
                  name="setno"
                  defaultValue={dlval.setno}
                  required="required"
                />
                <FieldGroup type="hidden" name="id" defaultValue={dlval.id} required="required" />
                <FieldGroup
                  id="formControlKeyName"
                  type="hidden"
                  name="product_id"
                  defaultValue={dlval.product_id}
                  required="required"
                />
                <FieldGroup
                  id="formControlKeyName"
                  type="hidden"
                  name="pageno"
                  defaultValue={dlval.pageno}
                  required="required"
                />
                {/* <FieldGroup
                    id="formControlKeyVal"
                    type="text"
                    label="Set Order"
                    name="setorder"
                    defaultValue={dlval.setorder}
                    placeholder="Enter order"
                    required="required"
                  /> */}
                <FieldGroup
                  id="formControlKeyVal"
                  type="number"
                  label="Occurence Count:"
                  name="dlcount"
                  defaultValue={dlval.dlcount}
                  placeholder="Enter Occurence count"
                  required="required"
                />
              </Modal.Body>
              <Modal.Footer>
                <Button type="submit">Submit</Button>
                {/* <Button
                  onClick={(e) => {
                    that.hideAddSetOverlay(e, dlval);
                  }}
                >
                  Cancel
                </Button> */}
              </Modal.Footer>
            </Form>
          </Modal>
        );
        view.push(t);
      });
    }
    return <li>{view}</li>;
  }
}
