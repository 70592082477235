import React from 'react';

export default class LyDl extends React.Component {
  constructor(props)
  {
    super(props);
    this.state = {...props};
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    if(this.state !== nextProps){
      this.setState(nextProps);
    }
  }

  render()
  {
    const lyDl = this.state.layout_datalevel;
    return <li>
      {lyDl.dlname}
    </li>;
  }
};