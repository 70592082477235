import { connect } from "react-redux";
import OrderProductSet from "../../components/orders/OrderProductSet";
import { bindActionCreators } from "redux";
import { getPrice, addToCart } from "../../actions/orders";




const mapStateToProps = (state, ownProps) => {
  let product_set = ownProps.product.product_sets.filter((p) => p.setno === ownProps.setno)[0];
  let counts = product_set[ownProps.main_dlname];
  let cart_product = null;
  let carts = state.carts || (state.orders && state.orders.carts);
  if (carts && carts.length > 0)
    cart_product = carts && carts.find((c) => c.product_id == ownProps.product_id);
  
  return Object.assign(
    {},
    {},
    {
      product: ownProps.product,
      setno: ownProps.setno,
      product_set: product_set,
      order: ownProps.product.order || {},
      layouts: ownProps.layouts,
      cart_product: cart_product,
      counts: counts,
      carts: state.carts,
      main_dlname: ownProps.main_dlname || ownProps.product.main_dlname,
    }
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getPrice,
      addToCart,
    },
    dispatch
  );


const OrderProductSetContainer = connect(mapStateToProps, mapDispatchToProps)(OrderProductSet);

export default OrderProductSetContainer;
