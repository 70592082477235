import { hideOverlay } from "../actions/misc_actions";
import { takeEvery } from "redux-saga/effects";
import { ENDPOINTS } from "../constants/endpoints";
import { put, call } from "redux-saga/effects";
import {
  getLayouts as getActionLayouts,
  getLayoutDetails,
} from "../actions/layouts";
import {
  ADD_ALL_LAYOUTS,
  CREATE_PRODUCT_LAYOUT,
  DELETE_PRODUCT_LAYOUT,
  UPDATE_PRODUCT_LAYOUT,
  GET_PRODUCT_LAYOUTS,
  GET_LAYOUT_DETAILS,
  ADD_LAYOUT,
  CREATE_LY_DATALEVEL,
  DELETE_LY_DATALEVEL,
  CREATE_LY_DATALEVEL_REL,
  DELETE_LY_DATALEVEL_REL,
  CREATE_LY_DATALEVEL_REL_KEY,
  DELETE_LY_DATALEVEL_REL_KEY,
  CREATE_LY_SLOTFOR,
  DELETE_LY_SLOTFOR,
  CLONE_PRODUCT_LAYOUT,
} from "../actions/layouts";

function* getLayouts(action) {
  const apiCall = (action) => {
    let url =
      ENDPOINTS.layouts.list.uri +
      "?product_id=" +
      encodeURIComponent(action.id);
    return fetch(url, action.payload)
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  try {
    const data = yield call(apiCall, action);
    yield put(
      Object.assign({}, action, {
        type: ADD_ALL_LAYOUTS,
        payload: data.layouts,
      })
    );
  } catch (error) {
    throw error;
  }
}

function* getLayout(action) {
  const apiCall = (action) => {
    let url = ENDPOINTS.layouts.show.uri;
    url = url.replace(":id", action.id);
    return fetch(url, action.payload)
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  try {
    const data = yield call(apiCall, action);
    yield put(
      Object.assign({}, action, { type: ADD_LAYOUT, payload: data.layout })
    );
  } catch (error) {
    throw error;
  }
}

function* createLayout(action) {
  const apiCall = (action) => {
    let payload = action.payload;
    let url = action.url;
    url = url.replace(":layout_id", payload.layout_id);
    let ajaxCall = fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: action.method,
      body: JSON.stringify(payload),
    });
    return ajaxCall
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  try {
    const data = yield call(apiCall, action);
    let payload = action.payload;
    const errors = data.errors;
    let overlay_id = payload.product_id + "/layouts";
    if (errors == null || errors.length == 0) {
      yield put(getActionLayouts(payload.product_id));
      yield put(hideOverlay(overlay_id));
    } else {
      setTimeout(function () {
        alert(errors.join("\n"));
      }, 100);
    }
  } catch (error) {
    throw error;
  }
}

function* updateLayout(action) {
  const apiCall = (action) => {
    let payload = action.payload;
    let url = action.url;
    url = url.replace(":id", action.id);
    let ajaxCall = fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: action.method,
      body: JSON.stringify(payload),
    });
    return ajaxCall
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  try {
    const data = yield call(apiCall, action);
    let payload = action.payload;
    const errors = data.errors;
    let overlay_id = payload.product_id + "/layouts";
    if (errors == null || errors.length == 0) {
      yield put(getActionLayouts(payload.product_id));
      yield put(hideOverlay(overlay_id));
    } else {
      setTimeout(function () {
        alert(errors.join("\n"));
      }, 100);
    }
  } catch (error) {
    throw error;
  }
}

function* cloneLayout(action) {
  const apiCall = (action) => {
    let payload = action.payload;
    let url = action.url;
    url = url.replace(":id", payload.id);
    let ajaxCall = fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: action.method,
      body: JSON.stringify(payload),
    });
    return ajaxCall
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  try {
    const data = yield call(apiCall, action);
    let payload = action.payload;
    const errors = data.errors;
    let overlay_id = payload.product_id + "/layouts";
    if (errors == null || errors.length == 0) {
      yield put(getActionLayouts(payload.product_id));
      yield put(hideOverlay(overlay_id));
    } else {
      setTimeout(function () {
        alert(errors.join("\n"));
      }, 100);
    }
  } catch (error) {
    throw error;
  }
}

function* deleteLayout(action) {
  const apiCall = (action) => {
    let payload = action.payload;
    let url = ENDPOINTS.layouts.show.uri;
    url = url.replace(":id", action.id);
    let ajaxCall = fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: action.method,
    });
    return ajaxCall
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  try {
    const data = yield call(apiCall, action);
    let payload = action.payload;
    const errors = data.errors;
    let overlay_id = payload.product_id + "/layouts";
    if (errors == null || errors.length == 0) {
      yield put(getActionLayouts(payload.product_id));
      yield put(hideOverlay(overlay_id));
    } else {
      setTimeout(function () {
        alert(errors.join("\n"));
      }, 100);
    }
  } catch (error) {
    throw error;
  }
}

function* deleteLyDl(action) {
  const apiCall = (action) => {
    let payload = action.payload;
    let url = action.url;
    url = url.replace(":layout_id", payload.layout_id);
    let ajaxCall = fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: action.method,
      body: JSON.stringify(payload),
    });
    return ajaxCall
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  try {
    const data = yield call(apiCall, action);
    let payload = action.payload;
    const errors = data.errors;
    if (errors == null || errors.length == 0) {
      yield put(getActionLayouts(payload.product_id));
    } else {
      setTimeout(function () {
        alert(errors.join("\n"));
      }, 100);
    }
  } catch (error) {
    throw error;
  }
}

function* createLyDL(action) {
  const apiCall = (action) => {
    let payload = action.payload;
    let url = action.url;
    url = url.replace(":layout_id", payload.layout_id);
    let ajaxCall = fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: action.method,
      body: JSON.stringify(payload),
    });
    return ajaxCall
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  try {
    const data = yield call(apiCall, action);
    let payload = action.payload;
    const errors = data.errors;
    if (errors == null || errors.length == 0) {
      yield put(getLayoutDetails(payload.layout_id));
    } else {
      setTimeout(function () {
        alert(errors.join("\n"));
      }, 100);
    }
  } catch (error) {
    throw error;
  }
}

function* deleteLyDL(action) {
  const apiCall = (action) => {
    let payload = action.payload;
    let url = action.url;
    url = url.replace(":layout_id", payload.layout_id);
    url = url.replace(":dlname", payload.dlname);
    let ajaxCall = fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: action.method,
      body: JSON.stringify(payload),
    });
    return ajaxCall
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  try {
    const data = yield call(apiCall, action);
    let payload = action.payload;
    const errors = data.errors;
    if (errors == null || errors.length == 0) {
      yield put(getLayoutDetails(payload.layout_id));
    } else {
      setTimeout(function () {
        alert(errors.join("\n"));
      }, 100);
    }
  } catch (error) {
    throw error;
  }
}

function* deleteLyDLRel(action) {
  const apiCall = (action) => {
    let payload = action.payload;
    let url = action.url;
    url = url.replace(":layout_id", payload.layout_id);
    url = url.replace(":pdlname", payload.pdlname);
    url = url.replace(":cdlname", payload.cdlname);
    let ajaxCall = fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: action.method,
      body: JSON.stringify(payload),
    });
    return ajaxCall
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  try {
    const data = yield call(apiCall, action);
    let payload = action.payload;
    const errors = data.errors;
    if (errors == null || errors.length == 0) {
      yield put(getLayoutDetails(payload.layout_id));
    } else {
      setTimeout(function () {
        alert(errors.join("\n"));
      }, 100);
    }
  } catch (error) {
    throw error;
  }
}

function* createLyDLRel(action) {
  const apiCall = (action) => {
    let payload = action.payload;
    let url = action.url;
    url = url.replace(":layout_id", payload.layout_id);
    url = url.replace(":pdlname", payload.pdlname);
    url = url.replace(":cdlname", payload.cdlname);
    let ajaxCall = fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: action.method,
      body: JSON.stringify(payload),
    });
    return ajaxCall
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  try {
    const data = yield call(apiCall, action);
    let payload = action.payload;
    const errors = data.errors;
    if (errors == null || errors.length == 0) {
      yield put(getActionLayouts(payload.product_id));
      yield put(getLayoutDetails(payload.layout_id));
    } else {
      setTimeout(function () {
        let err = errors.join("\n");
        err = err.replace("Relstyle", "Datalevel")
        alert(err);
      }, 100);
    }
  } catch (error) {
    throw error;
  }
}

function* createLyDLSlotFor(action) {
  const apiCall = (action) => {
    let payload = action.payload;
    let url = action.url;
    url = url.replace(":layout_id", payload.layout_id);
    let ajaxCall = fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: action.method,
      body: JSON.stringify(payload),
    });
    return ajaxCall
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  try {
    const data = yield call(apiCall, action);
    let payload = action.payload;
    const errors = data.errors;
    if (errors == null || errors.length == 0) {
      yield put(getLayoutDetails(payload.layout_id));
    } else {
      setTimeout(function () {
        alert(errors.join("\n"));
      }, 100);
    }
  } catch (error) {
    throw error;
  }
}

function* deleteLyDLSlotFor(action) {
  const apiCall = (action) => {
    let payload = action.payload;
    let url = action.url;
    url = url.replace(":layout_id", payload.layout_id);
    url = url.replace(":dlname", payload.dlname);
    let ajaxCall = fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: action.method,
      body: JSON.stringify(payload),
    });
    return ajaxCall
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  try {
    const data = yield call(apiCall, action);
    let payload = action.payload;
    const errors = data.errors;
    if (errors == null || errors.length == 0) {
      yield put(getLayoutDetails(payload.layout_id));
    } else {
      setTimeout(function () {
        alert(errors.join("\n"));
      }, 100);
    }
  } catch (error) {
    throw error;
  }
}

export function* createLayoutSaga() {
  yield takeEvery(CREATE_PRODUCT_LAYOUT, createLayout);
}

export function* updateLayoutSaga() {
  yield takeEvery(UPDATE_PRODUCT_LAYOUT, updateLayout);
}

export function* cloneLayoutSaga() {
  yield takeEvery(CLONE_PRODUCT_LAYOUT, cloneLayout);
}

export function* deleteLayoutSaga() {
  yield takeEvery(DELETE_PRODUCT_LAYOUT, deleteLayout);
}

export function* getLayoutListSaga() {
  yield takeEvery(GET_PRODUCT_LAYOUTS, getLayouts);
}

export function* getLayoutDetailsSaga() {
  yield takeEvery(GET_LAYOUT_DETAILS, getLayout);
}

export function* createLyDlSaga() {
  yield takeEvery(CREATE_LY_DATALEVEL, createLyDL);
}

export function* deleteLyDlSaga() {
  yield takeEvery(DELETE_LY_DATALEVEL, deleteLyDL);
}

export function* createLyDlSlotForSaga() {
  yield takeEvery(CREATE_LY_SLOTFOR, createLyDLSlotFor);
}

export function* deleteLyDlSlotForSaga() {
  yield takeEvery(DELETE_LY_SLOTFOR, deleteLyDLSlotFor);
}

export function* createLyDLRelSaga() {
  yield takeEvery(CREATE_LY_DATALEVEL_REL, createLyDLRel);
}

export function* deleteLyDlRelSaga() {
  yield takeEvery(DELETE_LY_DATALEVEL_REL, deleteLyDLRel);
}

export function* createLyDLRelKeySaga() {
  yield takeEvery(CREATE_LY_DATALEVEL_REL_KEY, createLyDLRel);
}

export function* deleteLyDlRelKeySaga() {
  yield takeEvery(DELETE_LY_DATALEVEL_REL_KEY, deleteLyDLRel);
}
