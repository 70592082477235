import {connect} from 'react-redux';
import ProductSetDLVal from '../../components/product_sets/ProductSetDLVal';
import {bindActionCreators} from 'redux';
import {
  toggleOverlay
 ,hideOverlay
 ,showOverlay
} from '../../actions/misc_actions'
import {updateSetDLVal, createSetDLVal} from '../../actions/product_sets'


const mapStateToProps = (state, ownProps) => {
  return Object.assign({}, ownProps, {overlays: state.overlays });
};

const mapDispatchToProps = (dispatch) =>bindActionCreators({
 hideOverlay
 ,showOverlay
 ,createSetDLVal
 ,updateSetDLVal
},dispatch);


const ProductSetDLValContainer = connect(mapStateToProps, mapDispatchToProps)(ProductSetDLVal);

export default ProductSetDLValContainer;