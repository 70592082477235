import React, { Component } from "react";
import OrderProductSetContainer from "../../containers/orders/OrderProductSetContainer";
import { ENDPOINTS } from "../../constants/endpoints";


class OrderProductSetList extends Component {
  constructor(props) {
    super(props);
    let customState = { ...props, dbKeyVals: []  };
    this.state = customState;
    this.getKeyValsfromDB();
  }


  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps !== this.state) {
      this.setState(nextProps);
    }
  }

 UNSAFE_componentWillMount() {
  }

  getKeyValsfromDB = () => {
    let keyVals_uri = ENDPOINTS.productkeyval.index.uri;
    var product_id = this.props.product.id;
    
    var where =  "product_id=" + product_id;
    
    let localKeyVals = [];
    
    keyVals_uri = `${keyVals_uri}?where=${where}`;
  
    let noRecords;
    
    (async () => {
       noRecords =  await fetch(keyVals_uri, {
          method: "GET", headers: { "content-type": "application/json" }, 
         })
       .then((response) => response.json())
       .then((data) => { 
          this.setState({dbKeyVals: data});
      });
    })();     
  };
  


  render() {
    const product_sets = this.props.product_sets;
    const product = this.props.product;
    const order_status = this.props.product.order.status;
    if (product_sets == null) {
      return null;
    }
    return (
      <ul className="tree">
        {Object.keys(product_sets).map((k, i) => {
          let product_set = product_sets[k];
          let props = { ...product_set };
          props.product = product;
          props.main_dlname = this.props.main_dlname;
          props.dbKeyVals = this.state.dbKeyVals;
          return (
            <OrderProductSetContainer
              product_id={product.id}
              product_role={product.product_role}
              key={i}
              order_status={order_status}
              {...props}
            />
          );
        })}
      </ul>
    );
  }
}

export default OrderProductSetList;
