//Import the Action types
import {
    SETQTY,
    INCREASE,
    DECREASE,
  } from "./CartTypes.js";
  
  
  // The reducer is listening for an action, which is the type that we defined in the CartTypes.js file
  const CartReducer = (state, action) => {
    // The switch statement is checking the type of action that is being passed in
    switch (action.type) {
      // If the action type is SETQTY, we want to set the quantity with the payload
      case SETQTY:
        state.cartItems.quantity=action.payload;
        return {
          ...state,
          cartItems: state.cartItems,
        };
  
      // If the action type is INCREASE, we want to increase the quantity of the particular item in the cartItems array
      case INCREASE:
        state.cartItems.quantity++;
        return {
          ...state,
          cartItems: state.cartItems,
        };
    
      // If the action type is DECREASE, we want to decrease the quantity of the particular item in the cartItems array
      case DECREASE:
        state.cartItems.quantity--;
        return {
          ...state,
          cartItems: state.cartItems,
        };
  
  
      //Return the state if the action type is not found
      default:
        return state;
    }
  };
  
  export default CartReducer;
  