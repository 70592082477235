import { connect } from "react-redux";
import LayoutComponent from "../../components/layouts/Layout";
import { bindActionCreators } from "redux";
import { getLayoutDetails } from "../../actions/layouts";
import { deleteLayout, cloneLayout, updateLayout } from "../../actions/layouts";

const mapStateToProps = (state, ownProps) => {
  const product = state.products.filter((p) => p.id == ownProps.product_id)[0];
  const layout = state.layouts.filter((p) => p.id == ownProps.layout_id)[0];
  return Object.assign({}, ownProps, { layout: layout, product: product });
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getLayoutDetails,
      deleteLayout,
      cloneLayout,
      updateLayout,
    },
    dispatch
  );

const LayoutContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LayoutComponent);

export default LayoutContainer;
