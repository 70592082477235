import { connect } from "react-redux";
import { createProduct, getCategories } from "../actions";
import { hideOverlay, showOverlay } from "../actions/misc_actions";
import AddProduct from "../components/AddProduct";
import { bindActionCreators } from "redux";

const mapStateToProps = (state, ownProps) => {
  return Object.assign({}, ownProps, {
    overlay: state.overlays["addProduct"] || { visible: false },
    categories: state.categories.categories || [],
    sub_categories: state.categories.sub_categories || [],
  });
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createProduct,
      hideOverlay,
      showOverlay,
      getCategories,
    },
    dispatch
  );

const AddProductContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddProduct);

export default AddProductContainer;
