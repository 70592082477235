import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createSelector } from "reselect";
import ProductData from "../components/ProductData";

const mapStateToProps = (state, ownProps) => {
  const product = state.products.filter((p) => p.id == ownProps.product_id)[0];
  const datalevels = state.product_datalevels.filter((dl) => dl.product_id == ownProps.product_id);
  return Object.assign({}, {}, { product: product, product_datalevels: datalevels });
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const ProductDataContainer = connect(mapStateToProps, mapDispatchToProps)(ProductData);

export default ProductDataContainer;
