import { connect } from "react-redux";
import OrderProductSetList from "../../components/orders/OrderProductSetList";
import { bindActionCreators } from "redux";

const mapStateToProps = (state, ownProps) => {
  let product = state.products.filter((e) => e.id === ownProps.product_id)[0];
  let product_sets = Object.values(
    (state.product_sets && state.product_sets[ownProps.product_id]) || {}
  );
  const main_dl = ((state.product_datalevels && state.product_datalevels) || []).find(
    (pdl) => pdl.dltype === "main" && pdl.product_id == product.id
  );
  const main_dlname = (main_dl && main_dl.dlname) || product.main_dlname;
  product.product_sets =
    product.product_sets && product.product_sets.length > 0 ? product.product_sets : product_sets;
  return {
    product: product,
    product_sets: product.product_sets || product_sets,
    order_status: ownProps.order_status,
    main_dlname: main_dlname,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const OrderProductSetListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderProductSetList);

export default OrderProductSetListContainer;
