import React from "react";
import { Row, Col, Form, FormControl, Modal, Button } from "react-bootstrap";
import serialize from "form-serialize";

import EditLyDlRelKeyContainer from "../../../containers/layouts/lydlrel/EditLyDlRelKeyContainer";

export default class EditLyDlRelKeyList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
    this._handleValidSubmit = this._handleValidSubmit.bind(this);
    this.hideOverlay = this.hideOverlay.bind(this);
    this.showOverlay = this.showOverlay.bind(this);
  }

  hideOverlay(e) {
    e.stopPropagation();
    this.setState(Object.assign({}, this.state, { visible: false }));
  }

  showOverlay(e) {
    e.stopPropagation();
    this.setState(Object.assign({}, this.state, { visible: true }));
  }

  _handleValidSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    let formData = serialize(e.target, { hash: true, empty: true });
    console.debug(formData);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state !== nextProps) {
      this.setState(nextProps);
    }
  }

  render() {
    const layout = this.state.layout;
    const product = this.state.product;
    const relation_keys = this.state.dlRel.datalevel_relation_keys || [];
    const attributes = this.state.attributes;
    const isVisible = this.state.visible;
    if (attributes == null) {
      return null;
    }
    let children = [];
    if (isVisible) {
      for (let i = 0; i < attributes.length; i++) {
        let attribute = attributes[i];
        let dlRelKey = relation_keys.filter((rel_key) => rel_key.key == attribute)[0] || {};
        children.push(
          <Row key={"dlRel" + i}>
            <Col md={2}>{dlRelKey.key || attribute}</Col>
            <Col md={2}>
              <FormControl
                componentClass="input"
                type="number"
                placeholder="please select"
                required="required"
                defaultValue={dlRelKey.order}
                name={"lydlrelkey[" + i + "][order]"}
              />
              <FormControl
                componentClass="input"
                type="hidden"
                required="required"
                defaultValue={dlRelKey.key || attribute}
                name={"lydlrelkey[" + i + "][key]"}
              />
            </Col>
            <Col md={2}>
              <input
                type="checkbox"
                className="form-control"
                name={"lydlrelkey[" + i + "][selected]"}
                defaultValue={1}
                defaultChecked={dlRelKey.key != null}
              />
            </Col>
          </Row>
        );
      }
    }
    return (
      <li>
        <a onClick={this.showOverlay}>Manage Join keys</a>
        <Modal show={isVisible} onHide={this.hideOverlay}>
          <Form className="override_form" onSubmit={this._handleValidSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Manage Layout datalevel relationship keys</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <Row>
                  <Col md={2}>Join Key</Col>
                  <Col md={2}>Join Key Order</Col>
                  <Col md={2}>Action</Col>
                </Row>
                {children}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button type="submit">Save</Button>
              <Button onClick={this.hideOverlay}>Cancel</Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </li>
    );
  }
}
