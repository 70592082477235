import { connect } from "react-redux";
import ViewProduct from "../../components/view_products/ViewProduct";
import { toggleView } from "../../actions";
import { getSets } from "../../actions/product_sets";
import { bindActionCreators } from "redux";

const mapStateToProps = (state, ownProps) => {
  let product = state.products.filter(p => p.id == ownProps.product_id)[0];
  const expand = product.expand == null ? false : product.expand;
  return Object.assign(
    {},
    {},
    {
      product: product,
      expand: expand
    }
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getSets,
      toggleView
    },
    dispatch
  );

const ViewProductContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewProduct);

export default ViewProductContainer;
