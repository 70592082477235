import { connect } from "react-redux";
import CCavenuePayment from "../../components/orders/CCavenuePayment";
import { bindActionCreators } from "redux";
import { getPrice, generateImage } from "../../actions/orders";

const mapStateToProps = (state, ownProps) => {
  return {
    product_id: ownProps.product_id,
    setno: ownProps.setno,
    pageno: ownProps.pageno,
    no_of_files: ownProps.no_of_files,
    prtype: ownProps.prtype,
    numpages: ownProps.numpages
  };
};


const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getPrice,
      generateImage
    },
    dispatch
  );

const CCavenuePaymentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CCavenuePayment);

export default CCavenuePaymentContainer;
