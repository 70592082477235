import { connect } from 'react-redux'
import LyDlRelKey from '../../../components/layouts/lydlrel/LyDlRelKey';
import { deleteLyDLRelKey } from '../../../actions/layouts';

import {bindActionCreators} from 'redux';
const mapStateToProps = (state, ownProps) => {
  const product = state.products.filter(p => p.id == ownProps.product_id)[0];
  const layout = state.layouts.filter(p => p.id == ownProps.layout_id)[0];
  let dlRel = layout.datalevel_relations.filter(dlrel => dlrel.id == ownProps.dlrel_id)[0];
  let dlRelKey = dlRel.datalevel_relation_keys.filter(key => key.id == ownProps.dlrelkey_id)[0];
  return Object.assign({}, ownProps, {layout: layout, dlRel: dlRel, product: product, dlRelKey: dlRelKey });
};

const mapDispatchToProps = (dispatch) =>bindActionCreators({
  deleteLyDLRelKey
},dispatch);

const LyDlRelKeyContainer = connect(mapStateToProps, mapDispatchToProps)(LyDlRelKey);

export default LyDlRelKeyContainer;