import { put, takeEvery, all, fork } from "redux-saga/effects";
import {
  createProductSaga,
  deleteProductSaga,
  cloneProductSaga,
  fetchProductsSaga,
  fetchDatalevelsSaga,
  toggleSaga,
  updateResourceSaga,
  createResourceSaga,
  fetchCategoriesSaga,
  updateProductSaga,
  productUserSaga,
  addViewProductUserSaga,
  getViewProductUserSaga,
  getUploadedDataSaga,
  updateUploadedDataSaga,
} from "./products.saga";
import {
  fetchProductKeysSaga,
  createProductKeySaga,
  deleteProductKeySaga,
} from "./product_keys.saga";
import {
  getProductSetsSaga,
  createProductSetSaga,
  deleteProductSetSaga,
  getProductSetsPlaceholdersSaga,
  updateProductSetKeySaga,
  createProductSetKeySaga,
  createProductSetDLValSaga,
  updateProductSetDLValSaga,
  createProductSetLayoutSaga,
  deleteProductSetLayoutSaga,
  getSupportedLayoutsSaga,
  createSetOcurrenceCountSaga,
  updateSetOcurrenceCountSaga,
  updateProductSetSaga,
} from "./product_sets.saga";
import {
  getLayoutListSaga,
  createLayoutSaga,
  cloneLayoutSaga,
  deleteLayoutSaga,
  getLayoutDetailsSaga,
  createLyDlSaga,
  deleteLyDlSaga,
  createLyDlSlotForSaga,
  deleteLyDlSlotForSaga,
  createLyDLRelSaga,
  deleteLyDlRelSaga,
  createLyDLRelKeySaga,
  deleteLyDlRelKeySaga,
  updateLayoutSaga,
} from "./layouts.saga";
import {
  fetchProductsSaga as ofetchProductsSaga,
  toggleSaga as otoggleSaga,
  getProductSetsSaga as ogetProductSetsSaga,
  fetchPriceSaga,
  generateImageSaga,
  fetchViewProductsSaga,
  shareProductSaga,
  addToCartSaga,
  getOrdersSaga,
} from "./orders.saga";
import { updateSetOccurenceCount } from "../actions/product_sets";
export default function* rootSaga() {
  yield all([
    fork(fetchDatalevelsSaga),
    fork(fetchProductsSaga),
    fork(toggleSaga),
    fork(updateResourceSaga),
    fork(createResourceSaga),
    fork(fetchProductKeysSaga),
    fork(createProductKeySaga),
    fork(deleteProductKeySaga),
    fork(getProductSetsSaga),
    fork(createProductSetSaga),
    fork(deleteProductSetSaga),
    fork(updateProductSetSaga),
    fork(updateProductSetKeySaga),
    fork(createProductSetKeySaga),
    fork(updateProductSetDLValSaga),
    fork(createProductSetDLValSaga),
    fork(getProductSetsPlaceholdersSaga),
    fork(createProductSaga),
    fork(cloneProductSaga),
    fork(deleteProductSaga),
    fork(createProductSetLayoutSaga),
    fork(deleteProductSetLayoutSaga),
    fork(getSupportedLayoutsSaga),
    fork(getLayoutListSaga),
    fork(createLayoutSaga),
    fork(cloneLayoutSaga),
    fork(deleteLayoutSaga),
    fork(getLayoutDetailsSaga),
    fork(createLyDlSaga),
    fork(deleteLyDlSaga),
    fork(createLyDlSlotForSaga),
    fork(deleteLyDlSlotForSaga),
    fork(deleteLyDlRelSaga),
    fork(createLyDLRelSaga),
    fork(deleteLyDlRelKeySaga),
    fork(createLyDLRelKeySaga),
    fork(fetchCategoriesSaga),
    fork(updateProductSaga),
    fork(productUserSaga),
    fork(addViewProductUserSaga),
    fork(getViewProductUserSaga),
    fork(getUploadedDataSaga),
    fork(updateUploadedDataSaga),
    fork(createSetOcurrenceCountSaga),
    fork(updateSetOcurrenceCountSaga),
    fork(updateLayoutSaga),
    // fork(ofetchProductsSaga),
    // fork(otoggleSaga),
    fork(ogetProductSetsSaga),
    fork(fetchPriceSaga),
    fork(generateImageSaga),
    fork(fetchViewProductsSaga),
    fork(shareProductSaga),
    fork(addToCartSaga),
    fork(getOrdersSaga),
  ]);
}
