import React, { Component } from "react";
import { Col, Form, Modal, Button } from "react-bootstrap";

import { FieldGroup } from "./bootstrap/Forms.FieldGroup";
import { formDataToJSON } from "../adukku_utils/array";
import { authorized, Authorized } from "../adukku_utils/authorizeUser";

export default class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      email: props.edit_user && props.edit_user.email,
      login: props.edit_user && props.edit_user.login,
    };
  }
  _handleValidSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let formData = formDataToJSON(new FormData(e.target));
    if (formData["role"] === "editor") formData["name"] = formData.login;
    this.props.productUser(formData);
    this.setState({ show: false, view_pay_modal: false });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.edit_user !== nextProps.edit_user) {
      this.setState({
        ...nextProps,
        email: nextProps.edit_user && nextProps.edit_user.email,
        login: nextProps.edit_user && nextProps.edit_user.login,
      });
    }
  }

  render() {
    const { edit_user, email, login, role } = this.state;
    const style = {
      display: "none",
    };
    return (
      <React.Fragment>
        <Authorized userRoles={[role, role]} requiredRoles={["admin", "owner"]} roleType="ANY">
          <li>
            <a variant="primary" onClick={() => this.setState({ show: true })}>
              {edit_user ? "Edit Data Correction User" : "Add Data Correction User"}
            </a>
            <Modal show={this.state.show} size="lg" centered="true">
              <Form className="override_form" onSubmit={this._handleValidSubmit}>
                <Modal.Header closeButton>
                  <Modal.Title>Data Correction user</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Col md={12}>
                    <FieldGroup
                      id="formControlProduct"
                      type="text"
                      label="Product ID"
                      name="product_id"
                      placeholder="Product ID"
                      required="required"
                      style={style}
                      value={this.state.product_id}
                      readOnly={true}
                    />
                    <FieldGroup
                      id="formControlRole"
                      type="text"
                      name="role"
                      required="required"
                      style={style}
                      value={"editor"}
                      readOnly={true}
                    />
                    <FieldGroup
                      id="formControlEmail"
                      type="text"
                      label="Email"
                      name="email"
                      placeholder="Email address"
                      required="required"
                      disabled={edit_user ? true : false}
                      value={email ? email : ""}
                      onChange={({ target: { name, value } }) => {
                        this.setState({ [name]: value });
                      }}
                    />
                    <FieldGroup
                      id="formControlUsername"
                      type="text"
                      label="Username"
                      name="login"
                      placeholder="Username"
                      required="required"
                      disabled={edit_user ? true : false}
                      value={login ? login : ""}
                      onChange={({ target: { name, value } }) => {
                        this.setState({ [name]: value });
                      }}
                    />
                    <FieldGroup
                      id="formControlPassword"
                      type="password"
                      label="password"
                      name="password"
                      placeholder="Password"
                      required={this.props.is_password_set ? null : "required"}
                    />
                  </Col>
                  <hr></hr>
                </Modal.Body>
                <Modal.Footer>
                  <Button type="submit">Submit</Button>
                  <Button onClick={() => this.setState({ show: false })}>Close</Button>
                </Modal.Footer>
              </Form>
            </Modal>
          </li>
        </Authorized>
        <li>
          <a variant="primary" onClick={() => this.setState({ view_pay_modal: true })}>
            {this.props.is_password_set ? "Edit View & Pay user" : "Add View & Pay user"}
          </a>
          <Modal show={this.state.view_pay_modal} size="lg" centered="true">
            <Modal.Header closeButton>
              <Modal.Title>View & Pay User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form horizontal onSubmit={this._handleValidSubmit}>
                <Col md={12}>
                  <FieldGroup
                    id="formControlProduct"
                    type="text"
                    label="Product ID"
                    name="product_id"
                    placeholder="Product ID"
                    required="required"
                    style={style}
                    value={this.state.product_id}
                    readOnly={true}
                  />
                  <FieldGroup
                    id="formControlRole"
                    type="text"
                    name="role"
                    required="required"
                    style={style}
                    value={"viewer"}
                    readOnly={true}
                  />
                  <FieldGroup
                    id="formControlPassword"
                    type="password"
                    label="password"
                    name="password"
                    placeholder="Password"
                    required={this.props.is_password_set ? null : "required"}
                  />
                </Col>
                <hr></hr>
                <Button type="submit">Submit</Button>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => this.setState({ view_pay_modal: false })}>Close</Button>
            </Modal.Footer>
          </Modal>
        </li>
      </React.Fragment>
    );
  }
}
