import "babel-polyfill";
import React from "react";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import createLogger from "redux-logger";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../sagas/orders_index.saga";
import AdukkuApp from "../reducers/orders_reducer";
import ViewProductListContainer from "../containers/view_products/ViewProductListContainer";

//const logger = createLogger({});
const sagaMiddleware = createSagaMiddleware();
//const store = createStore(AdukkuApp, applyMiddleware(logger, sagaMiddleware)); // Bala
const store = createStore(AdukkuApp, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(rootSaga);

class ViewProducts extends React.Component {
  render() {
    return (
      <div style={{paddingTop:30, paddingLeft: 20}}>
      <Provider store={store}>
        <ViewProductListContainer />
      </Provider>
      </div>
    );
  }
}

export default ViewProducts;
