import React from "react";
import PropTypes from "prop-types";
import { ENDPOINTS } from "../constants/endpoints";
import EditProductDataContainer from "../containers/EditProductDataContainer";

export default class ProductData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isUploading: false,
      UploadinDataLevel: "",
      product: props.product,
      product_datalevels: props.product_datalevels,
      uploadedFileNames: {}
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fileInput = React.createRef();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.product !== this.state.product) {
      this.setState({ product: nextProps.product });
    }
    if (nextProps.product_datalevels !== this.state.product_datalevels) {
      this.setState({ product_datalevels: nextProps.product_datalevels });
    }
  }
  handleUploadFile = (e, index) => {
    let temp = {};
    temp[index] = e.target.files[0].name;
    this.setState({ uploadedFileNames: { ...this.state.uploadedFileNames, ...temp } });
  }

  handleSubmit(e, dlname) {
    e.preventDefault();
    this.setState({ isUploading: true, UploadinDataLevel: dlname, });
    // alert(
    //   `Selected file - ${
    //     this.fileInput.current.files[0].name
    //   }`
    // );
    let $f = $(e.target);
    const product = this.state.product;
    const that = this;
    let file = $f.find("input:file")[0].files[0];
    let template_url = ENDPOINTS.csv.upload;
    template_url = template_url
      .replace(":product_id", product.id)
      .replace(":dlname", dlname);
    let f2 = new FormData(e.target);
    f2.append("file", file);
    let $ajax = $.ajax({
      url: template_url,
      type: "POST",
      method: "POST",
      data: f2,
      processData: false,
      contentType: false
    });
    $ajax.done(function () {
      that.setState({ isUploading: false, UploadinDataLevel: "",  });
      alert("Successfully, uploaded file");
    });
    $ajax.fail(function (jqXHR, textStatus, errorThrown) {
      that.setState({ isUploading: false, UploadinDataLevel: "",  });
      if (jqXHR && jqXHR.responseText) alert(jqXHR.responseText)
      else {
        if (jqXHR.status == 406) {
          alert("File size is in excess of 5MB\n\nPlease Upload file again with size less than 5MB");
        }
        else
          alert("An internal server error occured");
      }
    });
    $ajax.always(function () {
      $f.find("input:file").val("");
    });
  }
  render() {
    const product = this.state.product;
    const datalevels = this.state.product_datalevels;
    let template_url = ENDPOINTS.csv.download;
    template_url = template_url.replace(":product_id", product.id);
    if (datalevels == null || product == null) {
      return (
        <ul>
          <li>
            <strong>Internal error occured</strong>
          </li>
        </ul>
      );
    }
    return (
      <ul>
        {datalevels.map((p, i) => {
          return (
            <li key={p.dlname}>
              <table>
                <tbody>
                  <tr>
                    <td style={{ display: 'flex' }}>{p.dlname}</td>
                    <td>
                      <EditProductDataContainer
                        product_id={product.id}
                        dlname={p.dlname}
                      />

                    </td>
                    <td className="p-5 text-center">
                      <a
                        title="Download template"
                        href={template_url.replace(":dlname", p.dlname)}
                        _blank="true"
                        style={{ cursor: 'pointer' }}
                      >
                        <img src="icons/DownloadFileIcon.png" style={{ width: '30px' }} />
                      </a>
                    </td>
                    <td className="p-5">
                    {(this.state.isUploading && this.state.UploadinDataLevel == p.dlname) ?  <div style={{color: 'red'}}>Uploading Data...</div> : 
                      <form
                        className="mb-0"
                        onSubmit={e => {
                          this.handleSubmit(e, p.dlname);
                        }}
                        encType="multipart/form-data"
                      >
                        <label title="Upload Data" style={{ cursor: 'pointer', display: 'inline-block', marginRight: '10px', marginBottom: '0px' }}>
                        {this.state.isUploading ?  
                          <input type="submit" disabled = "disabled" value="upload" required style={{ display: 'none' }} /> :
                          <input type="submit" value="upload" required style={{ display: 'none' }} />
                        }
                          <img src="icons/UploadFileIcon.png" style={{ width: '30px' }} />
                        </label>
                        <label title="Choose Data File" id="Upload template" style={{ cursor: 'pointer', display: 'inline-block', marginBottom: '0px' }}>
                          <input type="file" accept=".csv" style={{ display: 'none' }} onChange={(e) => this.handleUploadFile(e, p.dlname)} />
                          <img src="icons/ChooseFileIcon.png" style={{ width: '30px' }} />
                        </label>
                      </form>
                    }
                    </td>
                    <td>
                      {this.state.uploadedFileNames[p.dlname]}
                    </td>
                  </tr>
                  {/* <tr>
                    <td>{p.dlname}</td>
                    <td>
                      <EditProductDataContainer
                        product_id={product.id}
                        dlname={p.dlname}
                      />
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </li>
          );
        })}
      </ul>
    );
  }
}
