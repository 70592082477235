import { connect } from 'react-redux'
import LySlotForList from '../../../components/layouts/lyslotfors/LySlotForList';

import {bindActionCreators} from 'redux';
const mapStateToProps = (state, ownProps) => {
  const product = state.products.filter(p => p.id == ownProps.product_id)[0];
  let layout = state.layouts.filter(p => p.id == ownProps.layout_id)[0];
  let datalevel_slotfors = layout.datalevel_slotfors.filter(slotfor => slotfor.slotforcode == ownProps.slotforcode);
  layout = Object.assign({}, layout, {});
  delete layout.datalevel_slotfors;
  delete layout.datalevel_relations;
  delete layout.datalevels;
  datalevel_slotfors = datalevel_slotfors.reduce((accumilator, slotfor)=> {
    accumilator[slotfor.dlname] = accumilator[slotfor.dlname] || [];
    accumilator[slotfor.dlname].push(slotfor);
    return accumilator;
  }, {});
  return Object.assign({}, ownProps, {layout: layout, product: product, dlnames: Object.keys(datalevel_slotfors)});
};

const mapDispatchToProps = (dispatch) =>bindActionCreators({
},dispatch);

const LySlotForListContainer = connect(mapStateToProps, mapDispatchToProps)(LySlotForList);

export default LySlotForListContainer;