import React from "react";
import LayoutContainer from "../../containers/orders/LayoutContainer"

export default class ViewProductSet extends React.Component {
  constructor(props) {
    super(props);
    let customState = { ...props };
    customState.expand = false;
    this.state = customState;
  }


  onExpandToggle = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ expand: !this.state.expand });
  };

  getExpandDetails(product, product_set) {
    let expandView = [];
    let t = [];
    for (let i = 1; i <= product.numpages; i++) {
      t.push(
        <LayoutContainer
          page={i}
          product_id={product.id}
          setno={this.state.setno}
          layout={this.state.layouts[i] || {}}
          pageno={i}
        />
      );
    }
    expandView.push(
      <li key={"layouts"}>
        Layouts <ul>{t}</ul>
      </li>
    );
    return expandView;
  }

  render() {
    const expand = this.state.expand;
    const setno = this.state.setno;
    const product = this.state.product;
    const product_set = this.state.product_set;
    return (
      <li key={setno}>
        <a onClick={this.onExpandToggle} className="no-textcolor">
          {setno}
        </a>
        <ul>{expand ? this.getExpandDetails(product, product_set) : null}</ul>
      </li>
    );
  }
}
