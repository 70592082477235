import { connect } from "react-redux";
import Order from "../../components/orders/Order";
import { toggleView } from "../../actions";
import { getSets } from "../../actions/product_sets";
import { bindActionCreators } from "redux";
import { getPrice, addToCart, getOrders } from "../../actions/orders";

const mapStateToProps = (state, ownProps) => {
  let product = state.products.filter((p) => p.id == ownProps.product_id)[0];
  let cart_product = null;
  let carts = state.carts || (state.orders && state.orders.carts);
  if (carts && carts.length > 0)
    cart_product = carts && carts.find((c) => c.product_id == ownProps.product_id);
  const expand = product.expand == null ? false : product.expand;
  return Object.assign(
    {},
    {},
    {
      product: product,
      expand: expand,
      cart_product: cart_product,
    }
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getSets,
      toggleView,
      getPrice,
      addToCart,
      getOrders,
    },
    dispatch
  );

const OrderContainer = connect(mapStateToProps, mapDispatchToProps)(Order);

export default OrderContainer;
