import React from "react";
import EditLyDlRelKeyContainer from "../../../containers/layouts/lydlrel/EditLyDlRelKeyContainer";
import {
  Row,
  Col,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Modal,
  Fade,
  utils,
  Button,
  Table,
} from "react-bootstrap";
import serialize from "form-serialize";
import { ENDPOINTS } from "../../../constants/endpoints";

export default class AddLyDlRelKey extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
    this._handleValidSubmit = this._handleValidSubmit.bind(this);
    this.hideOverlay = this.hideOverlay.bind(this);
    this.showOverlay = this.showOverlay.bind(this);
  }

  hideOverlay(e) {
    e.stopPropagation();
    this.setState(Object.assign({}, this.state, { visible: false }));
  }

  showOverlay(e) {
    e.stopPropagation();
    this.setState(Object.assign({}, this.state, { visible: true }));
  }

  _handleValidSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    let formData = serialize(e.target, { hash: true, empty: true });
    const layout = this.state.layout;
    const product = this.state.product;
    const dlRel = this.state.dlRel;
    let endpoint = Object.assign({}, ENDPOINTS.layout_datalevels_keys.create, {});
    let url = endpoint.uri.replace(":layout_id", layout.id);
    url.replace(":pdlname", dlRel.pdlname);
    url.replace(":cdlname", dlRel.cdlname);
    // url.replace(":relstyle", dlRel.style);
    formData.pdlname = dlRel.pdlname;
    formData.cdlname = dlRel.cdlname;
    formData.product_id = product.id;
    formData.layout_id = layout.id;
    formData.relstyle = dlRel.style;
    this.props.createLyDLRelKey(url, endpoint.method, formData);
    this.setState(Object.assign({}, this.state, { visible: false }));
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state !== nextProps) {
      this.setState(nextProps);
    }
  }

  componentDidMount() {
    let presetProductId = localStorage.getItem("preset_product_id");
    const isPresetProduct =
      !presetProductId || presetProductId == "null" || presetProductId == "undefined"
        ? false
        : true;
    this.setState({
      isPresetProduct: isPresetProduct,
    });
  }

  render() {
    const layout = this.state.layout;
    const product = this.state.product;
    const relation_keys = this.state.dlRel.datalevel_relation_keys || [];
    const attributes = this.state.attributes;
    const isVisible = this.state.visible;
    const dlRelKey = this.state.dlRelKey;
    let children = [];
    const isPresetProduct = this.state.isPresetProduct;
    children.push(
      <div>
        <Row>
          {/*<Col md={2}>
                  <FormControl
                    componentClass="input"
                    type="number"
                    placeholder="please select"
                    required="required"
                    defaultValue={dlRelKey.order}
                    name={ "order" }  />
                </Col>*/}
          <Col md={6}>
            <FormControl
              componentClass="select"
              placeholder="please select"
              required="required"
              defaultValue={dlRelKey.key}
              name={"key"}
            >
              {attributes.map((attribute, i) => {
                return (
                  <option key={i} value={attribute}>
                    {attribute}
                  </option>
                );
              })}
            </FormControl>
          </Col>
        </Row>
      </div>
    );
    return (
      <li>
        {!isPresetProduct && <a onClick={this.showOverlay}>Add key</a>}
        <Modal show={isVisible} onHide={this.hideOverlay}>
          <Form className="override_form" onSubmit={this._handleValidSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Add Layout datalevel relationship key</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                {/*<Col md={2}>Join Key Order</Col>*/}
                <Col md={2}>Join Key</Col>
              </Row>
              {children}
            </Modal.Body>
            <Modal.Footer>
              <Button type="submit">Save</Button>
              {/* <Button onClick={this.hideOverlay}>Cancel</Button> */}
            </Modal.Footer>
          </Form>
        </Modal>
      </li>
    );
  }
}
