import {
  GET_PRODUCT_KEYS
 ,CREATE_PRODUCT_KEY
 ,DELETE_PRODUCT_KEY
 ,DELETE_KEY
 ,ADD_KEY
 ,ADD_ALL_KEYS
} from "../actions/product_keys";
import {takeEvery} from 'redux-saga/effects';
import {ENDPOINTS} from "../constants/endpoints";
import {put, call} from 'redux-saga/effects';
import { hideOverlay } from "../actions/misc_actions";

function* fetchKeys(action) {
  const apiCall = (action) => {
    let url = ENDPOINTS.keys.list.uri;
    url = url.replace(":product_id", action.id);
    return fetch(url)
      .then(response => {
        return response.json()
      })
      .catch(error => {
        throw error;
      })
  };
  try {
    const data = yield call(apiCall, action);
    yield put(Object.assign({}, action, {type: action.reducerKey, payload: data.keys}));
  }
  catch (error) {
    throw  error;
  }
}

function* createKey(action) {
  const apiCall = (action) => {
    let payload = action.payload;
    let url = action.url;
    url = url.replace(":product_id", payload.product_id);
    url = url.replace(":dlname", payload.dlname);
    let ajaxCall = fetch(url, 
      { 
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        method: action.method,
        body: JSON.stringify(payload)
      }
    );
    return ajaxCall.then(response => {
      return response.json()
    })
    .catch(error => {
      throw error;
    });
  };
  try {
    const data = yield call(apiCall, action);
    let payload = action.payload;
    const errors = data.errors;
    if(errors == null || errors.length == 0)
    {
      yield* fetchKeys({ type: GET_PRODUCT_KEYS, id: payload.product_id, reducerKey: ADD_ALL_KEYS })
      yield put(hideOverlay(payload.product_id+"/keys"));
    }
    else
    {
      alert(errors.join("\n"));
    }
  }
  catch (error) {
    throw  error;
  }
}

function* deleteKey(action) {
  const apiCall = (action) => {
    let payload = action.payload
    let url = action.url;
    url = url.replace(":product_id", payload.product_id);
    url = url.replace(":dlname", payload.dlname);
    let ajaxCall = fetch(url, 
      { 
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        method: action.method,
        body: JSON.stringify(payload)
      }
    );
    return ajaxCall.then(response => {
      return response.json()
    })
    .catch(error => {
      throw error;
    });
  };
  try {
    const data = yield call(apiCall, action);
    let payload = action.payload;
    const errors = data.errors;
    if(errors == null || errors.length == 0)
    {
      yield* fetchKeys({ type: GET_PRODUCT_KEYS, id: payload.product_id, reducerKey: ADD_ALL_KEYS })
    }
    else
    {
      alert(errors.join("\n"));
    }
  }
  catch (error) {
    throw  error;
  }
}


export function* createProductKeySaga() {
  yield takeEvery(CREATE_PRODUCT_KEY, createKey);
}

export function* deleteProductKeySaga() {
  yield takeEvery(DELETE_PRODUCT_KEY, deleteKey);
}

export function* fetchProductKeysSaga() {
  yield takeEvery(GET_PRODUCT_KEYS, fetchKeys);
}