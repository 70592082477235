import { connect } from "react-redux";
import ProductDLAttr from "../components/ProductDLAttr";
import {
  toggleView,
  deleteDataLevelAttribute,
  createDataLevelAttribute
} from "../actions";
import { bindActionCreators } from "redux";

const mapStateToProps = (state, ownProps) => {
  return { attribute: ownProps };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      toggleView,
      deleteDataLevelAttribute,
      createDataLevelAttribute
    },
    dispatch
  );

const ProductDLAttrContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductDLAttr);

export default ProductDLAttrContainer;
