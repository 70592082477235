import React from "react";
import { FormGroup, ControlLabel, FormControl, HelpBlock } from "react-bootstrap";

export const FieldGroup = ({ id, label, help, ...props }) => {
  return (
    <FormGroup controlId={id} style={props.style}>
      <ControlLabel>{label}</ControlLabel>
      <FormControl {...props} />
      {help && <HelpBlock>{help}</HelpBlock>}
    </FormGroup>
  );
};
