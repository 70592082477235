import { connect } from 'react-redux';
import ShareProduct from "../../components/orders/ShareProduct"
import { bindActionCreators } from 'redux';
import { shareProduct } from "../../actions/orders";

const mapStateToProps = (state, ownProps) => {
    return Object.assign({}, {}, ownProps);
};
const mapDispatchToProps = (dispatch) => bindActionCreators({
    shareProduct
}, dispatch);

const ShareProductContainer = connect(mapStateToProps, mapDispatchToProps)(ShareProduct);

export default ShareProductContainer;