import React from "react";
import { Form, Modal, Button } from "react-bootstrap";

import { FieldGroup } from "../bootstrap/Forms.FieldGroup";
import { ENDPOINTS } from "../../constants/endpoints";
import { formDataToJSON } from "../../adukku_utils/array";

export default class AddProductSet extends React.Component {
  constructor(props) {
    super(props);
    this.showAddSetOverlay = this.showAddSetOverlay.bind(this);
    this.hideAddSetOverlay = this.hideAddSetOverlay.bind(this);
    this.state = {
      product: props.product,
      overlay: props.overlay || { visible: false },
    };
    this._handleValidSubmit = this._handleValidSubmit.bind(this);
    // this.handleOrderChange = this.handleOrderChange.bind(this);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps !== this.state) {
      this.setState(nextProps);
    }
  }

  showAddSetOverlay(e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.showOverlay(this.state.product.id + "_sets");
  }

  hideAddSetOverlay(e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.hideOverlay(this.state.product.id + "_sets");
  }

  _handleValidSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    let formData = formDataToJSON(new FormData(e.target));
    let endpoint = ENDPOINTS["sets"]["create"];
    let url = endpoint.uri;
    let method = endpoint.method;
    this.props.createSet(url, method, formData);
  }

  render() {
    const isVisible = this.state.overlay.visible;
    const product = this.state.product;
    return (
      <li>
        <a onClick={this.showAddSetOverlay}>{this.props.labelText}</a>
        <Modal show={isVisible} onHide={this.hideAddSetOverlay}>
          <Form onSubmit={this._handleValidSubmit} className="override_form">
            <Modal.Header closeButton>
              <Modal.Title>Create Product Set</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* <FieldGroup
                id="formControlSetNo"
                type="number"
                label="Set no"
                name="setno"
                placeholder="Enter set no"
                required="required"
              /> */}
              <FieldGroup
                id="formControlDesc"
                type="texarea"
                label="Name:"
                name="desc"
                placeholder="Enter Name"
                required="required"
              />
              <FieldGroup
                id="formControlProductId"
                name="product_id"
                type="hidden"
                value={product.id}
                required="required"
              />
            </Modal.Body>
            <Modal.Footer>
              <Button type="submit">Submit</Button>
              {/* <Button onClick={this.hideAddSetOverlay}>Cancel</Button> */}
            </Modal.Footer>
          </Form>
        </Modal>
      </li>
    );
  }
}
