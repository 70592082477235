import React from "react";
import { Modal } from "react-bootstrap";

import StoragePayment from "./StoragePayment";

export default class PaymentModal extends React.Component {
  render() {
    const { no_of_gb, storage_unit, convenienceCharges } = this.props.fields;
    return (
      <Modal
        centered="true"
        show={no_of_gb > 0}
        onHide={(e) => {
          this.props.resetStorage();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul style={{ listStyleType: "none" }}>
            <li>
              <strong>
                Total Price for {no_of_gb} GB: ${no_of_gb}
              </strong>
            </li>
            <li>
              <strong>
                Convenience Charges ({convenienceCharges * 100}%) : $
                {parseFloat(no_of_gb * convenienceCharges).toFixed(2)}
              </strong>
            </li>
            <li>
              <strong>Grand Total: $ {parseFloat(no_of_gb + no_of_gb * convenienceCharges)}</strong>
            </li>
          </ul>
          <StoragePayment no_of_gb={no_of_gb} storage_unit={storage_unit} />
        </Modal.Body>
      </Modal>
    );
  }
}
