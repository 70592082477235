import React from "react";
import PropTypes from "prop-types";
import ProductSetContainer from "../../containers/product_sets/ProductSetContainer";
import AddSetContainer from "../../containers/product_sets/AddProductSetContainer";
import { authorized, Authorized } from "../../adukku_utils/authorizeUser";

export default class ProductSetList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps !== this.state) {
      this.setState(nextProps);
    }
  }
  UNSAFE_componentWillMount() {
    this.props.getSets(this.state.product.id);
    this.props.getKeys(this.state.product.id);

    // let user_role = document.getElementById("user_role");
    // user_role = user_role.innerHTML;
    // this.setState({ role: user_role });
  }

  render() {
    const { role, product_role } = this.state;
    const product_sets = this.state.product_sets;
    const product = this.state.product;
    if (product_sets == null) {
      return null;
    }
    return (
      <ul className="tree">
        {Object.keys(product_sets).map((k, i) => {
          let product_set = product_sets[k];
          let props = { ...product_set };
          props.product = product;
          return (
            <ProductSetContainer
              key={i}
              {...props}
              role={role}
              product_role={product_role}
            />
          );
        })}

        <Authorized
          userRoles={[role, product_role]}
          requiredRoles={["admin", "owner"]}
          roleType="ANY"
        >
          <AddSetContainer
            product_id={this.state.product.id}
            overlay={this.state.overlay}
            labelText="Add New Set"
          />
        </Authorized>
      </ul>
    );
  }
}
