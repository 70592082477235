import { connect } from 'react-redux';
import AddUser from "../components/AddUser";
import { bindActionCreators } from 'redux';
import { productUser } from "../actions";
import { addViewProductUser } from "../actions";
import { getViewProductUser } from "../actions";

const mapStateToProps = (state, ownProps) => {
  return Object.assign({}, {}, ownProps);
};
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      productUser,
      addViewProductUser,
      getViewProductUser,
    },
    dispatch
  );

const AddUserContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddUser);

export default AddUserContainer;