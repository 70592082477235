import React, { Component } from "react";

export const authorized = function (requiredRoles, userRoles, type = "ANY") {
  if (userRoles.includes("preset")) {
    return false;
  }
  if (userRoles.includes("admin")) {
    return true;
  }

  if (type === "ANY") {
    return requiredRoles.some((role) => userRoles.includes(role));
  } else if (type === "ALL") {
    return !requiredRoles.some((role) => !userRoles.includes(role));
  }
};

export class Authorized extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    if (authorized(this.props.requiredRoles, this.props.userRoles, this.props.roleType || "ANY")) {
      return this.props.children;
    }
    return null;
  }
}
