import { connect } from "react-redux";
import ViewProductList from "../../components/view_products/ViewProductList";
import { getViewProducts } from "../../actions/orders";
import { bindActionCreators } from "redux";

const mapStateToProps = (state) => {
  return {
    products: state.products,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getViewProducts,
    },
    dispatch
  );

const ViewProductListContainer = connect(mapStateToProps, mapDispatchToProps)(ViewProductList);

export default ViewProductListContainer;
