import React from "react";
import ReactDOM from "react-dom";

let portalRoot = null;

class EditProductImage extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement("div");
  }

  componentDidMount() {
    portalRoot = document.getElementById(this.props.portalClass);
    this.el.className = "react_image_editor";
    portalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    portalRoot.removeChild(this.el);
  }

  render() {
    const { children } = this.props;
    return ReactDOM.createPortal(children, this.el);
  }
}

export default EditProductImage;
