import React from "react";
import PropTypes from "prop-types";
import ProductContainer from "../containers/ProductContainer";
import AddProductContainer from "../containers/AddProductContainer";
import FileMangerContainer from "../containers/FileManagerContainer";
import UserStorageContainer from "../containers/UserStorageContainer";
import AdminContainer from "../containers/AdminContainer";
import { ENDPOINTS } from "../constants/endpoints";

export default class ProductList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.products !== this.state.products) {
      this.setState({ products: nextProps.products });
    }
  }
  UNSAFE_componentWillMount() {
    let user_role = document.getElementById("user_role");
    user_role = user_role.innerHTML;
    let preset_product_id = document.getElementById("preset_product_id");
    preset_product_id = preset_product_id ? preset_product_id.innerHTML : null;
    user_role = preset_product_id ? "preset" : user_role;
    localStorage.setItem("preset_product_id", preset_product_id);
    this.setState({
      role: user_role,
      preset_product_id: preset_product_id || null,
    });
    if (preset_product_id) {
      this.props.getProducts({ type: "presets" });
    } else this.props.getProducts();
  }

  resetDefaults = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let uri = ENDPOINTS.user_storages.update_defaults.uri;
    let that = this;
    let formData = new FormData();
    formData.append("type", "reset_defults");
    return fetch(uri, {
      method: "PUT",
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.data == "success") alert("Space reset successful");
      });
  };

  render() {
    const { role, preset_product_id } = this.state;
    return (
      <div style={{ marginBottom: "20px" }}>
        {" "}
        <h3
          className="list-heading"
          style={{
            margin: "20px 0 0px 0",
            background: "#ccc",
            fontSize: "18px",
            padding: "5px 10px",
            textAlign: "center",
            userSelect: "none",
          }}
        >
          {preset_product_id ? "Presets" : "My Products"}
        </h3>
        <ul className="tree" style={{ padding: "15px 0" }}>
          {/* {this.state.products.map((p, i) => (
            <ProductContainer key={p.id} {...p} role={role} />
          ))} */}
          {this.state.products.map((p, i) => {
            if (p.is_archived) {
              return null;
            } else {
              return (
                <ProductContainer
                  key={p.id}
                  {...p}
                  role={p && p.product_state != "edit" ? "preset" : role}
                />
              );
            }
          })}

          {(role === "admin" || role === "owner") && (
            <AddProductContainer labelText="Add new product" products={this.state.products} />
          )}
          {!preset_product_id && (
            <React.Fragment>
              <FileMangerContainer labelText="View Image Files" products={this.state.products} />
              <UserStorageContainer labelText="Buy More storage" products={this.state.products} />
              {role === "admin" && (
                <AdminContainer labelText="Set Admin Defaults" products={this.state.products} />
              )}
              {role === "admin" && (
                <li>
                  <a onClick={this.resetDefaults}>{"Reset Defaults"}</a>
                </li>
              )}
            </React.Fragment>
          )}
        </ul>
      </div>
    );
  }
}
ProductList.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
      collapse: PropTypes.bool,
      product_datalevels: PropTypes.array,
    })
  ),
};
