import React from "react";
import LyDlRelKeyContainer from "../../../containers/layouts/lydlrel/LyDlRelKeyContainer";
import { Col, Row } from "react-bootstrap";
import AddLyDlRelKeyContainer from "../../../containers/layouts/lydlrel/AddLyDlRelKeyContainer";

export default class LyDlRelKeyList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
    this.toggleExpand = this.toggleExpand.bind(this);
  }

  toggleExpand(e) {
    e.stopPropagation();
    this.setState(Object.assign({}, this.state, { expand: !this.state.expand }));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state !== nextProps) {
      this.setState(nextProps);
    }
  }

  getExpandJSX = (expandFlag) => {
    return (
      <span>
        {expandFlag && <i className="icon-collapse" aria-hidden="true"></i>}
        {!expandFlag && <i className="icon-expand" aria-hidden="true"></i>}
      </span>
    );
  };

  render() {
    const layout = this.state.layout;
    const product = this.state.product;
    const dlRel = this.state.dlRel;
    const relation_keys = this.state.dlRel.datalevel_relation_keys;

    if (relation_keys == null) {
      return null;
    }
    const isExpand = this.state.expand;
    let children = [];
    if (isExpand) {
      for (let i = 0; i < relation_keys.length; i++) {
        children.push(
          <LyDlRelKeyContainer
            key={relation_keys[i].id}
            layout_id={layout.id}
            dlrel_id={this.state.dlRel.id}
            product_id={product.id}
            dlrelkey_id={relation_keys[i].id}
          />
        );
      }
    }
    return (
      <ul>
        <li>
          <a onClick={this.toggleExpand}>{this.getExpandJSX(isExpand)} Join keys</a>
          {isExpand && (
            <ul>
              <li>
                <div>
                  <Row>
                    <Col md={3}>Join Key</Col>
                    <Col md={3}>Join Key order</Col>
                    {product.product_state == "edit" && <Col md={3}>Action</Col>}
                  </Row>
                  {children}
                </div>
              </li>
              <AddLyDlRelKeyContainer
                dlRelKey={{}}
                dlrel_id={dlRel.id}
                layout_id={this.state.layout.id}
                product_id={this.state.product.id}
              />
            </ul>
          )}
        </li>
      </ul>
    );
  }
}
