import React, { Component } from "react";
import ReactDOM from "react-dom";

import { ENDPOINTS } from "../../constants/endpoints";

class CCStoragePayment extends Component {
  constructor(props) {
    super(props);
    this.state = { no_of_gb: 1, ...props };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {}

  
  componentDidMount() {
    // uncomment below line to generate a image in the background
    // this.props.generateImage(this.state.product_id, this.state.setno);
  }

  gst = 0.18;
  storage_amount = this.props.no_of_gb * 75 + this.props.no_of_gb * 10;
  gst_amount = this.storage_amount * this.gst;
  amount = Math.round(this.storage_amount + this.gst_amount).toFixed(2);

  render() {
    
    const style = {
      color: "green"
   };

   const style3 = {
    color: "white",
    fontSize: 17,
    background: "green"
 };

    return (
            <div style={style}>
              <form id="redirect" method="post" name="redirect" action="/storagetransactions" >
              <input type="hidden" name="amount" value={this.amount}/>
              <input type="hidden" name="no_of_gb" value={this.props.no_of_gb}/>
              <button type="submit" style={style3} className="btn btn-success">CCAvenue Checkout</button>
              </form>
            </div>
          );
  }
}

export default CCStoragePayment;
