import React, { useState } from 'react';
import ProductMynavbar from './ProductNavbar';
export class ProductComposzNavbar extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight
        };
        document.cookie = "cartCounter=25;";
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
}

  handleResize = e => {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    
    this.setState(prevState => {
        return {
        windowWidth,
        windowHeight,
        };
    });
  };
  
   Fnavbar = () => {
    let Usernavbar = ProductMynavbar(this.props.user_id, this.props.user_name, this.props.user_email, this.props.has_view_products)
    return Usernavbar
   }

  render() {
    return (
      <React.Fragment>
        <this.Fnavbar />
      </React.Fragment>  
      );
  }
}

export default ProductComposzNavbar
