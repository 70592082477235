import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ENDPOINTS } from "../../constants/endpoints";

class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {}

  componentDidMount() {
    // uncomment below line to generate a image in the background
    // this.props.generateImage(this.state.product_id, this.state.setno);
  }

  createOrder = (data, actions) => {
    if (this.state.pg_order_id) return this.state.pg_order_id;
    let uri = ENDPOINTS.orders.create.uri;
    return fetch(uri, {
      method: ENDPOINTS.orders.create.method,
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(this.state),
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({ pg_order_id: response.pg_order_id });
        return response.pg_order_id;
      });
  };

  onApprove = (data, actions) => {
    localStorage.setItem("isOrderPaid", "true");
    let uri = ENDPOINTS.orders.capture.uri;
    return fetch(uri, {
      method: ENDPOINTS.orders.capture.method,
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        paypal_order_id: data.orderID,
      }),
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        alert("payment done successfully");
        // if (this.props.isRedirect)
        window.location = "/products";
        // window.location.reload();
        return data.pg_order_id;
      });
  };

  onSuccess = (details, data) => {
    alert("payment done successfully");
    // if (this.props.isRedirect) window.location = "/products";
  };

  onCancel = (data, actions) => {
    alert("Payment cancelled");
  };
  onError = (data, actions) => {
    localStorage.removeItem("isOrderPaid", "true");
    alert("Something went wrong please try again later");
  };

  render() {
    const style = {
      // height: 25,
      // width: 25,
      // color: "gold"
      size: "small",
      color: "gold",
      shape: "pill",
      label: "checkout",
      tagline: "true",
    };
    const PaypalButton = window.paypal.Buttons.driver("react", {
      React,
      ReactDOM,
    });
    return (
      <div style={style}>
        <PaypalButton
          {...this.props}
          createOrder={this.createOrder}
          onApprove={this.onApprove}
          // onApprove={e => this.onApprove(e)}
          onSuccess={this.onSuccess}
          onError={this.onError}
          onCancel={this.onCancel}
          // style={style}
        />
      </div>
    );
  }
}

export default Payment;
