import { connect } from 'react-redux'
import LySlotFor from '../../../components/layouts/lyslotfors/LySlotFor';
import { deleteLyDLSlotFor } from '../../../actions/layouts';

import {bindActionCreators} from 'redux';
const mapStateToProps = (state, ownProps) => {
  const product = state.products.filter(p => p.id == ownProps.product_id)[0];
  const layout = state.layouts.filter(p => p.id == ownProps.layout_id)[0];
  let slotFors = layout.datalevel_slotfors.filter(slotfor => slotfor.dlname == ownProps.dlname && slotfor.slotforcode == ownProps.slotforcode);
  return Object.assign({}, ownProps, {layout: layout, slotFors: slotFors, product: product });
};

const mapDispatchToProps = (dispatch) =>bindActionCreators({
  deleteLyDLSlotFor
},dispatch);

const LySlotForContainer = connect(mapStateToProps, mapDispatchToProps)(LySlotFor);

export default LySlotForContainer;