import React from "react";
import LyDlRelContainer from "../../../containers/layouts/lydlrel/LyDlRelContainer";
import EditLyDlRelListContainer from "../../../containers/layouts/lydlrel/EditLyDlRelListContainer";
import AddLyDlRelContainer from "../../../containers/layouts/lydlrel/AddLyDlRelContainer";
import { Col, Row } from "react-bootstrap";
import { authorized, Authorized } from "../../../adukku_utils/authorizeUser";

export default class LyDlRelList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
    this.toggleExpand = this.toggleExpand.bind(this);
    this.addRelKey = this.addRelKey.bind(this);
    this.getDlRelsByType = this.getDlRelsByType.bind(this);
  }

  toggleExpand(e) {
    e.stopPropagation();
    this.setState(Object.assign({}, this.state, { expand: !this.state.expand }));
  }
  componentDidMount() {
    if (this.state.product && this.state.product.id) this.props.getKeys(this.state.product.id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state !== nextProps) {
      this.setState(nextProps);
    }
  }

  addRelKey(e) {
    e.preventDefault();
    this.setState(Object.assign({}, this.state, { isAddVisible: true }));
  }

  getDlRelsByType(type, isExpand) {
    let children = [];
    const layout = this.state.layout;
    const product = this.state.product;
    const isAddVisible = this.state.isAddVisible;
    const datalevel_relations = layout.datalevel_relations;
    const role = this.state.role;

    if (isExpand) {
      if (type == "product_key")
        for (let i = 0; i < datalevel_relations.length; i++) {
          let dlRel = datalevel_relations[i];
          if (dlRel.style.toUpperCase() == "P")
            children.push(
              <LyDlRelContainer
                key={dlRel.id}
                layout_id={layout.id}
                dlrel_id={dlRel.id}
                product_id={product.id}
                type={"product_key"}
                role={role}
              />
            );
        }
      else
        for (let i = 0; i < datalevel_relations.length; i++) {
          let dlRel = datalevel_relations[i];
          if (dlRel.style.toUpperCase() != "P")
            children.push(
              <LyDlRelContainer
                key={dlRel.id}
                layout_id={layout.id}
                dlrel_id={dlRel.id}
                product_id={product.id}
                type={"non_product_key"}
                role={role}
              />
            );
        }
    }
    return children;
  }

  getExpandJSX = (expandFlag) => {
    return (
      <span>
        {expandFlag && <i className="icon-collapse" aria-hidden="true"></i>}
        {!expandFlag && <i className="icon-expand" aria-hidden="true"></i>}
      </span>
    );
  };

  render() {
    const layout = this.state.layout;
    const product = this.state.product;
    const isAddVisible = this.state.isAddVisible;
    const datalevel_relations = layout.datalevel_relations;
    const role = this.state.role;

    if (datalevel_relations == null) {
      return null;
    }
    const isExpand = this.state.expand;
    let children = this.getDlRelsByType(this.props.type, isExpand);
    let label = "Layout Data Level Relationships & Keys";
    if (this.props.type == "product_key")
      label = "Layout Data Level Relationships & Keys via Product Set keys";
    else label = "Layout Data Level Relationships & Keys via non-Product Set keys";
    return (
      <ul>
        <li>
          <a className="no-textcolor" onClick={this.toggleExpand}>
            {this.getExpandJSX(isExpand)} {label}
          </a>
          {isExpand && (
            <ul>
              <li>
                <React.Fragment>
                  <Row>
                    {this.props.type == "product_key" && <Col md={3}> Data Levels</Col>}
                    {this.props.type != "product_key" && (
                      <React.Fragment>
                        <Col md={3}>Parent Data Level</Col>
                        <Col md={3}>Child Data Level</Col>
                      </React.Fragment>
                    )}
                    {/* <Col md={3}>Actions</Col> */}
                  </Row>
                  {children}
                </React.Fragment>
              </li>
              <Authorized
                userRoles={[role, product.product_role]}
                requiredRoles={["admin", "owner", "preset"]}
                roleType="ANY"
              >
                <li>
                  <a onClick={() => {
                    this.setState({
                      isAddVisible: true
                    })
                  }}>Add new</a>
                </li>
                <AddLyDlRelContainer
                  isAddVisible={isAddVisible}
                  dlRel={{}}
                  layout_id={layout.id}
                  product_id={product.id}
                  type={this.props.type}
                  closeAddRel={() => {
                    this.setState({
                      isAddVisible: false
                    })
                  }}
                />
              </Authorized>
            </ul>
          )}
        </li>
      </ul>
    );
  }
}
