import { distinctObjects } from "../adukku_utils/array";
import product_keys from "./product_key.reducers";
import product_sets from "./product_sets.reducers";
import layouts from "./layouts.reducers";
import overlays from "./misc_reducer";
import { combineReducers } from "redux";
import {
  DELETE_PRODUCT_DATA_LEVEL,
  UPDATE_PRODUCT_DATA_LEVEL,
  CREATE_PRODUCT_DATA_LEVEL,
  DELETE_DATA_LEVEL_ATTRIBUTE,
  UPDATE_DATA_LEVEL_ATTRIBUTE,
  CREATE_DATA_LEVEL_ATTRIBUTE,
  DELETE_PRODUCT,
  UPDATE_PRODUCT,
  CREATE_PRODUCT,
  GET_PRODUCTS,
  GET_PRODUCT_DATA_LEVELS,
  TOGGLE_DATALEVEL_EXPAND,
  TOGGLE_PRODUCT_EXPAND,
  TOGGLE_EDIT_DATALEVEL,
  TOGGLE_EDIT_PRODUCT,
  CLEAR_PRODUCT_DATA_LEVELS,
  TOGGLE_EDIT_DATALEVEL_ATTRIBUTE,
  NEW_PRODUCT_DATA_LEVEL,
  NEW_DATA_LEVEL_ATTRIBUTE,
  ADD_CATEGORIES,
  ADD_PRODUCT_USER,
  ADD_VIEW_PRODUCT_USER,
  GET_UPLOADED_DATA,
  UPDATE_UPLOADED_DATA,
} from "../actions";
import orders from "./orders_reducer";

const DatalevelHandle = (state = [], action) => {
  let newState = null;
  switch (action.type) {
    case NEW_PRODUCT_DATA_LEVEL: {
      return [...state, action.payload];
    }
    case CREATE_PRODUCT_DATA_LEVEL: {
      const payload = action.payload;
      let datalevels = state.filter((datalevel) => datalevel.isEdit !== true);
      newState = [...datalevels, payload.datalevel];
      return newState;
    }
    case TOGGLE_DATALEVEL_EXPAND: {
      return state.map((datalevel) =>
        datalevel.id === action.id ? { ...datalevel, collapse: !datalevel.collapse } : datalevel
      );
    }
    case CLEAR_PRODUCT_DATA_LEVELS: {
      return [];
    }
    case DELETE_PRODUCT_DATA_LEVEL: {
      return state.filter((datalevel) => datalevel.id !== action.id);
    }
    case DELETE_DATA_LEVEL_ATTRIBUTE: {
      newState = state.map((datalevel) => {
        let nDl = Object.assign({}, datalevel); // copies element
        let attrs = nDl.attributes.filter((attribute) => attribute.id !== action.id);
        return Object.assign({}, nDl, { attributes: attrs });
      });
      return newState;
    }
    case CREATE_DATA_LEVEL_ATTRIBUTE:
      {
        newState = state.map((datalevel) => {
          if (datalevel.id === action.dl_id) {
            let attributes = datalevel.attributes.filter((attribute) => attribute.isEdit !== true);
            if (action.payload.attribute != null) {
              attributes.push(action.payload.attribute);
            }
            return Object.assign({}, datalevel, { attributes: attributes });
          } else {
            return datalevel;
          }
        });
      }
      return newState;

    case NEW_DATA_LEVEL_ATTRIBUTE: {
      newState = state.map((datalevel) => {
        if (datalevel.id === action.dl_id) {
          let attributes = datalevel.attributes;
          attributes.push(action.payload);
          return Object.assign({}, datalevel, { attributes: attributes });
        } else {
          return datalevel;
        }
      });
      return newState;
    }

    case UPDATE_DATA_LEVEL_ATTRIBUTE: {
      newState = state.map((datalevel) => {
        let nDl = Object.assign({}, datalevel); // copies element
        let attrs = nDl.attributes.filter((attribute) => attribute.isEdit !== true);
        attrs.push(action.payload);
        return Object.assign({}, nDl, { attributes: attrs });
      });
      return newState;
    }
    case GET_PRODUCT_DATA_LEVELS: {
      newState = state.concat(action.payload || []);
      newState = newState.filter(
        (s, index, self) => index === self.findIndex((t) => t.id === s.id)
      );
      return newState;
    }
    default:
      return state;
  }
};

const ProductHandle = (state = [], action) => {
  switch (action.type) {
    case TOGGLE_EDIT_PRODUCT:
      return state.map((product) =>
        product.id === action.id ? { ...product, isEdit: !(product.isEdit || false) } : product
      );
    case TOGGLE_PRODUCT_EXPAND:
      return state.map((product) =>
        product.id === action.id ? { ...product, collapse: !(product.collapse || false) } : product
      );
    case GET_PRODUCTS:
      return action.payload;
    case CREATE_PRODUCT:
      return [...state, action.payload];
    case DELETE_PRODUCT:
      let currentProduct1 = state.find((product) => product.id == action.id);
      //return state.filter(({ product }) => product.id !== action.id);
      return state.filter(({ product }) => currentProduct1.id !== action.id);
    case UPDATE_PRODUCT:
      return state;
    case ADD_PRODUCT_USER:
      let currentProduct = state.find((product) => product.id == action.id);
      if (action.payload && action.payload.is_edit_user_set)
        currentProduct.edit_user = action.payload.user;
      if (action.payload && action.payload.is_password_set)
        currentProduct.is_password_set = action.payload.is_password_set;

      return state.map((product) => (product.id === action.id ? { ...currentProduct } : product));
    case ADD_VIEW_PRODUCT_USER: {
      let currentProduct = state.find((product) => product.id == action.id);
      if (action.payload && action.payload.add_view_product_user) 
        currentProduct.product_code = action.payload.product_code;
      if (action.payload && action.payload.add_view_product_user) 
        currentProduct.password = action.payload.password;
      
      return state.map((product) => (product.id === action.id ? { ...currentProduct } : product));  
    }
    case GET_UPLOADED_DATA: {
      let currentProduct = state.find((product) => product.id == action.id);
      currentProduct.uploaded_data = action.payload;
      return state.map((product) => (product.id === action.id ? { ...currentProduct } : product));
    }
    case UPDATE_UPLOADED_DATA: {
      let currentProduct = state.find((product) => product.id == action.id);
      currentProduct.uploaded_data = action.payload;
      return state.map((product) => (product.id === action.id ? { ...currentProduct } : product));
    }

    default:
      return state;
  }
};

const CategoryHandle = (state = { categories: [], sub_categories: [] }, action) => {
  switch (action.type) {
    case ADD_CATEGORIES:
      return action.payload;
    default:
      return state;
  }
};

const AdukkuApp = combineReducers({
  products: ProductHandle,
  product_datalevels: DatalevelHandle,
  product_keys: product_keys,
  product_sets: product_sets,
  layouts: layouts,
  overlays: overlays,
  categories: CategoryHandle,
  orders: orders,
});
export default AdukkuApp;
