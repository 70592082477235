import { GET_PRODUCTS, TOGGLE_PRODUCT_EXPAND } from "../actions";
import { ADD_ALL_SETS } from "../actions/product_sets";
import {
  ADD_PRICE,
  GENERATE_IMAGE,
  ADD_VIEW_PRODUCTS,
  ADD_SHARE_PRODUCT,
  ADD_TO_CART,
  GET_ORDERS,
} from "../actions/orders";

const initialState = {
  products: [],
  carts: [],
  orders: [],
};

const reducer = (state = initialState, action) => {
  const newState = { ...state };
  let product = {};
  switch (action.type) {
    case GET_PRODUCTS:
      newState.products = action.payload;
      break;
    case ADD_VIEW_PRODUCTS:
      newState.products = action.payload;
      break;
    case TOGGLE_PRODUCT_EXPAND:
      newState.products = state.products.map((product) =>
        product.id === action.id ? { ...product, expand: !(product.expand || false) } : product
      );
      break;
    case ADD_ALL_SETS:
      product = newState.products.filter((product) => product.id == action.id)[0];
      product.product_sets = action.payload;
      newState;
      break;
    case ADD_PRICE:
      product = newState.products.filter((product) => product.id == action.product_id)[0];
      if (action.setno === "") {
        product.pricing = action.payload;
      }
      if (action.setno > 0) {
        let product_set = product.product_sets.filter((ps) => ps.setno === action.setno)[0];
        product_set.pricing = action.payload;
      }
      newState;
      break;
    case GENERATE_IMAGE:
      // newState.product_set_pricing = action.payload;
      break;
    case ADD_SHARE_PRODUCT:
      break;
    case ADD_TO_CART:
      const { carts } = newState;
      const newProductCart = action.payload;

      let current_product_cart = carts.find(
        (cart) =>
          cart.product_id ==
          (newProductCart.product_id || (newProductCart.order && newProductCart.order.product_id))
      );
      if (current_product_cart) {
        newState.carts = carts.map((cart) => {
          if (cart.product_id == newProductCart.order.product_id) {
            return newProductCart.order;
          }
          return cart;
        });
      } else {
        newState.carts = [...carts, newProductCart.order];
      }
      break;
    case GET_ORDERS:
      newState.carts = action.payload;
      break;
  }
  return newState;
};

export default reducer;
