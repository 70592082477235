import React from "react";
import { Col, Row } from "react-bootstrap";
import LyDlRelKeyListContainer from "../../../containers/layouts/lydlrel/LyDlRelKeyListContainer";
import { ENDPOINTS } from "../../../constants/endpoints";

import { authorized, Authorized } from "../../../adukku_utils/authorizeUser";

export default class LyDlRel extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
    this.toggleExpand = this.toggleExpand.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  toggleExpand(e) {
    e.stopPropagation();
    this.setState(Object.assign({}, this.state, { expand: !this.state.expand }));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state !== nextProps) {
      this.setState(nextProps);
    }
  }

  onDelete(e) {
    e.stopPropagation();
    const data = this.state.dlRel;
    const layout = this.state.layout;
    const product = this.state.product;
    let endpoint = Object.assign({}, ENDPOINTS.layout_datalevels_rel.delete, {});
    let url = endpoint.uri.replace(":layout_id", layout.id).replace(":pdlname", data.pdlname);
    url = url.replace(":cdlname", data.cdlname);
    data.product_id = product.id;
    data.layout_id = layout.id;
    this.props.deleteLyDLRel(url, endpoint.method, data);
  }

  getExpandJSX = (expandFlag) => {
    return (
      <span>
        {expandFlag && <i className="icon-collapse" aria-hidden="true"></i>}
        {!expandFlag && <i className="icon-expand" aria-hidden="true"></i>}
      </span>
    );
  };

  render() {
    const dlRel = this.state.dlRel;
    const isExpand = this.state.expand;
    const role = this.state.role;
    const product = this.state.product;
    return (
      <div>
        <Row onClick={this.toggleExpand}>
          <Col md={4} style={{ cursor: "pointer", userSelect: "none" }}>
            {this.getExpandJSX(isExpand)} {dlRel.pdlname}
          </Col>
          {this.props.type == "non_product_key" && <Col md={4}>{dlRel.cdlname}</Col>}
          {/* <Col md={2}>{dlRel.cdlcount}</Col>
        <Col md={2}>{dlRel.style}</Col> */}
          <Col md={4}>
            {product.product_state == "edit" && (
              <Authorized
                userRoles={[role, product.product_role]}
                requiredRoles={["admin", "owner"]}
                roleType="ANY"
              >
                <a onClick={this.onDelete} title="Delete" style={{ paddingLeft: "15px" }}>
                  <img src="/icons/delete.png" style={{ width: "17px" }} />
                </a>
              </Authorized>
            )}
          </Col>
        </Row>
        {isExpand && (
          <LyDlRelKeyListContainer
            dlrel_id={dlRel.id}
            layout_id={this.state.layout.id}
            product_id={this.state.product.id}
          />
        )}
      </div>
    );
  }
}
