export const distinctObjects = function (objectArr, compositeKey) {
  var runTime = {};
  for (var object of objectArr) {
    let uniqKey = compositeKey
      .map((key) => {
        return object[key];
      })
      .join("_");
    runTime[uniqKey] = runTime[uniqKey] || {};
    runTime[uniqKey] = Object.assign({}, runTime[uniqKey], object);
  }
  return Object.values(runTime);
};

export const formDataToJSON = function (formData) {
  let object = {};
  formData.forEach(function (value, key) {
    object[key] = value;
  });
  return object;
};
