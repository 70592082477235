import { combineReducers } from "redux";
import {
  ADD_ALL_SETS,
  ADD_SET_KEYVALS,
  ADD_ALL_SUPPORTED_LAYOUTS
} from "../actions/product_sets";

// {
//   1: { 1: { keyvals: [], dlvals: [], layouts: [] } }
// }
const X = (state = {}, action) => {
  let newState = {};
  let product_id = null;
  let setno = null;
  let data = null;
  let product_set = null;
  let product_set_list = null;
  switch (action.type) {
    case ADD_ALL_SETS:
      let product_sets = action.payload.reduce((result, product_set) => {
        if (result[product_set.setno] == null) {
          result[product_set.setno] = {};
        }
        let dlvals = (product_set.dlvals || []).reduce((dlvalResult, dlval) => {
          if (dlvalResult[dlval.pageno] == null) {
            dlvalResult[dlval.pageno] = [];
          }
          dlvalResult[dlval.pageno].push(dlval);
          return dlvalResult;
        }, {});
        delete product_set.dlvals;
        product_set.dlvals = dlvals || {};

        let counts = (product_set.counts || []).reduce((countResult, count) => {
          if (count && countResult[count.dlname] == null) {
            countResult[count.dlname] = [];
          }
          if (count) {
            countResult[count.dlname].push(count);
            return countResult;
          }
        }, {});
        delete product_set.counts;
        product_set.counts = counts || {};
        result[product_set.setno] = product_set;
        return result;
        let layouts = (product_set.layouts || []).reduce(
          (layoutResult, layout) => {
            layoutResult[layout.pageno] = layout;
            return layoutResult;
          },
          {}
        );
        delete product_set.dlvals;
        delete product_set.layouts;
        product_set.layouts = layouts || {};
        product_set.dlvals = dlvals || {};
        result[product_set.setno] = product_set;
        return result;
      }, {});
      newState[action.id] = product_sets;
      return newState;
    case ADD_SET_KEYVALS:
      data = action.payload;
      setno = action.setno;
      product_id = action.id;
      product_set_list = Object.assign({}, state[product_id], {});
      product_set = product_set_list[setno];
      product_set.keyvals = product_set.keyvals.concat(data.keyvals).flat();
      data.dlvals = Object.values(product_set.dlvals)
        .concat(data.dlvals)
        .flat();
      let dlvals = data.dlvals.reduce((dlvalResult, dlval) => {
        if (dlvalResult[dlval.pageno] == null) {
          dlvalResult[dlval.pageno] = [];
        }
        dlvalResult[dlval.pageno].push(dlval);
        return dlvalResult;
      }, {});
      product_set.dlvals = dlvals;
      data.counts = Object.values(product_set.counts)
        .concat(data.counts)
        .flat();
      let counts = data.counts.reduce((countResult, count) => {
        if (countResult[count.dlname] == null) {
          countResult[count.dlname] = [];
        }
        countResult[count.dlname].push(count);
        return countResult;
      }, {});
      product_set.counts = counts;
      newState = {};
      newState[product_id] = product_set_list;
      return Object.assign({}, state, newState);
    case ADD_ALL_SUPPORTED_LAYOUTS:
      data = action.payload;
      setno = action.setno;
      newState[
        action.product_id + "_set_" + setno + "_" + action.pageno
      ] = data;
      return Object.assign({}, state, newState);
    default:
      return state;
  }
};
export default X;
