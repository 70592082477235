import React from "react";
import LyDlContainer from "../../../containers/layouts/lydl/LyDlContainer";
import ManageLyDlContainer from "../../../containers/layouts/lydl/ManageLyDlContainer";
import { authorized, Authorized } from "../../../adukku_utils/authorizeUser";

export default class LyDlList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
    this.toggleExpand = this.toggleExpand.bind(this);
  }

  toggleExpand(e) {
    e.stopPropagation();
    this.setState(Object.assign({}, this.state, { expand: !this.state.expand }));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state !== nextProps) {
      this.setState(nextProps);
    }
  }
  getExpandJSX = (expandFlag) => {
    return (
      <span>
        {expandFlag && <i className="icon-collapse" aria-hidden="true"></i>}
        {!expandFlag && <i className="icon-expand" aria-hidden="true"></i>}
      </span>
    );
  };

  render() {
    const layout = this.state.layout;
    const product = this.state.product;
    const role = this.state.role;
    if (layout == null && layout.datalevels == null) {
      return null;
    }
    const ly_datalevels = layout.datalevels;
    const isExpand = this.state.expand;
    let children = [];
    if (isExpand) {
      for (let i = 0; i < ly_datalevels.length; i++) {
        children.push(
          <LyDlContainer layout_id={layout.id} dlname={ly_datalevels[i].dlname} role={role} />
        );
      }

      if (authorized(["admin", "owner"], [role, product.product_role], "ANY")) {
        children.push(
          <ManageLyDlContainer
            layout_id={layout.id}
            product_id={product.id}
            labelText="Manage datalevels"
            role={role}
          />
        );
      }
    }
    return (
      <ul>
        <li>
          <a className="no-textcolor" onClick={this.toggleExpand}>
            {this.getExpandJSX(isExpand)} Layout Datalevels
          </a>
        </li>
        <ul>{children}</ul>
      </ul>
    );
  }
}
