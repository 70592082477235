import { connect } from "react-redux";
import { getProducts } from "../actions";
import ProductDL from "../components/ProductDL";
import { bindActionCreators } from "redux";
import {
  toggleView,
  deleteProductDataLevel,
  createProductDatalevel,
  newDatalevelAttribute,
} from "../actions";

const mapStateToProps = (state, ownProps) => {
  // read state.datalevel_attributes and return product specific ones
  let product = state.products.filter((p) => p.id == ownProps.id)[0];
  let product_role = product && product.product_role;
  let role = ownProps.role;
  let datalevel = state.product_datalevels.filter(
    (dl) => dl.product_id == ownProps.product_id && dl.dlname == ownProps.dlname
  )[0];
  const collapse = datalevel.collapse == null ? false : datalevel.collapse;
  return {
    product_datalevel: datalevel,
    collapse: collapse,
    role: role,
    product_role: product_role,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteProductDataLevel,
      toggleView,
      newDatalevelAttribute,
      createProductDatalevel,
    },
    dispatch
  );

const ProductContainer = connect(mapStateToProps, mapDispatchToProps)(ProductDL);
export default ProductContainer;
