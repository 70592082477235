import { GET_PRODUCTS, PRODUCTS, TOGGLE } from "../actions";
import {
  GET_PRICE,
  ADD_PRICE,
  GENERATE_IMAGE_SAGA,
  GENERATE_IMAGE,
  GET_VIEW_PRODUCTS,
  ADD_VIEW_PRODUCTS,
  SHARE_PRODUCT,
  ADD_SHARE_PRODUCT,
  ADD_TO_CART,
  ADD_TO_CART_SAGA,
  GET_ORDERS,
  GET_ORDERS_SAGA,
} from "../actions/orders";
import { GET_PRODUCT_SETS } from "../actions/product_sets";
import { takeEvery } from "redux-saga/effects";
import { ENDPOINTS } from "../constants/endpoints";
import { put, call } from "redux-saga/effects";

function* fetchProducts(action) {
  const apiCall = () => {
    return fetch(ENDPOINTS.products.list.uri + "?type=orders")
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  const apiCallViewProducts = () => {
    return fetch(ENDPOINTS.products.view.uri)
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  try {
    const user_products = yield call(apiCall);
    const view_products = yield call(apiCallViewProducts);
    const products = view_products.products.concat(user_products.products);
    let p = {};

    products.map((pr) => {
      p[pr.id] = pr;
    });

    yield put({ type: GET_PRODUCTS, payload: Object.values(p) });
  } catch (error) {
    throw error;
  }
}

function* fetchViewProducts(action) {
  const apiCall = () => {
    return fetch(ENDPOINTS.products.view.uri)
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  try {
    const data = yield call(apiCall);
    yield put({ type: ADD_VIEW_PRODUCTS, payload: data.products });
  } catch (error) {
    throw error;
  }
}

function* toggle(action) {
  yield put({ type: action.payload.reducerKey, id: action.payload.id });
}

function* getSets(action) {
  const apiCall = (action) => {
    let url = ENDPOINTS.sets.list.uri;
    url = url.replace(":product_id", action.id);
    return fetch(url)
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  try {
    const data = yield call(apiCall, action);
    yield put(
      Object.assign({}, action, {
        type: action.reducerKey,
        payload: data.product_sets,
      })
    );
  } catch (error) {
    throw error;
  }
}

function* fetchPrice(action) {
  const apiCall = (action) => {
    let url = ENDPOINTS.sets.price.uri;
    url = url.replace(":product_id", action.product_id);
    if (action.setno !== "") url = url + "?setno=" + action.setno;
    return fetch(url)
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  try {
    const data = yield call(apiCall, action);
    yield put(
      Object.assign({}, action, {
        type: ADD_PRICE,
        payload: data.pricing,
      })
    );
  } catch (error) {
    throw error;
  }
}

function* generateImage(action) {
  const apiCall = (action) => {
    let payload = action.payload;
    let url = ENDPOINTS.images.generate.uri;
    let ajaxCall = fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: ENDPOINTS.images.generate.method,
      body: JSON.stringify(payload),
    });
    return ajaxCall
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  try {
    const data = yield call(apiCall, action);
    yield put({ type: GENERATE_IMAGE, payload: data });
  } catch (error) {
    throw error;
  }
}

function* shareProduct(action) {
  const apiCall = (action) => {
    let payload = action.payload;
    let url = ENDPOINTS.products.share.uri;
    let ajaxCall = fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: ENDPOINTS.products.share.method,
      body: JSON.stringify(payload),
    });
    return ajaxCall
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  try {
    const data = yield call(apiCall, action);
    yield put({ type: ADD_SHARE_PRODUCT, payload: data });
  } catch (error) {
    throw error;
  }
}

function* addToCart(action) {
  const apiCall = (action) => {
    let payload = action.payload;
    let url = ENDPOINTS.orders.add_to_cart.uri;
    let ajaxCall = fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: ENDPOINTS.orders.add_to_cart.method,
      body: JSON.stringify(payload),
    });
    return ajaxCall
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  try {
    const data = yield call(apiCall, action);
    yield put({ type: ADD_TO_CART, payload: data });
  } catch (error) {
    throw error;
  }
}

function* getOrders(action) {
  const apiCall = (action) => {
    let payload = action.payload;
    return fetch(ENDPOINTS.orders.list.uri + "?status=" + payload.status)
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  try {
    const data = yield call(apiCall, action);
    yield put({ type: GET_ORDERS, payload: data.orders });
  } catch (error) {
    throw error;
  }
}

export function* fetchProductsSaga() {
  yield takeEvery(PRODUCTS, fetchProducts);
}

export function* fetchViewProductsSaga() {
  yield takeEvery(GET_VIEW_PRODUCTS, fetchViewProducts);
}

export function* toggleSaga() {
  yield takeEvery(TOGGLE, toggle);
}

export function* getProductSetsSaga() {
  yield takeEvery(GET_PRODUCT_SETS, getSets);
}

export function* fetchPriceSaga() {
  yield takeEvery(GET_PRICE, fetchPrice);
}

export function* shareProductSaga() {
  yield takeEvery(SHARE_PRODUCT, shareProduct);
}

export function* generateImageSaga() {
  yield takeEvery(GENERATE_IMAGE_SAGA, generateImage);
}

export function* addToCartSaga() {
  yield takeEvery(ADD_TO_CART_SAGA, addToCart);
}

export function* getOrdersSaga() {
  yield takeEvery(GET_ORDERS_SAGA, getOrders);
}
