export const GET_PRODUCT_DATA_LEVELS = "GET_PRODUCT_DATA_LEVELS";
export const DELETE_PRODUCT_DATA_LEVEL = "DELETE_PRODUCT_DATA_LEVEL";
export const CREATE_PRODUCT_DATA_LEVEL = "CREATE_PRODUCT_DATA_LEVEL";
export const PRODUCT_DATA_LEVELS = "PRODUCT_DATA_LEVELS";
export const NEW_PRODUCT_DATA_LEVEL = "NEW_PRODUCT_DATA_LEVEL";

export const DATA_LEVEL_ATTRIBUTES = "DATA_LEVEL_ATTRIBUTES";
export const NEW_DATA_LEVEL_ATTRIBUTE = "DATA_LEVEL_ATTRIBUTES";
export const GET_DATA_LEVEL_ATTRIBUTES = "GET_DATA_LEVEL_ATTRIBUTES";
export const DELETE_DATA_LEVEL_ATTRIBUTE = "DELETE_DATA_LEVEL_ATTRIBUTE";
export const UPDATE_DATA_LEVEL_ATTRIBUTE = "UPDATE_DATA_LEVEL_ATTRIBUTE";
export const CREATE_DATA_LEVEL_ATTRIBUTE = "CREATE_DATA_LEVEL_ATTRIBUTE";

export const GET_PRODUCTS = "GET_PRODUCTS";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const DELETE_PRODUCT_SAGA = "DELETE_PRODUCT_SAGA";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const UPDATE_PRODUCT_SAGA = "UPDATE_PRODUCT_SAGA";
export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const PRODUCTS = "PRODUCTS";
export const CLONE_PRODUCT = "CLONE_PRODUCT";
export const GET_PRESET_PRODUCT = "GET_PRESET_PRODUCT";
export const GET_PRESET_PRODUCT_SAGA = "GET_PRESET_PRODUCT_SAGA";

export const TOGGLE_DATALEVEL_EXPAND = "TOGGLE_DATALEVEL_EXPAND";
export const TOGGLE_PRODUCT_EXPAND = "TOGGLE_PRODUCT_EXPAND";

export const TOGGLE = "TOGGLE";
export const TOGGLE_EDIT_DATALEVEL = "TOGGLE_EDIT_DATALEVEL";
export const TOGGLE_EDIT_PRODUCT = "TOGGLE_EDIT_PRODUCT";
export const TOGGLE_EDIT_DATALEVEL_ATTRIBUTE = "TOGGLE_EDIT_DATALEVEL_ATTRIBUTE";

export const CLEAR_PRODUCT_DATA_LEVELS = "CLEAR_PRODUCT_DATA_LEVELS";
export const CLEAR_PRODUCTS = "CLEAR_PRODUCTS";

export const UPDATE_OR_DELETE_RESOURCE = "UPDATE_OR_DELETE_RESOURCE";
export const CREATE_RESOURCE = "CREATE_RESOURCE";

export const GET_CATEGORIES = "GET_CATEGORIES";
export const ADD_CATEGORIES = "ADD_CATEGORIES";

export const ADD_PRODUCT_USER = "ADD_PRODUCT_USER";
export const PRODUCT_USER = "PRODUCT_USER";

export const ADD_VIEW_PRODUCT_USER = "ADD_VIEW_PRODUCT_USER";
export const GET_VIEW_PRODUCT_USER = "GET_VIEW_PRODUCT_USER";
export const ADD_VIEW_PRODUCT_USER_SAGA = "ADD_VIEW_PRODUCT_USER_SAGA";
export const GET_VIEW_PRODUCT_USER_SAGA = "GET_VIEW_PRODUCT_USER_SAGA";


export const GET_UPLOADED_DATA = "GET_UPLOADED_DATA";
export const GET_UPLOADED_DATA_SAGA = "GET_UPLOADED_DATA_SAGA";
export const UPDATE_UPLOADED_DATA = "UPDATE_UPLOADED_DATA";
export const UPDATE_UPLOADED_DATA_SAGA = "UPDATE_UPLOADED_DATA_SAGA";
/* START OF CRUD product data levels*/

export const SET_USER_ROLE = "SET_USER_ROLE";
export const SET_USER_ROLE_SAGA = "SET_USER_ROLE_SAGA";

export const setCurrentUserRole = (role) => {
  return { type: SET_USER_ROLE_SAGA, role: role };
};

export const getProductDatalevels = (product_id) => {
  return { type: PRODUCT_DATA_LEVELS, product_id: product_id };
};

export const newProductDatalevel = (product_id, dlname) => {
  return {
    type: NEW_PRODUCT_DATA_LEVEL,
    payload: {
      product_id: product_id,
      dlname: dlname,
      id: product_id + "/datalevels/" + dlname,
      isEdit: true,
      attributes: [],
    },
  };
};

export const deleteProductDataLevel = (id, persisted = true) => {
  if (persisted)
    return {
      type: UPDATE_OR_DELETE_RESOURCE,
      id: id,
      resource: "datalevels",
      method: "delete",
      payload: Object.assign({}, {}, { reducerKey: DELETE_PRODUCT_DATA_LEVEL }),
    };
  else return { type: DELETE_PRODUCT_DATA_LEVEL, id: id };
};

export const createProductDatalevel = (product_id, dlname, dltype) => {
  return {
    type: CREATE_RESOURCE,
    resource: "datalevels",
    id: product_id + "/datalevels",
    method: "create",
    payload: Object.assign(
      {},
      {},
      { dltype: dltype, dlname: dlname, reducerKey: CREATE_PRODUCT_DATA_LEVEL }
    ),
  };
};
/* END OF CRUD product data levels*/

/* START OF CRUD product data level attributes*/
export const getDataLevelAttributes = (product_id, dlname) => {
  return {
    type: DATA_LEVEL_ATTRIBUTES,
    product_id: product_id,
    dlname: dlname,
  };
};

export const newDatalevelAttribute = (dlId, payload) => {
  return {
    type: NEW_DATA_LEVEL_ATTRIBUTE,
    dl_id: dlId,
    payload: Object.assign({}, payload, { isEdit: true }),
  };
};

export const deleteDataLevelAttribute = (id, persisted = true) => {
  if (persisted)
    return {
      type: UPDATE_OR_DELETE_RESOURCE,
      id: id,
      resource: "attributes",
      method: "delete",
      payload: Object.assign({}, {}, { reducerKey: DELETE_DATA_LEVEL_ATTRIBUTE }),
    };
  else return { type: DELETE_DATA_LEVEL_ATTRIBUTE, id: id };
};

export const updateDataLevelAttribute = (payload) => {
  return {
    type: UPDATE_OR_DELETE_RESOURCE,
    id: payload.id,
    resource: "attributes",
    method: "update",
    payload: Object.assign({}, payload, {
      reducerKey: UPDATE_DATA_LEVEL_ATTRIBUTE,
    }),
  };
};

export const createDataLevelAttribute = (dlId, payload) => {
  let product_id = payload.product_id;
  let dlname = payload.dlname;
  let id = product_id + "/datalevels/" + dlname + "/attributes";
  return {
    type: CREATE_RESOURCE,
    dl_id: dlId,
    id: id,
    resource: "attributes",
    method: "update",
    payload: Object.assign({}, payload, {
      reducerKey: CREATE_DATA_LEVEL_ATTRIBUTE,
    }),
  };
};
/* END OF CRUD product data level attributes*/

/* START OF CRUD product*/
export const getProducts = (payload) => {
  return {
    type: PRODUCTS,
    products_type: payload && payload.type ? payload.type : "",
  };
};

export const getPresetProduct = (payload) => {
  return { type: GET_PRESET_PRODUCT, product_id: payload.product_id };
};

// export const deleteProduct = id => {
//   return { type: DELETE_PRODUCT, id: id };
// };

export const deleteProduct = (url, method, payload) => {
  return {
    type: DELETE_PRODUCT_SAGA,
    payload: payload,
    url: url,
    method: method,
  };
};

export const updateProduct = (id, url, method, payload) => {
  // return {type: UPDATE_PRODUCT, id: id, payload: payload};
  return {
    type: UPDATE_PRODUCT_SAGA,
    id: id,
    url: url,
    method: method,
    payload: payload,
  };
};

export const createProduct = (url, method, payload) => {
  return { type: CREATE_PRODUCT, url: url, method: method, payload: payload };
};

export const cloneProduct = (url, method, payload) => {
  return {
    type: CLONE_PRODUCT,
    payload: payload,
    url: url,
    method: method,
  };
};
/* END OF CRUD product*/

/* START OF CRUD product*/
export const getCategories = () => {
  return { type: GET_CATEGORIES };
};
/* END OF CRUD product*/

export const toggleView = (payload) => {
  return { type: TOGGLE, payload: payload };
};

export const productUser = (payload) => {
  return { type: PRODUCT_USER, payload: payload };
};

export const addViewProductUser = (payload) => {
  return { type: ADD_VIEW_PRODUCT_USER, payload: payload };
};

export const getViewProductUser = (payload) => {
  return { type: GET_VIEW_PRODUCT_USER, payload: payload };
};

export const getUploadedData = (payload) => {
  let product_id = payload.product_id;
  let dlname = payload.dlname;
  return {
    type: GET_UPLOADED_DATA_SAGA,
    product_id: product_id,
    dlname: dlname,
    is_search: payload.isSearch,
    search_data: payload.searchData,
    page: payload.page,
    per_page: payload.per_page,
  };
};

export const updateUploadedData = (payload) => {
  let product_id = payload.product_id;
  let dlname = payload.dlname;
  let data = payload.data;
  return {
    type: UPDATE_UPLOADED_DATA_SAGA,
    product_id: product_id,
    dlname: dlname,
    id: payload.id,
    data: data,
  };
};
