import React from "react";
import PropTypes from "prop-types";
import { ENDPOINTS } from "../../constants/endpoints";
import ProductSetKeyValContainer from "../../containers/product_sets/ProductSetKeyValContainer";
import { Table } from "react-bootstrap";

export default class ProductSetKeyValList extends React.Component {
  constructor(props) {
    super(props);
    let customState = { ...props };
    customState.expand = false;
    this.state = customState;
    this.onExpandToggle = this.onExpandToggle.bind(this);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state !== nextProps) {
      this.setState(nextProps);
    }
  }

  onExpandToggle(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ expand: !this.state.expand }); // TODO set from reducer
  }

  getExpandJSX = (expandFlag) => {
    return (
      <span>
        {expandFlag && <i className="icon-collapse" aria-hidden="true"></i>}
        {!expandFlag && <i className="icon-expand" aria-hidden="true"></i>}
      </span>
    );
  };

  render() {
    const { role, product_role } = this.state;
    const setno = this.state.setno;
    const expand = this.state.expand;
    const keyvals = this.state.keyvals;
    // const keyvals = [...new Set(this.state.keyvals.map(item => item.id))];
    const anchorDLDeleteStyle = {
      paddingLeft: "20px",
    };
    return (
      <li key={setno}>
        <a className="no-textcolor" onClick={this.onExpandToggle}>
          {this.getExpandJSX(expand)} Keys and Values{" "}
        </a>
        <ul>
          {expand && (
            <li>
              <Table hover className="TreeTable">
                <tbody>
                  {expand &&
                    keyvals.map((keyval, i) => {
                      return (
                        <ProductSetKeyValContainer
                          key={keyval.keyname + "" + keyval.keyorder}
                          {...keyval}
                          role={role}
                          product_role={product_role}
                        />
                      );
                    })}
                </tbody>
              </Table>
            </li>
          )}
        </ul>
      </li>
    );
  }
}
