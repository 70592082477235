import React, { Component } from "react";
import PaymentContainer from "../../containers/orders/PaymentContainer";
import CartContainer from "../../containers/cart/CartContainer";

class CartList extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {}

  componentDidMount() {
    this.props.getProducts();
    this.props.getOrders({ status: "pending" });
  }

  render1() {
    const carts = this.props.carts;
    const cartsList = carts.length ? (
      carts.map((cart) => {
        return (
          <li product_id={cart.product.id} key={cart.product.id}>
            {cart.product.name}
            <ul>
              {cart.pg_order_id && (
                <li>
                  <PaymentContainer
                    product_id={cart.product.id}
                    pg_order_id={cart.pg_order_id || null}
                  />
                </li>
              )}
              {!cart.pg_order_id && (
                <li>
                  <PaymentContainer product_id={cart.product.id} />
                </li>
              )}
            </ul>
          </li>
        );
      })
    ) : (
      <div className="no-orders"> No items in the cart</div>
    );

    return (
      <div>
        <h3 className="list-heading">My Cart</h3>
        <ul className="tree">{cartsList}</ul>
      </div>
    );
  }

  render() {
    const products = this.props.products;
    const productList = products.length ? (
      products.map((product) => {
        return <CartContainer product_id={product.id} key={product.id} />;
      })
    ) : (
      <div className="no-orders"> No Items</div>
    );

    return (
      <div>
        <h3 className="list-heading">My Cart</h3>
        <ul className="tree">{productList}</ul>
      </div>
    );
  }
}

export default CartList;
