import { distinctObjects } from "../adukku_utils/array";
import { combineReducers } from "redux";
import {
  SHOW_OVERLAY,
  HIDE_OVERLAY,
  TOGGLE_OVERLAY,
  SELECTED_DATALEVEL,
  SELECTED_DATALEVEL_ATTRIBUTE,
  SELECTED_ORDER,
  EXPAND_SECTION,
  HIDE_SECTION,
  SET_USER_ROLE
} from "../actions/misc_actions";

const X = (state = {}, action) => {
  let newState = {};
  switch (action.type) {
    case SHOW_OVERLAY:
      newState[action.id] = { visible: true };
      return { ...state, ...newState };
    case HIDE_OVERLAY:
      newState[action.id] = { visible: false };
      return { ...state, ...newState };
    case EXPAND_SECTION:
      newState[action.id] = { expand: true };
      return { ...state, ...newState };
    case HIDE_SECTION:
      newState[action.id] = { expand: false };
      return { ...state, ...newState };
    case TOGGLE_OVERLAY:
      newState[action.id] = { visible: !state[action.id] };
      return { ...state, ...newState };
    case SELECTED_DATALEVEL:
      newState[action.id] = Object.assign({}, state[action.id] || {}, {
        selectedDatalevel: action.payload
      });
      return { ...state, ...newState };
    case SELECTED_DATALEVEL_ATTRIBUTE:
      newState[action.id] = Object.assign({}, state[action.id] || {}, {
        selectedDatalevelAttribute: action.payload
      });
      return { ...state, ...newState };
    case SELECTED_ORDER:
      newState[action.id] = Object.assign({}, state[action.id] || {}, {
        selectedOrder: parseInt(action.payload)
      });
      return { ...state, ...newState };
    default:
      return state;
  }
};
export default X;
