import React, { Component } from "react";
import { Col, Form, Modal, Button, closeButton } from "react-bootstrap";
import { FieldGroup } from "../bootstrap/Forms.FieldGroup";
import { formDataToJSON } from "../../adukku_utils/array";

export default class ShareProduct extends Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }
  _handleValidSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let formData = formDataToJSON(new FormData(e.target));
    this.props.shareProduct(formData);
    this.setState({ show: false });
  };

  render() {
    const style = {
      display: "none",
    };
    return (
      <li>
        <a variant="primary" onClick={() => this.setState({ show: true })}>
          Share Product
        </a>
        <Modal show={this.state.show} size="lg" centered="true">
          <Modal.Header closeButton>
            <Modal.Title>Share Product</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form horizontal onSubmit={this._handleValidSubmit}>
              <Col md={12}>
                <FieldGroup
                  id="formControlProduct"
                  type="text"
                  label="Product ID"
                  name="product_id"
                  placeholder="Users email addresses"
                  required="required"
                  style={style}
                  value={this.state.product_id}
                  readOnly={true}
                />
                <FieldGroup
                  id="formControlEmail"
                  type="text"
                  label="People"
                  name="recipients"
                  placeholder="Users email addresses"
                  required="required"
                />
                <FieldGroup
                  id="formControlPassword"
                  type="password"
                  label="password"
                  name="password"
                  placeholder="Password"
                  required={this.props.is_password_set ? null : "required"}
                />
              </Col>
              <hr></hr>
              <Button type="submit">Submit</Button>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.setState({ show: false })}>Close</Button>
          </Modal.Footer>
        </Modal>
      </li>
    );
  }
}
