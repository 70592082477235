import React, { Component } from "react";
import ViewProductContainer from "../../containers/view_products/ViewProductContainer";

class ViewProductList extends Component {
  componentDidUpdate(nextProps) {}

  componentDidMount() {
    this.props.getViewProducts();
  }

  render() {
    const products = this.props.products;
    const productList = products.length ? (
      products.map(product => {
        return (
          <ViewProductContainer product_id={product.id} key={product.id} />
        );
      })
    ) : (
      <div className="no-orders"> No Products</div>
    );

    return (
      <div>
        <h3 className="list-heading">View Products</h3>
        <ul className="tree">{productList}</ul>
      </div>
    );
  }
}

export default ViewProductList;
