import { connect } from 'react-redux';
import ManageLyDl from '../../../components/layouts/lydl/ManageLyDl';
import {
 hideOverlay
 ,showOverlay
 ,expandSection
 ,hideSection
} from '../../../actions/misc_actions';

import {
 deleteLyDL
 ,createLyDL
} from '../../../actions/layouts';

import {bindActionCreators} from 'redux';
const mapStateToProps = (state, ownProps) => {
  const product = state.products.filter( p => p.id == ownProps.product_id)[0];
  const layout = state.layouts.filter(l => l.id == ownProps.layout_id)[0];
  const layout_datalevels = layout.datalevels || [];
  const product_datalevels = state.product_datalevels.filter(dl => dl.product_id == ownProps.product_id);
  let datalevels = product_datalevels.map(dl => {
    let t = layout_datalevels.filter(ld => ld.dlname == dl.dlname)[0];
    return Object.assign({}, dl, { saved: (t != null)});
  });
  return Object.assign({}, ownProps, {layout: layout, datalevels: datalevels, product: product });
};

const mapDispatchToProps = (dispatch) =>bindActionCreators({
  hideOverlay
  ,showOverlay
  ,hideSection
  ,expandSection
  ,deleteLyDL
  ,createLyDL
},dispatch);

const ManageLyDlContainer = connect(mapStateToProps, mapDispatchToProps)(ManageLyDl);

export default ManageLyDlContainer;