import React from "react";
import PropTypes from "prop-types";
import { ENDPOINTS } from "../../constants/endpoints";
import {
  Table,
  Col,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Modal,
  Fade,
  utils,
  Button,
} from "react-bootstrap";
import { FieldGroup } from "../bootstrap/Forms.FieldGroup";
import { formDataToJSON } from "../../adukku_utils/array";
import { authorized, Authorized } from "../../adukku_utils/authorizeUser";

export default class ProductSetCount extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props, editableField: null };
    this.onEditClick = this.onEditClick.bind(this);
    this.onExpandToggle = this.onExpandToggle.bind(this);
    this.hideAddSetOverlay = this.hideAddSetOverlay.bind(this);
    this._handleValidSubmit = this._handleValidSubmit.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state !== nextProps) {
      this.setState(nextProps);
    }
  }

  hideAddSetOverlay(e, count) {
    e.preventDefault();
    e.stopPropagation();
    this.props.hideOverlay(count.id);
  }

  onEditClick(e, count) {
    e.preventDefault();
    e.stopPropagation();
    this.props.showOverlay(count.id);
  }

  _handleValidSubmit(e, count) {
    e.preventDefault();
    e.stopPropagation();
    let formData = formDataToJSON(new FormData(e.target));
    if (count.main_occurence_count == null || count.main_occurence_count == 0) {
      let endpoint = ENDPOINTS.sets.counts.create;
      let url = endpoint.uri;
      let method = endpoint.method;
      this.props.createSetOccurenceCount(url, method, formData);
    } else {
      let endpoint = ENDPOINTS.sets.counts.update;
      let url = endpoint.uri;
      let method = endpoint.method;
      this.props.updateSetOccurenceCount(url, method, formData);
    }
    this.hideAddSetOverlay(e, count);
  }

  onExpandToggle(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ expand: !this.state.expand });
  }

  getExpandJSX = (expandFlag) => {
    return (
      <span>
        {expandFlag && <i className="icon-collapse" aria-hidden="true"></i>}
        {!expandFlag && <i className="icon-expand" aria-hidden="true"></i>}
      </span>
    );
  };

  render() {
    const { role, product_role } = this.state;
    const counts = this.state.counts;
    const isExpand = this.state.expand;
    let that = this;
    let isVisible = null;
    let view = [
      <a className="no-textcolor" onClick={this.onExpandToggle}>
        {this.getExpandJSX(isExpand)} {"Product Set Count "}
      </a>,
    ];
    if (isExpand) {
      let t = (
        <ul>
          <li>
            <Table hover className="TreeTable">
              <tbody>
                {counts.map((count, i) => {
                  return (
                    <tr>
                      <td>{count.dlname}</td>
                      <td>{count.main_occurence_count || "NA"}</td>
                      <Authorized
                        userRoles={[role, product_role]}
                        requiredRoles={["admin", "owner"]}
                        roleType="ANY"
                      >
                        <td>
                          <a
                            onClick={(e) => this.onEditClick(e, count)}
                            style={{ paddingLeft: "15px" }}
                            title="Edit"
                          >
                            <img src="/icons/edit.png" style={{ width: "18px" }} />
                          </a>
                        </td>
                      </Authorized>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </li>
        </ul>
      );
      view.push(t);
      counts.map((count, i) => {
        isVisible = (that.state.overlays[count.id] || { visible: false }).visible;
        t = (
          <Modal
            show={isVisible}
            onHide={(e) => {
              that.hideAddSetOverlay(e, count);
            }}
          >
            <Form
              className="override_form"
              onSubmit={(e) => {
                that._handleValidSubmit(e, count);
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title>Add Product Set Count</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FieldGroup
                  id="formControlKeyName"
                  type="text"
                  label="Key:"
                  readonly="readonly"
                  name="dlname"
                  placeholder="Enter set no"
                  defaultValue={count.dlname}
                  required="required"
                />
                <FieldGroup
                  id="formControlKeyName"
                  type="hidden"
                  name="setno"
                  defaultValue={count.setno}
                  required="required"
                />
                <FieldGroup
                  id="formControlKeyName"
                  type="hidden"
                  name="product_id"
                  defaultValue={count.product_id}
                  required="required"
                />
                <FieldGroup
                  id="formControlKeyVal"
                  type="number"
                  label="Product Set count:"
                  name="main_occurence_count"
                  defaultValue={count.main_occurence_count}
                  placeholder="Enter Product Set count"
                  required="required"
                />
              </Modal.Body>
              <Modal.Footer>
                <Button type="submit">Submit</Button>
                {/* <Button
                  onClick={(e) => {
                    that.hideAddSetOverlay(e, count);
                  }}
                >
                  Cancel
                </Button> */}
              </Modal.Footer>
            </Form>
          </Modal>
        );
        view.push(t);
      });
    }
    return <li>{view}</li>;
  }
}
