import { all, fork } from "redux-saga/effects";
import {
  fetchProductsSaga,
  toggleSaga,
  getProductSetsSaga,
  fetchPriceSaga,
  generateImageSaga,
  fetchViewProductsSaga,
  shareProductSaga,
  addToCartSaga,
  getOrdersSaga
} from "./orders.saga";
import { addToCart } from "../actions/orders";

export default function* rootSaga() {
  // getProductSetsSaga;
  yield all([
    fork(fetchProductsSaga),
    fork(toggleSaga),
    fork(getProductSetsSaga),
    fork(fetchPriceSaga),
    fork(generateImageSaga),
    fork(fetchViewProductsSaga),
    fork(shareProductSaga),
    fork(addToCartSaga),
    fork(getOrdersSaga)
  ]);
}
