import React from "react";
import { Form, FormGroup, ControlLabel, FormControl, Modal, Button } from "react-bootstrap";
import serialize from "form-serialize";

import Select from "../../react-select-override/FixRequiredSelect";
import { ENDPOINTS } from "../../../constants/endpoints";
import { authorized, Authorized } from "../../../adukku_utils/authorizeUser";

export default class AddLySlotFor extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
    // this.state = {
    //  layout: props.layout,
    //  layout_datalevels: props.layout_datalevels,
    //  product: props.product,
    // };
    this.hideOverlay = this.hideOverlay.bind(this);
    this.showOverlay = this.showOverlay.bind(this);
    this.handleLayoutDLChange = this.handleLayoutDLChange.bind(this);
    this.handleDLAttrChange = this.handleDLAttrChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state !== nextProps) {
      this.setState(nextProps);
    }
  }

  componentDidMount() {
    let presetProductId = localStorage.getItem("preset_product_id");
    const isPresetProduct =
      !presetProductId || presetProductId == "null" || presetProductId == "undefined"
        ? false
        : true;
    this.setState({ isPresetProduct: isPresetProduct });
  }

  hideOverlay(e) {
    this.setState(Object.assign({}, this.state, { visible: false }));
  }

  showOverlay(e) {
    e.stopPropagation();
    this.setState(Object.assign({}, this.state, { visible: true }));
  }

  handleLayoutDLChange(e) {
    e.preventDefault();
    const selected_layout_datalevel = this.state.layout_datalevels.filter((lyDl) => {
      return lyDl.dlname == e.target.value;
    })[0];
    this.setState({ selected_layout_datalevel: selected_layout_datalevel });
  }

  handleDLAttrChange(attr_names) {
    // e.preventDefault();
    const selected_layout_datalevel = this.state.selected_layout_datalevel;
    let selected_attrs = selected_layout_datalevel.attributes.filter((attr) => {
      return (
        attr_names.filter((attr_name) => {
          return attr_name.value == attr.name;
        }).length > 0
      );
    });
    this.setState({ selected_attrs: selected_attrs });
  }

  handleSubmit(e) {
    e.preventDefault();
    const selected_attrs = this.state.selected_attrs;
    const layout = this.state.layout;
    const product = this.state.product;
    const role = this.state.role;

    let formData = serialize(e.target, { hash: true, empty: true });
    let dlname = formData.lyslotfor["dlname"];
    let dl = this.state.layout_datalevels.filter((dl) => dl.dlname == dlname)[0];
    let maxOrder = Math.max.apply(
      Math,
      this.props.slotFors.map(function (o) {
        return o.attributeorder;
      })
    );
    maxOrder = parseInt(maxOrder) || 0;
    let newFormData = [];
    let temp = null;
    for (let i = 0; i < selected_attrs.length; i++) {
      temp = serialize(e.target, { hash: true, empty: true });
      temp.lyslotfor["slotforcode"] = dl.dltype == "main" ? "MA" : "OT";
      temp.lyslotfor["attributename"] = selected_attrs[i].name;
      temp.lyslotfor["attributetype"] = selected_attrs[i].type.toLowerCase();
      temp.lyslotfor["layout_id"] = layout.id;
      temp.lyslotfor["layout_id"] = layout.id;
      temp.lyslotfor["product_id"] = product.id;
      newFormData.push(temp);
      // if (temp.lyslotfor["slotforcode"] == "MA") {
      //   newFormData.push({
      //     ...temp,
      //     lyslotfor: {
      //       ...temp.lyslotfor,
      //       slotforcode: "MM"
      //     }
      //   });
      // }
      temp = null;
    }
    const that = this;
    let endpoint = null;
    newFormData.map((data, i) => {
      console.debug(data);
      endpoint = Object.assign({}, ENDPOINTS.layout_slotfors.create, {});
      endpoint.uri = endpoint.uri.replace(":slotforcode", data.lyslotfor.slotforcode);
      endpoint.uri = endpoint.uri.replace(":layout_id", layout.id);
      that.props.createLyDLSlotFor(
        endpoint.uri,
        endpoint.method,
        Object.assign({}, data.lyslotfor, { attributeorder: maxOrder + i + 1 })
      );
      endpoint = null;
    });
    this.hideOverlay(e);
  }

  render() {
    const slotFors = this.state.slotFors;
    const isVisible = this.state.visible;
    const layout_datalevels = this.state.layout_datalevels;
    const selected_layout_datalevel = this.state.selected_layout_datalevel;
    const selected_attrs = this.state.selected_attrs;
    const datalevel_relations = this.state.layout.datalevel_relations;
    const product = this.state.product;
    const role = this.state.role; 
    const { isPresetProduct } = this.state;
   
    if (authorized(["admin", "owner"], [role, product.product_role], "ANY")) {
    return (
      
    <Authorized
       userRoles={[role, product.product_role]}
       requiredRoles={["admin", "owner"]}
       roleType="ANY"
      >
    
        <li style={{ marginLeft: "-15px" }}>
         {!isPresetProduct && <a onClick={this.showOverlay}>Add Slot For's</a>}
          
          <Modal show={isVisible} onHide={this.hideOverlay}>
            <Form onSubmit={this.handleSubmit} className="override_form">
              <Modal.Header closeButton>
                <Modal.Title>Add Slot For's</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FormGroup controlId="dlname">
                  <ControlLabel>Select Data Level:</ControlLabel>
                  <FormControl
                    componentClass="select"
                    placeholder="please select"
                    required="required"
                    onChange={this.handleLayoutDLChange}
                    name={"lyslotfor[dlname]"}
                  >
                    <option value=""> </option>
                    {layout_datalevels.map((layout_datalevel) => {
                      let dl = datalevel_relations.find(
                        (dl) => dl.cdlname == layout_datalevel.dlname
                      );
                      if (dl)
                        return (
                          <option key={layout_datalevel.id} value={layout_datalevel.dlname}>
                            {layout_datalevel.dlname}
                          </option>
                        );
                    })}
                  </FormControl>
                </FormGroup>
                <FormGroup controlId="attributename">
                  <ControlLabel>Select Data Level Attribute:</ControlLabel>
                  <Select 
                    styles={{
                      menu: (provided, state) => ({
                        ...provided,
                        maxHeight: "85px",
                        overflow: "auto",
                      }),
                    }}
                    defaultValue={null}
                    name={"lyslotfor[attributename]"}
                    onChange={this.handleDLAttrChange}
                    isMulti
                    required
                    options={
                      selected_layout_datalevel &&
                      selected_layout_datalevel.attributes.map((attr) => {
                        return { value: attr.name, label: attr.name };
                      })
                    }
                  />
                </FormGroup>
              </Modal.Body>
              <Modal.Footer>
                <Button type="submit">Save</Button>
                {/* <Button onClick={this.hideOverlay}>Cancel</Button> */}
              </Modal.Footer>
            </Form>
          </Modal>
        </li>
      </Authorized>
    );
    }
    else {
     return(
     <li style={{ marginLeft: "-15px" }}>Add Slot For's</li>                    
     );
    }
  }
}
