import "babel-polyfill";
import React from "react";
import CartState from "../context/cart/CartState";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import createLogger from "redux-logger";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../sagas/orders_index.saga";
import AdukkuApp from "../reducers/orders_reducer";
import OrderListContainer from "../containers/orders/OrderListContainer";
import ProductComposzNavbar from "./ProductComposzNavbar";

//const logger = createLogger({});
const sagaMiddleware = createSagaMiddleware();
//const store = createStore(AdukkuApp, applyMiddleware(logger, sagaMiddleware)); // Bala
const store = createStore(AdukkuApp, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(rootSaga);

class MyOrders extends React.Component {
  render() {
    return (
    <div id="topNav">
     <CartState>
      <ProductComposzNavbar user_id={this.props.user_id} user_name={this.props.user_name} user_email={this.props.user_email} has_view_products={this.props.has_view_products}></ProductComposzNavbar>
      <div style={{paddingTop:30, paddingLeft: 200}}>
      <Provider store={store}>
        <OrderListContainer />
      </Provider>
      </div>
     </CartState>
    </div>    
    );
  }
}

export default MyOrders;
