import React from "react";
import PropTypes from "prop-types";
import { ENDPOINTS } from "../../constants/endpoints";
import ProductSetCountContainer from "../../containers/product_sets/ProductSetCountContainer";
import { Table } from "react-bootstrap";

export default class ProductSetCountList extends React.Component {
  constructor(props) {
    super(props);
    let customState = { ...props };
    customState.expand = false;
    this.state = customState;
    this.onExpandToggle = this.onExpandToggle.bind(this);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state !== nextProps) {
      this.setState(nextProps);
    }
  }

  onExpandToggle(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ expand: !this.state.expand }); // TODO set from reducer
  }

  getExpandJSX = (expandFlag) => {
    return (
      <span>
        {expandFlag && <i className="icon-collapse" aria-hidden="true"></i>}
        {!expandFlag && <i className="icon-expand" aria-hidden="true"></i>}
      </span>
    );
  };

  render() {
    const { role, product_role } = this.state;
    const setno = this.state.setno;
    const expand = this.state.expand;
    const counts = this.state.counts;

    const anchorDLDeleteStyle = {
      paddingLeft: "20px",
    };
    return (
      <React.Fragment>
        {Object.keys(counts).map((dlname, i) => {
          return (
            <ProductSetCountContainer
              key={dlname}
              dlname={dlname}
              counts={counts[dlname]}
              setno={setno}
              role={role}
              product_role={product_role}
            />
          );
        })}
      </React.Fragment>
    );
  }
}
