import React from "react";
import PropTypes from "prop-types";
import AddLayoutContainer from "../containers/layouts/AddLayoutContainer";
import LayoutListContainer from "../containers/layouts/LayoutListContainer";

export default class ProductLayoutList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
    this.toggleExpand = this.toggleExpand.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state !== nextProps) {
      this.setState(nextProps);
    }
  }

  // componentWillMount() {
  //   let user_role = document.getElementById("user_role");
  //   user_role = user_role.innerHTML;
  //   this.setState({ role: user_role });
  // }

  componentDidMount() {
    let expandedProduct = localStorage.getItem("expandedProduct");
    expandedProduct = expandedProduct ? JSON.parse(expandedProduct) : {};

    if (
      expandedProduct.layoutId &&
      expandedProduct.productId == this.state.product.id &&
      !expandedProduct.isSet
    ) {
      this.setState({
        expand: true,
      });
    }
  }

  toggleExpand(e) {
    e.stopPropagation();
    this.setState(
      Object.assign({}, this.state, { expand: !this.state.expand })
    );
  }

  getExpandJSX = (expandFlag) => {
    return (
      <span>
        {expandFlag && <i className="icon-collapse" aria-hidden="true"></i>}
        {!expandFlag && <i className="icon-expand" aria-hidden="true"></i>}
      </span>
    );
  };

  render() {
    const { role } = this.state;
    const isExpand = this.state.expand;
    return (
      <li>
        <a className="no-textcolor" onClick={this.toggleExpand}>
          {this.getExpandJSX(isExpand)} Layouts
        </a>
        {isExpand && (
          <LayoutListContainer product_id={this.state.product.id} role={role} />
        )}
        {(role === "admin" || role === "owner") && isExpand && (
          <AddLayoutContainer product_id={this.state.product.id} />
        )}
      </li>
    );
  }
}
