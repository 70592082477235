import { connect } from "react-redux";
import Product from "../components/Product";
import {
  getProductDatalevels,
  toggleView,
  newProductDatalevel,
  updateProduct,
  deleteProduct,
  cloneProduct,
} from "../actions";
import { bindActionCreators } from "redux";
import { createSelector } from "reselect";
import { getSets } from "../actions/product_sets";
import { getKeys } from "../actions/product_keys";
import { getOrders } from "../actions/orders";

const dlSelector = createSelector(
  [(state) => state.id, (state) => state.datalevels],
  (product_id, datalevels) => {
    return datalevels.filter((datalevel) => datalevel.product_id == product_id);
  }
);

const mapStateToProps = (state, ownProps) => {
  let datalevels = state.product_datalevels;
  let product = state.products.filter((p) => p.id == ownProps.id)[0];
  const collapse = product.collapse == null ? false : product.collapse;
  const isEdit = product.isEdit == null ? false : product.isEdit;
  datalevels = dlSelector(Object.assign({}, { id: product.id }, { datalevels: datalevels }));
  return Object.assign(
    {},
    {},
    {
      product: product,
      product_datalevels: datalevels,
      collapse: collapse,
      isEdit: isEdit,
      role: ownProps.role,
      categories: state.categories,
    }
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getProductDatalevels,
      toggleView,
      newProductDatalevel,
      getSets,
      getKeys,
      updateProduct,
      deleteProduct,
      cloneProduct,
      getOrders,
    },
    dispatch
  );

const ProductContainer = connect(mapStateToProps, mapDispatchToProps)(Product);

export default ProductContainer;
