import { connect } from "react-redux";
import ProductSet from "../../components/product_sets/ProductSet";
import { bindActionCreators } from "redux";
import { createSelector } from "reselect";
import { getSetPlaceholders, deleteSet, updateSet } from "../../actions/product_sets";

const mapStateToProps = (state, ownProps) => {
  return ownProps;
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getSetPlaceholders,
      deleteSet,
      updateSet,
    },
    dispatch
  );

const ProductSetContainer = connect(mapStateToProps, mapDispatchToProps)(ProductSet);

export default ProductSetContainer;
