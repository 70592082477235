import React from "react";
import { Modal } from "react-bootstrap";

//import StoragePayment from "./StoragePayment";
import CCStoragePayment from "./CCStoragePayment";

export default class CCPaymentModal extends React.Component {
  
  render() {
    //const { no_of_gb, storage_unit, convenienceCharges } = this.props.fields;
    const { no_of_gb, storage_unit } = this.props.fields;
    const convenienceCharges = 10;
    const gst = 0.18;

    return (
      <Modal
        centered="true"
        show={no_of_gb > 0}
        onHide={(e) => {
          this.props.resetStorage();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Purchase Storage</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul style={{ listStyleType: "none" }}>
            <li>
              <strong>
                Total Price for {no_of_gb} GB: ₹{no_of_gb * 75}
              </strong>
            </li>
            <li>
              <strong>
                Convenience Charges : ₹
                {parseFloat(no_of_gb * convenienceCharges).toFixed(2)}
              </strong>
            </li>
            <li>
              <strong>
                GST 18% : ₹
                {parseFloat((no_of_gb * 75 + no_of_gb * convenienceCharges) * gst).toFixed(2)}
              </strong>
            </li>
            <li>
              <strong>Grand Total: ₹ {Math.round(((no_of_gb * 75 + no_of_gb * convenienceCharges))+(no_of_gb * 75 + no_of_gb * convenienceCharges) * gst).toFixed(2)}</strong>
            </li>
          </ul>
          <CCStoragePayment no_of_gb={no_of_gb} storage_unit={storage_unit} />
        </Modal.Body>
      </Modal>
    );
  }
}
