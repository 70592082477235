import React from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Modal,
  Fade,
  utils,
  Button,
} from "react-bootstrap";
import { FieldGroup } from "../bootstrap/Forms.FieldGroup";
import { ENDPOINTS } from "../../constants/endpoints";
import { formDataToJSON } from "../../adukku_utils/array";
// import { placeholder } from '/svg/placeholder.svg'
import { authorized, Authorized } from "../../adukku_utils/authorizeUser";
import ThumbnailPreview from "../../components/layouts/ThumbnailPreview";
export default class ProductSetLayout extends React.Component {
  constructor(props) {
    super(props);
    this.showAssignLayoutOverlay = this.showAssignLayoutOverlay.bind(this);
    this.hideAssignLayoutOverlay = this.hideAssignLayoutOverlay.bind(this);
    this.onExpandToggle = this.onExpandToggle.bind(this);
    this.state = {
      supported_layouts: props.supported_layouts,
      setno: props.setno,
      product: props.product,
      pageno: props.pageno,
      layout: props.layout,
      overlay: props.overlay,
      overlay_id: props.overlay_id,
      show: false,
      role: props.role,
      product_role: props.product_role,
    };
    this._handleValidSubmit = this._handleValidSubmit.bind(this);
  }
  onExpandToggle(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ expand: !this.state.expand }); // TODO set from reducer
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps !== this.state) {
      this.setState(nextProps);
    }
  }

  componentDidMount() {
    let expandedProduct = localStorage.getItem("expandedProduct");
    expandedProduct = expandedProduct ? JSON.parse(expandedProduct) : {};

    if (expandedProduct.layoutId && this.state.layout.id == expandedProduct.layoutId) {
      this.setState({
        expand: true,
      });
    }
  }

  showAssignLayoutOverlay(e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.showOverlay(this.state.overlay_id);

    const product = this.state.product;
    const pageno = this.state.pageno;
    const setno = this.state.setno;
    this.props.getSupportedLayouts(product.id, {
      product_id: product.id,
      setno: setno,
      pageno: pageno,
    });
  }

  hideAssignLayoutOverlay(e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.hideOverlay(this.state.overlay_id);
  }

  UNSAFE_componentWillMount() {
    const product = this.state.product;
    const pageno = this.state.pageno;
    const setno = this.state.setno;
    this.props.getSupportedLayouts(product.id, {
      product_id: product.id,
      setno: setno,
      pageno: pageno,
    });
  }

  _handleValidSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    const set_layout = this.state.layout;
    let info = set_layout.layout_id ? confirm("Are you sure, you want to Assign layout?") : true;
    if (info) {
      let formData = formDataToJSON(new FormData(e.target));
      let endpoint = ENDPOINTS["sets"]["layout_create"];
      let url = endpoint.uri;
      let method = endpoint.method;
      this.props.createSetLayout(url, method, formData);
      this.props.hideOverlay(this.state.overlay_id);
    }
  }

  _handleUnAssignSetLayout(e, pageno) {
    if (confirm("Are you sure you UnAssign the layout?")) {
      e.preventDefault();
      let endpoint = ENDPOINTS["sets"]["layout_delete"];
      let url = endpoint.uri;
      let method = endpoint.method;
      let payload = {
        product_id: this.state.product.id,
        setno: this.state.setno,
        pageno: pageno,
      };
      this.props.deleteSetLayout(url, method, payload);
    }
  }

  render() {
    const { role, product_role } = this.state;
    const isVisible = this.state.overlay.visible;
    const product = this.state.product;
    const expand = this.state.expand;
    const set_layout = this.state.layout;
    const pageno = this.state.pageno;
    const layouts = this.state.supported_layouts;
    const setno = this.state.setno;
    let expandView = [];
    let formView = null;
    if (isVisible) {
      formView = (
        <Modal show={isVisible} onHide={this.hideAssignLayoutOverlay}>
          <Form className="override_form" onSubmit={this._handleValidSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Assign Layout to Product Set</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {layouts.map((layout) => {
                if (layout.errors && layout.errors.length > 1) {
                  for (let i = 0; i < layout.errors.length; i++) {
                    alert("For layout " + layout.desc + "\nErrors: " + layout.errors[i]);
                  }
                }
              })}
              <FieldGroup
                componentClass="select"
                label="Select Layout:"
                name="layout_id"
                placeholder="Select Layout"
                onChange={({ target: { name, value } }) => {
                  this.setState({ [name]: value });
                }}
                value={this.state.category}
                required="required"
              >
                <option key="None">{null}</option>
                {layouts.map((layout) => (
                  <React.Fragment>
                    {layout.errors && layout.errors.length == 0 && (
                      <option value={layout.id}>{layout.desc}</option>
                    )}
                  </React.Fragment>
                ))}
              </FieldGroup>
              <input type="hidden" defaultValue={pageno} name="pageno" />
              <input type="hidden" defaultValue={setno} name="setno" />
              <input type="hidden" defaultValue={product.id} name="product_id" />
            </Modal.Body>
            <Modal.Footer>
              <Button type="submit">Submit</Button>
              {/* <Button onClick={this.hideAssignLayoutOverlay}>Cancel</Button> */}
            </Modal.Footer>
          </Form>
        </Modal>
      );
    }

    if (set_layout.layout_id) {
      let t = (
        <li>
          <span>{"Page No " + pageno + " - " + set_layout.layout_name}</span>
          <Authorized
            userRoles={[role, product_role]}
            requiredRoles={["admin", "owner"]}
            roleType="ANY"
          >
            <a
              title="Edit"
              className="no-textcolor"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                let expandedProduct = {
                  productId: product.id,
                  layoutId: set_layout.layout_id,
                  setNo: setno,
                  isSet: true,
                };
                localStorage.setItem("expandedProduct", JSON.stringify(expandedProduct));
                window.location = "/products/" + product.id + "/layouts/" + set_layout.layout_id;
              }}
              // href={
              //   "/products/" +
              //   product.id +
              //   "/layouts/" +
              //   set_layout.layout_id
              // }
              style={{ paddingLeft: "15px" }}
              // target="_blank"
            >
              <img src="/icons/edit.png" style={{ width: "18px" }} />
            </a>
            <a
              title="Un-Assign"
              className="no-textcolor"
              onClick={(e) => this._handleUnAssignSetLayout(e, pageno)}
              style={{ paddingLeft: "15px" }}
            >
              <img src="icons/unassign.png" style={{ width: "18px" }} />
            </a>
            <a
              title="Re-Assign"
              className="no-textcolor"
              onClick={this.showAssignLayoutOverlay}
              style={{ paddingLeft: "15px" }}
            >
              <img src="icons/reassign.png" style={{ width: "35px" }} />
            </a>
          </Authorized>
          <div>
            <img
              onClick={() => this.setState({ show: true })}
              style={{
                height: "60px",
                margin: "10px 0",
                boxShadow: "5px 5px 10px rgba(0,0,0,.5)",
                cursor: "pointer",
              }}
              src={`${set_layout.preview_url}?${new Date()}`}
            />
            {this.state.show && (
              <ThumbnailPreview
                layoutDetails={set_layout}
                show={this.state.show}
                imageSrc={`${set_layout.preview_url}?${new Date()}`}
                onClose={() => this.setState({ show: false })}
              />
            )}
          </div>
        </li>
      );
      expandView.push(t);
    } else {
      if (authorized(["admin", "owner"], [role, product_role], "ANY")) {
        expandView.push(
          <li>
            Page No {pageno}
            <Authorized
              userRoles={[role, product_role]}
              requiredRoles={["admin", "owner"]}
              roleType="ANY"
            >
              <a
                className="no-textcolor"
                onClick={this.showAssignLayoutOverlay}
                title="Assign"
                style={{ paddingLeft: "15px" }}
              >
                <img src="/icons/assign.png" style={{ width: "18px" }} />
              </a>
              <a title="Edit" style={{ paddingLeft: "15px" }}>
                <img src="/icons/edit.png" style={{ width: "18px" }} />
              </a>
              <a title="Un-Assign" style={{ paddingLeft: "15px" }}>
                <img src="/icons/unassign.png" style={{ width: "18px" }} />
              </a>
            </Authorized>
            {/* </ul> */}
          </li>
        );
      }
    }
    return (
      <React.Fragment>
        {expandView}
        {formView}
      </React.Fragment>
    );
  }
}
