import React, { Component } from "react";
import { TOGGLE_PRODUCT_EXPAND } from "../../actions";
import ViewProductSetListContainer from "../../containers/view_products/ViewProductSetListContainer";

class ViewProduct extends Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }
  onExpandToggle = e => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ expand: !this.state.expand });
    this.props.toggleView({
      reducerKey: TOGGLE_PRODUCT_EXPAND,
      id: this.props.product.id
    });
    this.props.getSets(this.props.product.id);
  };

  componentDidUpdate(nextProps) {
    if (nextProps.product !== this.state.product) {
      this.setState({ product: nextProps.product });
    }
  }

  componentDidMount() {
  }

  render() {
    const product = this.state.product;
    const expand = this.state.expand;
    const readOnly = (
      <a className="no-textcolor" onClick={this.onExpandToggle}>
        {product.name}
      </a>
    );
    let productSets = null;
    if (expand == true) {
      productSets = (
        <ul>
          <li>
            <a
              className="no-textcolor"
              onClick={this.onProductSetsToggle}
            >
              Product Sets
            </a>
            <ViewProductSetListContainer
              product_id={product.id}
            />
          </li>
        </ul>
      );
    }
    return (
      <li key={product.id}>
        {readOnly}
        {productSets}
      </li>
    );
  }
}

export default ViewProduct;
