import {
  GET_PRODUCT_SETS,
  CREATE_PRODUCT_SET,
  DELETE_PRODUCT_SET,
  DELETE_SET,
  ADD_SET,
  ADD_ALL_SETS,
  CREATE_PRODUCT_SET_KEY,
  UPDATE_PRODUCT_SET_KEY,
  CREATE_PRODUCT_SET_DLVAL,
  UPDATE_PRODUCT_SET_DLVAL,
  GET_PRODUCT_SET_PLACEHOLDERS,
  CREATE_PRODUCT_SET_LAYOUT,
  DELETE_PRODUCT_SET_LAYOUT,
  ADD_ALL_SUPPORTED_LAYOUTS,
  GET_SUPPORTED_PRODUCT_LAYOUTS,
  ADD_SET_OCCURENCE_COUNT,
  UPDATE_SET_OCCURENCE_COUNT,
  UPDATE_PRODUCT_SET,
} from "../actions/product_sets";
import { hideOverlay } from "../actions/misc_actions";
import { getSetPlaceholders as getActionSetPlaceHolders } from "../actions/product_sets";
import { takeEvery } from "redux-saga/effects";
import { ENDPOINTS } from "../constants/endpoints";
import { put, call } from "redux-saga/effects";

function* getSetPlaceholders(action) {
  const apiCall = (action) => {
    let url = ENDPOINTS.sets.placeholders.uri;
    url = url.replace(":product_id", action.id);
    url = url.replace(":setno", action.setno);
    return fetch(url)
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  try {
    const data = yield call(apiCall, action);
    yield put(
      Object.assign({}, action, {
        type: action.reducerKey,
        id: action.id,
        setno: action.setno,
        payload: data,
      })
    );
  } catch (error) {
    throw error;
  }
}

function* getSupportedLayouts(action) {
  const apiCall = (action) => {
    let uri = ENDPOINTS.sets.layout_supported.uri;
    let payload = action.payload;
    uri = uri.replace(":product_id", payload.product_id);
    uri = uri.replace(":setno", payload.setno);
    uri = uri.replace(":pageno", payload.pageno);
    return fetch(uri, action.payload)
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  try {
    const data = yield call(apiCall, action);
    let payload = action.payload;
    yield put(
      Object.assign({}, action, {
        type: ADD_ALL_SUPPORTED_LAYOUTS,
        payload: data.layouts,
        setno: payload.setno,
        pageno: payload.pageno,
        product_id: payload.product_id,
      })
    );
  } catch (error) {
    throw error;
  }
}

function* getSets(action) {
  const apiCall = (action) => {
    let url = ENDPOINTS.sets.list.uri;
    url = url.replace(":product_id", action.id);
    return fetch(url)
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  try {
    const data = yield call(apiCall, action);
    yield put(
      Object.assign({}, action, {
        type: action.reducerKey,
        payload: data.product_sets,
      })
    );
  } catch (error) {
    throw error;
  }
}

function* createSet(action) {
  const apiCall = (action) => {
    let payload = action.payload;
    let url = action.url;
    url = url.replace(":product_id", payload.product_id);
    url = url.replace(":setno", payload.setno);
    url = url.replace(":keyname", payload.keyname);
    let ajaxCall = fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: action.method,
      body: JSON.stringify(payload),
    });
    return ajaxCall
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  try {
    const data = yield call(apiCall, action);
    let payload = action.payload;
    const errors = data.errors;
    if (errors == null || errors.length == 0) {
      yield put(hideOverlay(payload.product_id + "_sets"));
      yield* getSets({
        type: GET_PRODUCT_SETS,
        id: payload.product_id,
        reducerKey: ADD_ALL_SETS,
      });
      // yield put(getActionSetPlaceHolders(payload.product_id, payload.setno));
    } else {
      setTimeout(function () {
        alert(errors.join("\n"));
      }, 100);
    }
  } catch (error) {
    throw error;
  }
}

function* deleteSet(action) {
  const apiCall = (action) => {
    let payload = action.payload;
    let url = action.url;
    url = url.replace(":product_id", payload.product_id);
    url = url.replace(":setno", payload.setno);
    let ajaxCall = fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: action.method,
    });
    return ajaxCall
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  try {
    const data = yield call(apiCall, action);
    let payload = action.payload;
    const errors = data.errors;
    if (errors == null || errors.length == 0) {
      yield put(hideOverlay(payload.product_id + "_sets"));
      yield* getSets({
        type: GET_PRODUCT_SETS,
        id: payload.product_id,
        reducerKey: ADD_ALL_SETS,
      });
      // yield put(getActionSetPlaceHolders(payload.product_id, payload.setno));
    } else {
      setTimeout(function () {
        alert(errors.join("\n"));
      }, 100);
    }
  } catch (error) {
    throw error;
  }
}

function* updateSet(action) {
  const apiCall = (action) => {
    let payload = action.payload;
    let url = action.url;
    url = url.replace(":product_id", payload.product_id);
    url = url.replace(":setno", action.id);
    let ajaxCall = fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: action.method,
      body: JSON.stringify(payload),
    });
    return ajaxCall
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  try {
    const data = yield call(apiCall, action);
    let payload = action.payload;
    const errors = data.errors;
    if (errors == null || errors.length == 0) {
      yield put(hideOverlay(payload.product_id + "_sets"));
      yield* getSets({
        type: GET_PRODUCT_SETS,
        id: payload.product_id,
        reducerKey: ADD_ALL_SETS,
      });
      // yield put(getActionSetPlaceHolders(payload.product_id, payload.setno));
    } else {
      setTimeout(function () {
        alert(errors.join("\n"));
      }, 100);
    }
  } catch (error) {
    throw error;
  }
}

function* createSetKey(action) {
  const apiCall = (action) => {
    let payload = action.payload;
    let url = action.url;
    url = url.replace(":product_id", payload.product_id);
    url = url.replace(":setno", payload.setno);
    url = url.replace(":keyname", payload.keyname);
    let ajaxCall = fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: action.method,
      body: JSON.stringify(payload),
    });
    return ajaxCall
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  try {
    const data = yield call(apiCall, action);
    let payload = action.payload;
    const errors = data.errors;
    if (errors == null || errors.length == 0) {
      yield put(hideOverlay(payload.product_id + "/sets/" + payload.setno + "/" + payload.keyname));
      yield* getSets({
        type: GET_PRODUCT_SETS,
        id: payload.product_id,
        reducerKey: ADD_ALL_SETS,
      });
      yield put(getActionSetPlaceHolders(payload.product_id, payload.setno));
    } else {
      setTimeout(function () {
        alert(errors.join("\n"));
      }, 100);
    }
  } catch (error) {
    throw error;
  }
}

function* createSetLayout(action) {
  const apiCall = (action) => {
    let payload = action.payload;
    let url = action.url;
    url = url.replace(":product_id", payload.product_id);
    url = url.replace(":setno", payload.setno);
    url = url.replace(":pageno", payload.pageno);
    let ajaxCall = fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: action.method,
      body: JSON.stringify(payload),
    });
    return ajaxCall
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  try {
    const data = yield call(apiCall, action);
    let payload = action.payload;
    const errors = data.errors;
    if (errors == null || errors.length == 0) {
      yield put(
        hideOverlay(payload.product_id + "_layout_" + payload.setno + "_" + payload.keyname)
      );
      yield* getSets({
        type: GET_PRODUCT_SETS,
        id: payload.product_id,
        reducerKey: ADD_ALL_SETS,
      });
      yield put(getActionSetPlaceHolders(payload.product_id, payload.setno));
    } else {
      setTimeout(function () {
        alert(errors.join("\n"));
      }, 100);
    }
  } catch (error) {
    throw error;
  }
}

function* deleteSetLayout(action) {
  const apiCall = (action) => {
    let payload = action.payload;
    let url = action.url;
    url = url.replace(":product_id", payload.product_id);
    url = url.replace(":setno", payload.setno);
    url = url.replace(":pageno", payload.pageno);
    let ajaxCall = fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: action.method,
      body: JSON.stringify(payload),
    });
    return ajaxCall
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  try {
    const data = yield call(apiCall, action);
    let payload = action.payload;
    const errors = data.errors;
    if (errors == null || errors.length == 0) {
      yield put(
        hideOverlay(payload.product_id + "_layout_" + payload.setno + "_" + payload.keyname)
      );
      yield* getSets({
        type: GET_PRODUCT_SETS,
        id: payload.product_id,
        reducerKey: ADD_ALL_SETS,
      });
      yield put(getActionSetPlaceHolders(payload.product_id, payload.setno));
    } else {
      setTimeout(function () {
        alert(errors.join("\n"));
      }, 100);
    }
  } catch (error) {
    throw error;
  }
}

function* updateSetKey(action) {
  const apiCall = (action) => {
    let payload = action.payload;
    let url = action.url;
    url = url.replace(":product_id", payload.product_id);
    url = url.replace(":setno", payload.setno);
    url = url.replace(":keyname", payload.keyname);
    url = url.replace(":dlname", payload.dlname);
    url = url.replace(":pageno", payload.pageno);
    let ajaxCall = fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: action.method,
      body: JSON.stringify(payload),
    });
    return ajaxCall
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  try {
    const data = yield call(apiCall, action);
    let payload = action.payload;
    const errors = data.errors;
    if (errors == null || errors.length == 0) {
      yield put(hideOverlay(payload.product_id + "/sets/" + payload.setno + "/" + payload.keyname));
      yield* getSets({
        type: GET_PRODUCT_SETS,
        id: payload.product_id,
        reducerKey: ADD_ALL_SETS,
      });
      yield put(getActionSetPlaceHolders(payload.product_id, payload.setno));
    } else {
      setTimeout(function () {
        alert(errors.join("\n"));
      }, 100);
    }
  } catch (error) {
    throw error;
  }
}

function* createSetDLVal(action) {
  const apiCall = (action) => {
    let payload = action.payload;
    let url = action.url;
    url = url.replace(":product_id", payload.product_id);
    url = url.replace(":setno", payload.setno);
    url = url.replace(":pageno", payload.pageno);
    let ajaxCall = fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: action.method,
      body: JSON.stringify(payload),
    });
    return ajaxCall
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  try {
    const data = yield call(apiCall, action);
    let payload = action.payload;
    const errors = data.errors;
    if (errors == null || errors.length == 0) {
      yield put(hideOverlay(data.dlval.id));
      yield* getSets({
        type: GET_PRODUCT_SETS,
        id: payload.product_id,
        reducerKey: ADD_ALL_SETS,
      });
      yield put(getActionSetPlaceHolders(payload.product_id, payload.setno));
    } else {
      setTimeout(function () {
        alert(errors.join("\n"));
      }, 100);
    }
  } catch (error) {
    throw error;
  }
}

function* updateSetDLVal(action) {
  const apiCall = (action) => {
    let payload = action.payload;
    let url = action.url;
    url = url.replace(":product_id", payload.product_id);
    url = url.replace(":setno", payload.setno);
    url = url.replace(":pageno", payload.pageno);
    url = url.replace(":dlname", payload.dlname);
    let ajaxCall = fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: action.method,
      body: JSON.stringify(payload),
    });
    return ajaxCall
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  try {
    const data = yield call(apiCall, action);
    let payload = action.payload;
    const errors = data.errors;
    if (errors == null || errors.length == 0) {
      yield put(hideOverlay(data.dlval.id));
      yield* getSets({
        type: GET_PRODUCT_SETS,
        id: payload.product_id,
        reducerKey: ADD_ALL_SETS,
      });
      yield put(getActionSetPlaceHolders(payload.product_id, payload.setno));
    } else {
      setTimeout(function () {
        alert(errors.join("\n"));
      }, 100);
    }
  } catch (error) {
    throw error;
  }
}

function* deleteSetUnused(action) {
  const apiCall = (action) => {
    let payload = action.payload;
    let url = action.url;
    url = url.replace(":product_id", payload.product_id);
    url = url.replace(":dlname", payload.dlname);
    let ajaxCall = fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: action.method,
      body: JSON.stringify(payload),
    });
    return ajaxCall
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  try {
    const data = yield call(apiCall, action);
    let payload = action.payload;
    const errors = data.errors;
    if (errors == null || errors.length == 0) {
      yield* fetchKeys({
        type: GET_PRODUCT_SETS,
        id: payload.product_id,
        reducerKey: ADD_ALL_SETS,
      });
    } else {
      alert(errors.join("\n"));
    }
  } catch (error) {
    throw error;
  }
}

function* createProductSetLayout(action) {
  const apiCall = (action) => {
    let payload = action.payload;
    let url = action.url;
    url = url.replace(":product_id", payload.product_id);
    url = url.replace(":setno", payload.setno);
    url = url.replace(":keyname", payload.keyname);
    let ajaxCall = fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: action.method,
      body: JSON.stringify(payload),
    });
    return ajaxCall
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  try {
    const data = yield call(apiCall, action);
    let payload = action.payload;
    const errors = data.errors;
    let overlay_id =
      "product_" + action.product_id + "_" + "layout_" + payload.setno + "_" + payload.pageno;
    if (errors == null || errors.length == 0) {
      yield put(hideOverlay(overlay_id));
      yield* getSets({
        type: GET_PRODUCT_SETS,
        id: payload.product_id,
        reducerKey: ADD_ALL_SETS,
      });
      yield put(getActionSetPlaceHolders(payload.product_id, payload.setno));
    } else {
      setTimeout(function () {
        alert(errors.join("\n"));
      }, 100);
    }
  } catch (error) {
    throw error;
  }
}

function* createSetOccurenceCount(action) {
  const apiCall = (action) => {
    let payload = action.payload;
    let url = action.url;
    url = url.replace(":product_id", payload.product_id);
    url = url.replace(":setno", payload.setno);
    // url = url.replace(":dlname", payload.dlname);
    let ajaxCall = fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: action.method,
      body: JSON.stringify(payload),
    });
    return ajaxCall
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  try {
    const data = yield call(apiCall, action);
    let payload = action.payload;
    const errors = data.errors;
    if (errors == null || errors.length == 0) {
      yield put(
        hideOverlay(payload.product_id + "/sets/" + payload.setno + "/counts/" + payload.dlname)
      );
      yield* getSets({
        type: GET_PRODUCT_SETS,
        id: payload.product_id,
        reducerKey: ADD_ALL_SETS,
      });
      yield put(getActionSetPlaceHolders(payload.product_id, payload.setno));
    } else {
      setTimeout(function () {
        alert(errors.join("\n"));
      }, 100);
    }
  } catch (error) {
    throw error;
  }
}
function* updateSetOccurenceCount(action) {
  const apiCall = (action) => {
    let payload = action.payload;
    let url = action.url;
    url = url.replace(":product_id", payload.product_id);
    url = url.replace(":setno", payload.setno);
    url = url.replace(":dlname", payload.dlname);
    let ajaxCall = fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: action.method,
      body: JSON.stringify(payload),
    });
    return ajaxCall
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  try {
    const data = yield call(apiCall, action);
    let payload = action.payload;
    const errors = data.errors;
    if (errors == null || errors.length == 0) {
      yield put(
        hideOverlay(payload.product_id + "/sets/" + payload.setno + "/counts/" + payload.dlname)
      );
      yield* getSets({
        type: GET_PRODUCT_SETS,
        id: payload.product_id,
        reducerKey: ADD_ALL_SETS,
      });
      yield put(getActionSetPlaceHolders(payload.product_id, payload.setno));
    } else {
      setTimeout(function () {
        alert(errors.join("\n"));
      }, 100);
    }
  } catch (error) {
    throw error;
  }
}

export function* createProductSetSaga() {
  yield takeEvery(CREATE_PRODUCT_SET, createSet);
}

export function* createProductSetKeySaga() {
  yield takeEvery(CREATE_PRODUCT_SET_KEY, createSetKey);
}

export function* createProductSetLayoutSaga() {
  yield takeEvery(CREATE_PRODUCT_SET_LAYOUT, createSetLayout);
}

export function* deleteProductSetLayoutSaga() {
  yield takeEvery(DELETE_PRODUCT_SET_LAYOUT, deleteSetLayout);
}

export function* updateProductSetKeySaga() {
  yield takeEvery(UPDATE_PRODUCT_SET_KEY, updateSetKey);
}

export function* createProductSetDLValSaga() {
  yield takeEvery(CREATE_PRODUCT_SET_DLVAL, createSetKey);
}

export function* updateProductSetDLValSaga() {
  yield takeEvery(UPDATE_PRODUCT_SET_DLVAL, updateSetKey);
}

export function* deleteProductSetSaga() {
  yield takeEvery(DELETE_PRODUCT_SET, deleteSet);
}

export function* updateProductSetSaga() {
  yield takeEvery(UPDATE_PRODUCT_SET, updateSet);
}

export function* getProductSetsSaga() {
  yield takeEvery(GET_PRODUCT_SETS, getSets);
}

export function* getProductSetsPlaceholdersSaga() {
  yield takeEvery(GET_PRODUCT_SET_PLACEHOLDERS, getSetPlaceholders);
}

export function* getSupportedLayoutsSaga() {
  yield takeEvery(GET_SUPPORTED_PRODUCT_LAYOUTS, getSupportedLayouts);
}

export function* createSetOcurrenceCountSaga() {
  yield takeEvery(ADD_SET_OCCURENCE_COUNT, createSetOccurenceCount);
}
export function* updateSetOcurrenceCountSaga() {
  yield takeEvery(UPDATE_SET_OCCURENCE_COUNT, updateSetOccurenceCount);
}
