import "babel-polyfill";
import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import createLogger from "redux-logger";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../sagas";
import AdukkuApp from "../reducers/product_reducers";
import ProductListContainer from "../containers/ProductListContainer";

//const logger = createLogger({});
const sagaMiddleware = createSagaMiddleware();
//const store = createStore(AdukkuApp, applyMiddleware(logger, sagaMiddleware)); // Bala
const store = createStore(AdukkuApp, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(rootSaga);
class MyProducts extends React.Component {
  componentDidMount() {
    let user_role = document.getElementById("user_role");
    user_role = user_role.innerHTML;
    // this.setState({ role: user_role });
  }
  render() {
    return (
      <div style={{paddingLeft: 20}}>
      <Provider store={store}>
        <ProductListContainer />
      </Provider>
      </div>
    );
  }
}
export default MyProducts;
