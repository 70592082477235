export const GET_PRODUCT_LAYOUTS = "GET_PRODUCT_LAYOUTS";
export const DELETE_PRODUCT_LAYOUT = "DELETE_PRODUCT_LAYOUT";
export const CREATE_PRODUCT_LAYOUT = "CREATE_PRODUCT_LAYOUT";
export const UPDATE_PRODUCT_LAYOUT = "UPDATE_PRODUCT_LAYOUT";
export const ADD_ALL_LAYOUTS = "ADD_ALL_LAYOUTS";
export const ADD_LAYOUT = "ADD_LAYOUT";
export const CLONE_LAYOUT = "CLONE_LAYOUT";
export const CLONE_PRODUCT_LAYOUT = "CLONE_PRODUCT_LAYOUT";
export const DELETE_LAYOUT = "DELETE_LAYOUT";
export const ADD_LY_DATALEVELS = "ADD_LY_DATALEVELS";
export const DELETE_LY_DATALEVEL = "DELETE_LY_DATALEVEL";
export const GET_LAYOUT_DETAILS = "GET_LAYOUT_DETAILS";
export const CREATE_LY_DATALEVEL = "CREATE_LY_DATALEVEL";
export const CREATE_LY_DATALEVEL_REL = "CREATE_LY_DATALEVEL_REL";
export const DELETE_LY_DATALEVEL_REL = "DELETE_LY_DATALEVEL_REL";
export const CREATE_LY_DATALEVEL_REL_KEY = "CREATE_LY_DATALEVEL_REL_KEY";
export const DELETE_LY_DATALEVEL_REL_KEY = "DELETE_LY_DATALEVEL_REL_KEY";
export const CREATE_LY_SLOTFOR = "CREATE_LY_SLOTFOR";
export const DELETE_LY_SLOTFOR = "DELETE_LY_SLOTFOR";

export const getLayouts = (product_id, payload = {}) => {
  return {
    type: GET_PRODUCT_LAYOUTS,
    id: product_id,
    reducerKey: ADD_ALL_LAYOUTS,
    payload: Object.assign({}, payload, { product_id: product_id }),
  };
};

export const createLayout = (url, method, payload) => {
  return {
    type: CREATE_PRODUCT_LAYOUT,
    payload: payload,
    url: url,
    method: method,
  };
};

export const updateLayout = (id, url, method, payload) => {
  // return {type: UPDATE_PRODUCT, id: id, payload: payload};
  return {
    type: UPDATE_PRODUCT_LAYOUT,
    id: id,
    url: url,
    method: method,
    payload: payload,
  };
};

export const cloneLayout = (url, method, payload) => {
  return {
    type: CLONE_PRODUCT_LAYOUT,
    payload: payload,
    url: url,
    method: method,
  };
};

export const deleteLayout = (url, method, payload) => {
  return {
    type: DELETE_PRODUCT_LAYOUT,
    payload: payload,
    url: url,
    method: method,
    id: payload.id,
    product_id: payload.product_id,
  };
};

export const deleteLyDL = (url, method, payload) => {
  return {
    type: DELETE_LY_DATALEVEL,
    payload: payload,
    url: url,
    method: method,
  };
};

export const createLyDL = (url, method, payload) => {
  return {
    type: CREATE_LY_DATALEVEL,
    payload: payload,
    url: url,
    method: method,
  };
};

export const getLayoutDetails = (layout_id, payload = {}) => {
  return {
    type: GET_LAYOUT_DETAILS,
    id: layout_id,
    payload: Object.assign({}, payload, { layout_id: layout_id }),
  };
};

export const deleteLyDLRel = (url, method, payload) => {
  return {
    type: DELETE_LY_DATALEVEL_REL,
    payload: payload,
    url: url,
    method: method,
  };
};

export const createLyDLRel = (url, method, payload) => {
  return {
    type: CREATE_LY_DATALEVEL_REL,
    payload: payload,
    url: url,
    method: method,
  };
};

export const deleteLyDLRelKey = (url, method, payload) => {
  return {
    type: DELETE_LY_DATALEVEL_REL_KEY,
    payload: payload,
    url: url,
    method: method,
  };
};

export const createLyDLRelKey = (url, method, payload) => {
  return {
    type: CREATE_LY_DATALEVEL_REL_KEY,
    payload: payload,
    url: url,
    method: method,
  };
};

export const deleteLyDLSlotFor = (url, method, payload) => {
  return {
    type: DELETE_LY_SLOTFOR,
    payload: payload,
    url: url,
    method: method,
  };
};

export const createLyDLSlotFor = (url, method, payload) => {
  return {
    type: CREATE_LY_SLOTFOR,
    payload: payload,
    url: url,
    method: method,
  };
};
