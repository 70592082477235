import React, { useState } from 'react';
import { Navbar, Nav, NavItem, MenuItem, NavDropdown, Dropdown } from 'react-bootstrap';
import logo from 'images/Composz-logo-white-trans.png';
import logoBlack from 'images/Composz-logo-black-trans.png';
import profile from 'images/blankProfile.png';
import CartState from "../context/cart/CartState";
import CartContext from "../context/cart/CartContext";
import { useContext } from "react";
import { Container } from 'react-bootstrap/lib/Tab';


function ProductMynavbar (user_id, user_name, user_email, has_view_products) {

  // Extract itemscount from CartContext
  const { cartItems } = useContext(CartContext);

  const [show3, setShow3] = useState(false);
  const showDropdown3 = (e)=>{
      setShow3(true);
   }
  const hideDropdown3 = e => {
     setShow3(false);
   }
  
  const [show2, setShow2] = useState(false);
  const showDropdown2 = (e)=>{
      setShow2(true);
  }
  const hideDropdown2 = e => {
    setShow2(false);
  }
  
  const [show1, setShow1] = useState(false);
  const showDropdown1 = (e)=>{
      setShow1(true);
      setShow(false);
   }
   const hideDropdown1 = e => {
     setShow1(false);
   }
   
   const [show, setShow] = useState(false);
   const showDropdown = (e)=>{
       setShow(true);
    }
   const hideDropdown = e => {
      setShow(false);
    }
   const toggleShow = e => {
     setShow(!show)
     setShow1(false)
     setShow2(false)
     setShow3(false)
   }
   const toggleShow1 = e => {
     setShow1(!show1)
     setShow(false)
     setShow2(false)
     setShow3(false)
   }
   const toggleShow2 = e => {
     setShow2(!show2)
     setShow(false)
     setShow1(false)
     setShow3(false)
    }
   const toggleShow3 = e => {
     setShow3(!show3)
     setShow(false)
     setShow1(false)
     setShow2(false)
   }

   const handleToggle = e => {
    ;; // do nothing
   }
  

    return (
        window.innerWidth > 450 ?
        <Navbar  expand="lg" inverse collapseOnSelect style={{paddingLeft:"180px", borderRadius:"0px", backgroundColor: "#000", color:"#fff", position: "sticky", zIndex:1000, minHeight:"52px", maxHeight:"52px"}} variant="dark" fixed="top">
            
            <Navbar.Collapse>
            <Nav >
                <NavItem  eventKey={2} href="/" >
                 <img height="50px" src={logo} style={{paddingBottom:"10px"}}/> 
                </NavItem>
                {user_id===-1?  
                  <Nav style={{paddingTop:"7px"}}>
                    <NavItem eventKey={2} href="#">
                      {""}
                    </NavItem>
                    <NavItem eventKey={2} href="#">
                      {""}
                    </NavItem>
                    <NavItem eventKey={2} href="#">
                      {""}
                    </NavItem>
                  </Nav>
                :
                  null
                }
                <NavItem eventKey={2} href="#">
                  {""}
                </NavItem>
                <NavItem eventKey={1} href="/static/Learn.html" target="blank" style={{paddingTop:"7px"}}> 
                   Learn
                </NavItem>
                
                {user_id===-1?
                  <NavDropdown eventKey={3} title="Audience" id="1" style={{paddingTop:"7px"}}
                    onMouseEnter={showDropdown} onMouseLeave={hideDropdown} open = {show}
                    onToggle={handleToggle}>
                    <MenuItem eventKey={3.1} href="/static/Schools.html" target="blank" style={{ backgroundColor: "#000"}}><span style={{ color: "white", fontWeight : "normal", fontSize: "125%"}}>Schools</span></MenuItem>
                    <MenuItem eventKey={3.2} href="/static/Colleges.html" target="blank" style={{ backgroundColor: "#000"}}><span style={{ color: "white", fontWeight : "normal", fontSize: "125%"}}>Colleges</span></MenuItem>
                    <MenuItem eventKey={3.3} href="/static/Students.html" target="blank" style={{ backgroundColor: "#000"}}><span style={{ color: "white", fontWeight : "normal", fontSize: "125%"}}>Students</span></MenuItem>
                    <MenuItem eventKey={3.4} href="/static/AlumniStudents.html" target="blank" style={{ backgroundColor: "#000"}}><span style={{ color: "white", fontWeight : "normal", fontSize: "125%"}}>Alumni Students</span></MenuItem>
                    <MenuItem eventKey={3.5} href="/static/Photographers.html" target="blank" style={{ backgroundColor: "#000"}}><span style={{ color: "white", fontWeight : "normal", fontSize: "125%"}}>Photographers</span></MenuItem>
                    <MenuItem eventKey={3.6} href="/static/Groups.html" target="blank" style={{ backgroundColor: "#000"}}><span style={{ color: "white", fontWeight : "normal", fontSize: "125%"}}>Groups</span></MenuItem>
                  </NavDropdown>
                : 
                  <NavItem eventKey={2} href="#">
                    {""}
                  </NavItem>
                }
                {user_id===-1?
                  <NavDropdown eventKey={3} title="Products" id="2" style={{paddingTop:"7px"}}
                    onMouseEnter={showDropdown1} onMouseLeave={hideDropdown1} open = {show1}
                    onToggle={handleToggle}>
                    <MenuItem eventKey={3.1} href="/static/Composites.html" target="blank" style={{ backgroundColor: "#000"}}><span style={{ color: "white", fontWeight : "normal", fontSize: "125%"}}>Composites</span></MenuItem>
                    <MenuItem eventKey={3.2} href="/static/PictureBooklets.html" target="blank" style={{ backgroundColor: "#000"}}><span style={{ color: "white", fontWeight : "normal", fontSize: "125%"}}>Picture Booklets</span></MenuItem>
                    <MenuItem eventKey={3.3} href="/static/SportsBooklets.html" target="blank" style={{ backgroundColor: "#000"}}><span style={{ color: "white", fontWeight : "normal", fontSize: "125%"}}>Sports Booklets</span></MenuItem>
                    <MenuItem eventKey={3.4} href="/static/GraduationFolders.html" target="blank" style={{ backgroundColor: "#000"}}><span style={{ color: "white", fontWeight : "normal", fontSize: "125%"}}>Graduation Folders</span></MenuItem>
                    <MenuItem eventKey={3.5} href="/static/CustomYearBooks.html" target="blank" style={{ backgroundColor: "#000"}}><span style={{ color: "white", fontWeight : "normal", fontSize: "125%"}}>Custom Year Books</span></MenuItem>
                    <MenuItem eventKey={3.6} href="/static/ReportCardFolders.html" target="blank" style={{ backgroundColor: "#000"}}><span style={{ color: "white", fontWeight : "normal", fontSize: "125%"}}>Report Card Folders</span></MenuItem>
                    <MenuItem eventKey={3.7} href="/static/PortraitSheets.html" target="blank" style={{ backgroundColor: "#000"}}><span style={{ color: "white", fontWeight : "normal", fontSize: "125%"}}>Portrait Sheets</span></MenuItem>
                    <MenuItem eventKey={3.8} href="/static/PassportSheets.html" target="blank" style={{ backgroundColor: "#000"}}><span style={{ color: "white", fontWeight : "normal", fontSize: "125%"}}>Passport Sheets</span></MenuItem>
                    <MenuItem eventKey={3.9} href="/static/IDCardSheets.html" target="blank" style={{ backgroundColor: "#000"}}><span style={{ color: "white", fontWeight : "normal", fontSize: "125%"}}>ID Card Sheets</span></MenuItem>
                    <MenuItem eventKey={3.10} href="/static/CustomCertificates.html" target="blank" style={{ backgroundColor: "#000"}}><span style={{ color: "white", fontWeight : "normal", fontSize: "125%"}}>Custom Certificates</span></MenuItem>
                    <MenuItem eventKey={3.11} href="/static/OtherProducts.html" target="blank" style={{ backgroundColor: "#000"}}><span style={{ color: "white", fontWeight : "normal", fontSize: "125%"}}>Other Products</span></MenuItem>
                  </NavDropdown>
                : 
                  <NavItem eventKey={2} href="#">
                    {""}
                  </NavItem>
                }  
                <NavItem eventKey={2} href="#">
                  {""}
                </NavItem>
                <NavItem eventKey={2} href="#">
                  {""}
                </NavItem>
                <NavItem eventKey={2} href="#">
                  {""}
                </NavItem>
                <NavItem eventKey={2} href="#">
                  {""}
                </NavItem>
                <NavItem eventKey={2} href="#">
                  {""}
                </NavItem>
                <NavItem eventKey={2} href="#">
                  {""}
                </NavItem>
                <NavItem eventKey={2} href="#">
                  {""}
                </NavItem>
              </Nav >
              {user_id!=-1?
                <Nav style={{paddingTop:"7px"}}>
                  <NavItem eventKey={2} href="#">
                    {""}
                  </NavItem>
                  <NavItem eventKey={2} href="#">
                    {""}
                  </NavItem>
                  <NavItem eventKey={2} href="#">
                    {""}
                  </NavItem>
                  <NavItem eventKey={2} href="#">
                    {""}
                  </NavItem>
                  <NavItem eventKey={2} href="#">
                    {""}
                  </NavItem>
                </Nav>
              :
              <Nav style={{paddingTop:"7px"}}>
                <NavItem eventKey={2} href="#">
                  {""}
                </NavItem>
                <NavItem eventKey={2} href="#">
                  {""}
                </NavItem>
                <NavItem eventKey={1} href="/presets" >
                  Presets
                </NavItem>
                <NavDropdown eventKey={3} title="Dashboard" id="3"
                  onMouseEnter={showDropdown2} onMouseLeave={hideDropdown2} open = {show2}
                  onToggle={handleToggle}>
                  <MenuItem eventKey={3.1} href="/products/add" style={{backgroundColor: "#000000"}}><span style={{ color: "white", fontWeight : "normal", fontSize: "125%"}}>Parents: Add your School's View/Pay Products</span></MenuItem>
                </NavDropdown>
              </Nav>
              }
              {user_id!=-1?
                  <Nav pullRight >
                  <NavItem eventKey={2} href="#">
                  {""}
                  </NavItem>
                  <NavItem eventKey={2} href="#">
                  {""}
                  </NavItem>
                  <NavItem eventKey={1} href="/presets" style={{paddingTop:"7px"}}>
                    Presets
                  </NavItem>
                    <NavDropdown eventKey={3} title="Dashboard" id="3" style={{paddingTop:"7px"}}
                      onMouseEnter={showDropdown2} onMouseLeave={hideDropdown2} open = {show2}
                      onToggle={handleToggle}>
                      <MenuItem eventKey={3.1} href="/products" style={{ backgroundColor: "#000000"}}><span style={{ color: "white", fontWeight : "normal", fontSize: "125%"}}>My Products</span></MenuItem>
                      {has_view_products?
                        <MenuItem eventKey={3.2} href="/orders" target="blank" style={{ backgroundColor: "#000000"}}><span style={{ color: "white", fontWeight : "normal", fontSize: "125%"}}>View/Pay Products</span></MenuItem>
                      :
                        <MenuItem eventKey={3.2} href="/products/add" target="blank" style={{ backgroundColor: "#000000"}}><span style={{ color: "white", fontWeight : "normal", fontSize: "125%"}}>Add View/Pay Products</span></MenuItem>
                      }  
                    </NavDropdown>
                    <NavItem eventKey={1} href="/orders/cart" style={{paddingTop:"7px"}}>
                      Cart (
                          <span id="carCounter" style={{color:"green", fontWeight:"bold"}}>
                            {cartItems.quantity===null? <i className="fa fa-shopping-cart" height="20px" aria-hidden="true"></i>
                            :cartItems.quantity}</span>)
                    </NavItem>
                    <NavDropdown eventKey={2} id="4" title={<img height="20px" src={profile} style={{paddingTop:"3px"}}/>  }  
                      onMouseEnter={showDropdown3} onMouseLeave={hideDropdown3} open = {show3}
                      style={{paddingTop:"3px"}}
                      onToggle={handleToggle}> 
                    <li className = "dropdown-header" ><span style={{ textAlign:"center", color: "gray", fontWeight : "normal", fontSize: "125%"}}>Logged in as: </span><br></br>
                      <span style={{ textAlign:"center", color: "white", fontWeight : "bold", fontSize: "125%"}}>{user_name}</span><br></br>
                      <span style={{ textAlign:"center", color: "white", fontWeight : "bold", fontSize: "125%"}}>{user_email}</span></li>
                      <MenuItem eventKey={3.1} href="/users/sign_out" data-method="delete" style={{ backgroundColor: "#ff0000"}}><span style={{ color: "white", fontWeight : "normal", fontSize: "125%"}}>Log out</span></MenuItem>
                    </NavDropdown>
                  </Nav>
                  : 
                  <Nav pullRight style={{paddingTop:"7px"}}>
                    <NavItem eventKey={1} href="/users/sign_in">
                      Login
                    </NavItem>
                  </Nav>
                }
            </Navbar.Collapse>
        </Navbar>
        
        :

        <Navbar  collapseOnSelect bg="dark" variant="dark" style={{backgroundColor: "#000"}} position="top">
          <div style={{paddingTop:"7px"}}>
            <a href="/" className="navbar-left"  ><img height="40px" src={logo} /></a>
          
          <span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          
          <Navbar.Toggle  style={{backgroundColor: "#fff", height: "18px", paddingTop:"3px"}} aria-controls='responsive-navbar-nav'/>
          </div>
          <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav >
              
                <NavItem eventKey={1} href="/static/Learn.html" target="blank" > 
                <span style={{color:"white"}}>Learn</span>
                </NavItem>
                <NavItem divider="true" style={{backgroundColor:'white', height:'1px'}}/>
                  <NavDropdown eventKey={3} backgroundColor ="white" title={<span style={{color:'white'}}>Audience</span>} id="1" 
                    onClick={toggleShow} open={show}
                    onToggle={handleToggle}>
                   <div style={{display:show?"block":"none"}}>
                    <MenuItem eventKey={3.1} href="/static/Schools.html" target="blank" style={{ backgroundColor: "#000"}}><span style={{ color: "white", fontWeight : "normal", fontSize: "125%"}}>Schools</span></MenuItem>
                    <MenuItem eventKey={3.2} href="/static/Colleges.html" target="blank" style={{ backgroundColor: "#000"}}><span style={{ color: "white", fontWeight : "normal", fontSize: "125%"}}>Colleges</span></MenuItem>
                    <MenuItem eventKey={3.3} href="/static/Students.html" target="blank" style={{ backgroundColor: "#000"}}><span style={{ color: "white", fontWeight : "normal", fontSize: "125%"}}>Students</span></MenuItem>
                    <MenuItem eventKey={3.4} href="/static/AlumniStudents.html" target="blank" style={{ backgroundColor: "#000"}}><span style={{ color: "white", fontWeight : "normal", fontSize: "125%"}}>Alumni Students</span></MenuItem>
                    <MenuItem eventKey={3.5} href="/static/Photographers.html" target="blank" style={{ backgroundColor: "#000"}}><span style={{ color: "white", fontWeight : "normal", fontSize: "125%"}}>Photographers</span></MenuItem>
                    <MenuItem eventKey={3.6} href="/static/Groups.html" target="blank" style={{ backgroundColor: "#000"}}><span style={{ color: "white", fontWeight : "normal", fontSize: "125%"}}>Groups</span></MenuItem>
                   </div>
                  </NavDropdown>
                  <NavItem divider="true" style={{backgroundColor:'white', height:'0px'}}/>
                  <NavDropdown eventKey={3} title={<span style={{color:'white'}}>Products</span>} id="2"
                    onClick={toggleShow1} open={show1}
                    onToggle={handleToggle}>
                   <div style={{display:show1?"block":"none"}}>
                    <MenuItem eventKey={3.1} href="/static/Composites.html" target="blank" style={{ backgroundColor: "#000"}}><span style={{ color: "white", fontWeight : "normal", fontSize: "125%"}}>Composites</span></MenuItem>
                    <MenuItem eventKey={3.2} href="/static/PictureBooklets.html" target="blank" style={{ backgroundColor: "#000"}}><span style={{ color: "white", fontWeight : "normal", fontSize: "125%"}}>Picture Booklets</span></MenuItem>
                    <MenuItem eventKey={3.3} href="/static/SportsBooklets.html" target="blank" style={{ backgroundColor: "#000"}}><span style={{ color: "white", fontWeight : "normal", fontSize: "125%"}}>Sports Booklets</span></MenuItem>
                    <MenuItem eventKey={3.4} href="/static/GraduationFolders.html" target="blank" style={{ backgroundColor: "#000"}}><span style={{ color: "white", fontWeight : "normal", fontSize: "125%"}}>Graduation Folders</span></MenuItem>
                    <MenuItem eventKey={3.5} href="/static/CustomYearBooks.html" target="blank" style={{ backgroundColor: "#000"}}><span style={{ color: "white", fontWeight : "normal", fontSize: "125%"}}>Custom Year Books</span></MenuItem>
                    <MenuItem eventKey={3.6} href="/static/ReportCardFolders.html" target="blank" style={{ backgroundColor: "#000"}}><span style={{ color: "white", fontWeight : "normal", fontSize: "125%"}}>Report Card Folders</span></MenuItem>
                    <MenuItem eventKey={3.7} href="/static/PortraitSheets.html" target="blank" style={{ backgroundColor: "#000"}}><span style={{ color: "white", fontWeight : "normal", fontSize: "125%"}}>Portrait Sheets</span></MenuItem>
                    <MenuItem eventKey={3.8} href="/static/PassportSheets.html" target="blank" style={{ backgroundColor: "#000"}}><span style={{ color: "white", fontWeight : "normal", fontSize: "125%"}}>Passport Sheets</span></MenuItem>
                    <MenuItem eventKey={3.9} href="/static/IDCardSheets.html" target="blank" style={{ backgroundColor: "#000"}}><span style={{ color: "white", fontWeight : "normal", fontSize: "125%"}}>ID Card Sheets</span></MenuItem>
                    <MenuItem eventKey={3.10} href="/static/CustomCertificates.html" target="blank" style={{ backgroundColor: "#000"}}><span style={{ color: "white", fontWeight : "normal", fontSize: "125%"}}>Custom Certificates</span></MenuItem>
                    <MenuItem eventKey={3.11} href="/static/OtherProducts.html" target="blank" style={{ backgroundColor: "#000"}}><span style={{ color: "white", fontWeight : "normal", fontSize: "125%"}}>Other Products</span></MenuItem>
                   </div> 
                  </NavDropdown>
                  <NavItem divider="true" style={{backgroundColor:'white', height:'1px'}}/>
                <NavItem eventKey={1} href="/presets">
                <span style={{color:"white"}}>Presets</span>
                </NavItem>
                <NavItem divider="true" style={{backgroundColor:'white', height:'1px'}}/>  
                {user_id!=-1?
                  <Nav style={{paddingLeft:"15px"}}>
                  
                    <NavDropdown eventKey={3} title={<span style={{color:'white'}}>Dashboard</span>}id="3"
                      onClick={toggleShow2} open={show2}
                      onToggle={handleToggle}>
                       <div style={{display:show2?"block":"none"}}>
                      <MenuItem eventKey={3.1} href="/products" style={{ backgroundColor: "#000000"}}><span style={{ color: "white", fontWeight : "normal", fontSize: "125%"}}>My Products</span></MenuItem>
                      {has_view_products?
                        <MenuItem eventKey={3.2} href="/orders" target="blank" style={{ backgroundColor: "#000000"}}><span style={{ color: "white", fontWeight : "normal", fontSize: "125%"}}>View/Pay Products</span></MenuItem>
                      :
                        <MenuItem eventKey={3.2} href="/products/add" target="blank" style={{ backgroundColor: "#000000"}}><span style={{ color: "white", fontWeight : "normal", fontSize: "125%"}}>Add View/Pay Products</span></MenuItem>
                      }  
                     </div> 
                    </NavDropdown>
                    <NavItem divider="true" style={{backgroundColor:'white', height:'1px'}}/>
                    <NavItem eventKey={1} href="/orders/cart" >
                    <span style={{color:"white"}}>Cart</span> 
                    </NavItem>
                    <NavItem divider="true" style={{backgroundColor:'white', height:'1px'}}/>
                    <NavDropdown eventKey={2} id="4" title={<img height="20px" src={profile}/>} 
                      onClick={toggleShow3} open={show3}
                      onToggle={handleToggle}>
                     <div style={{display:show3?"block":"none"}}>
                      <li className = "dropdown-header" ><span style={{ textAlign:"center", color: "gray", fontWeight : "normal", fontSize: "125%"}}>Logged in as: </span><br></br><span style={{ textAlign:"center", color: "white", fontWeight : "bold", fontSize: "125%"}}>{user_name}</span></li>
                        <MenuItem eventKey={3.1} href="/users/sign_out" data-method="delete" style={{ backgroundColor: "#ff0000"}}><span style={{ color: "white", fontWeight : "normal", fontSize: "125%"}}>Log out</span></MenuItem>
                     </div>                      
                    </NavDropdown>
                  </Nav>
                  : 
                  <Nav style={{paddingLeft:"15px"}}>
                    <NavItem eventKey={1} href="/users/sign_in">
                      <span style={{color:"white"}}>Login</span>
                    </NavItem>
                  </Nav>
                }

           </Nav>
        </Navbar.Collapse>
     </Navbar>

      );
}

export default ProductMynavbar
