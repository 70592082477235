import {connect} from 'react-redux'
import ProductSetDLValList from '../../components/product_sets/ProductSetDLValList'
import {bindActionCreators} from 'redux';
import { createSelector } from 'reselect'
import {
  toggleOverlay
 ,hideOverlay
 ,showOverlay
} from '../../actions/misc_actions'

const mapStateToProps = (state, ownProps) => {
  return Object.assign({}, ownProps, {overlay: state.overlays});
};

const mapDispatchToProps = (dispatch) =>bindActionCreators({
 hideOverlay
 ,showOverlay
},dispatch);


const ProductSetDLValListContainer = connect(mapStateToProps, mapDispatchToProps)(ProductSetDLValList);

export default ProductSetDLValListContainer;