import { connect } from "react-redux";
import ProductSetLayout from "../../components/product_sets/ProductSetLayout";
import { hideOverlay, showOverlay } from "../../actions/misc_actions";
import { bindActionCreators } from "redux";
import { createSetLayout, deleteSetLayout } from "../../actions/product_sets";
import { getSupportedLayouts } from "../../actions/product_sets";

const mapStateToProps = (state, ownProps) => {
  let overlay_id =
    "product_" +
    ownProps.product_id +
    "_" +
    "layout_" +
    ownProps.setno +
    "_" +
    ownProps.pageno;
  const product = state.products.filter(p => p.id == ownProps.product_id)[0];
  const supported_layouts =
    state.product_sets[
      ownProps.product_id + "_set_" + ownProps.setno + "_" + ownProps.pageno
    ] || [];
  const set_layouts =
    state.product_sets[product.id][ownProps.setno].layouts || [];
  const set_layout =
    set_layouts.filter(p => p.pageno == ownProps.pageno)[0] || {};
  return Object.assign({}, ownProps, {
    supported_layouts: supported_layouts,
    overlay_id: overlay_id,
    product: product,
    layout: set_layout,
    overlay: state.overlays[overlay_id] || { visible: false }
  });
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createSetLayout,
      hideOverlay,
      showOverlay,
      deleteSetLayout,
      getSupportedLayouts
    },
    dispatch
  );

const ProductSetLayoutContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductSetLayout);

export default ProductSetLayoutContainer;
