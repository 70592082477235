import { connect } from "react-redux";
import DownloadFiles from "../../components/orders/DownloadFiles";
import { bindActionCreators } from "redux";
import { getSets } from "../../actions/product_sets";

const mapStateToProps = (state, ownProps) => {
  let product = state.products.filter((e) => e.id === ownProps.product_id)[0];
  let product_sets = Object.values(
    (state.product_sets && state.product_sets[ownProps.product_id]) || {}
  );
  const orders = (product && product.order) || [];
  const paidOrders = orders.filter((o) => o.status == "paid");
  const main_dl = ((state.product_datalevels && state.product_datalevels) || []).find(
    (pdl) => pdl.dltype === "main"
  );
  const main_dlname = main_dl && main_dl.dlname;
  product.product_sets =
    product && product.product_sets && product.product_sets.length > 0
      ? product.product_sets
      : product_sets;
  return {
    product: product,
    product_sets: product.product_sets || product_sets,
    main_dlname: main_dlname,
    paidOrders: paidOrders,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ getSets }, dispatch);

const DownloadFilesContainer = connect(mapStateToProps, mapDispatchToProps)(DownloadFiles);

export default DownloadFilesContainer;
