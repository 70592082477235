import React from "react";
import { Col, Modal, Button, Row } from "react-bootstrap";

import { FieldGroup } from "../bootstrap/Forms.FieldGroup";
import { ENDPOINTS } from "../../constants/endpoints";
import ThumbnailPreview from "../layouts/ThumbnailPreview";
// import StoragePayment from "./StoragePayment";

export default class AdminDefaults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      avail_space: "",
      adminDefaults: {},
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isFileManagerOpen && this.state.isFileManagerOpen != prevState.isFileManagerOpen)
      this.getPurchaseHistory();
  }

  getPurchaseHistory = () => {
    let uri = ENDPOINTS.user_storages.get_defaults.uri;
    let that = this;
    return fetch(uri, {
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        that.setState({
          avail_space: response.data.avail_space || 0,
          product_expiry_hours: response.data.product_expiry_hours || 0,
          space_expiry_hours: response.data.space_expiry_hours || 0,
        });
      });
  };

  updateAdminDefaults = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let uri = ENDPOINTS.user_storages.update_defaults.uri;
    let that = this;
    let formData = new FormData();
    formData.append("avail_space", this.state.avail_space);
    formData.append("product_expiry_hours", this.state.product_expiry_hours);
    formData.append("space_expiry_hours", this.state.space_expiry_hours);
    formData.append("storage_unit", "MEGA_BYTE");
    formData.append("type", "set_defaults");
    return fetch(uri, {
      method: "PUT",
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        that.setState({
          adminDefaults: response.data,
          isFileManagerOpen: false,
        });
      });
  };

  openFileManager = () => {
    this.setState({
      isFileManagerOpen: !this.state.isFileManagerOpen,
    });
  };

  render() {
    const { isFileManagerOpen, adminDefaults } = this.state;
    return (
      <li>
        <a onClick={this.openFileManager}>{"Set Admin Defaults"}</a>
        <Modal size="xl" show={isFileManagerOpen} onHide={this.openFileManager}>
          <Modal.Header closeButton>
            <Modal.Title>Purchase Storage</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={12}>
                <Col md={6}>
                  <span style={{ display: "inline-flex" }}>
                    <FieldGroup
                      id="formControlPages"
                      type="number"
                      label="Default Avail Space"
                      name="avail_space"
                      placeholder="in Mega Bytes"
                      required="required"
                      onChange={({ target: { name, value } }) => {
                        this.setState({ [name]: value });
                      }}
                      value={
                        adminDefaults && adminDefaults.avail_space
                          ? adminDefaults.avail_space
                          : this.state.avail_space
                      }
                    />
                    <span style={{ margin: "35px 0px 0px 4px" }}> MB</span>
                  </span>
                </Col>
                <Col md={6}>
                  <span style={{ display: "inline-flex" }}>
                    <FieldGroup
                      id="formControlPages"
                      type="number"
                      label="Default Product Expiry"
                      name="product_expiry_hours"
                      placeholder="in Hours"
                      required="required"
                      onChange={({ target: { name, value } }) => {
                        this.setState({ [name]: value });
                      }}
                      value={
                        adminDefaults && adminDefaults.product_expiry_hours
                          ? adminDefaults.product_expiry_hours
                          : this.state.product_expiry_hours
                      }
                    />
                    <span style={{ margin: "35px 0px 0px 4px" }}> Hours</span>
                  </span>
                </Col>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <Col md={6}>
                  <span style={{ display: "inline-flex" }}>
                    <FieldGroup
                      id="formControlPages"
                      type="number"
                      label="Default Space Expiry"
                      name="space_expiry_hours"
                      placeholder="in Hours"
                      required="required"
                      onChange={({ target: { name, value } }) => {
                        this.setState({ [name]: value });
                      }}
                      value={
                        adminDefaults && adminDefaults.space_expiry_hours
                          ? adminDefaults.space_expiry_hours
                          : this.state.space_expiry_hours
                      }
                    />
                    <span style={{ margin: "35px 0px 0px 4px" }}> Hours</span>
                  </span>
                </Col>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={(e) => this.updateAdminDefaults(e)}>Save</Button>
            {/* <Button onClick={this.openFileManager}>Cancel</Button> */}
          </Modal.Footer>
        </Modal>
      </li>
    );
  }
}
