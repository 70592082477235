import { connect } from 'react-redux'
import AddLySlotFor from '../../../components/layouts/lyslotfors/AddLySlotFor';
import { createLyDLSlotFor } from '../../../actions/layouts';

import {bindActionCreators} from 'redux';
const mapStateToProps = (state, ownProps) => {
  const product = state.products.filter(p => p.id == ownProps.product_id)[0];
  const layout = state.layouts.filter(p => p.id == ownProps.layout_id)[0];
  let product_datalevels = state.product_datalevels.filter(dl => {
    return dl.product_id == ownProps.product_id
  });
  let slotFors = layout.datalevel_slotfors
  let layout_datalevels = layout.datalevels.reduce((accumilator, lyDl) => {
    accumilator.push(lyDl.dlname);
    return accumilator;
  }, [])
  product_datalevels = product_datalevels.filter(pDl => (layout_datalevels.indexOf(pDl.dlname) != -1))
  return Object.assign({}, ownProps, {layout: layout, layout_datalevels: product_datalevels, product: product, slotFors: slotFors });
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  createLyDLSlotFor
}, dispatch);

const AddLySlotForContainer = connect(mapStateToProps, mapDispatchToProps)(AddLySlotFor);

export default AddLySlotForContainer;