import { connect } from 'react-redux'
import LyDlList from '../../../components/layouts/lydl/LyDlList';
import { getLayoutDetails } from '../../../actions/layouts';

import {bindActionCreators} from 'redux';
const mapStateToProps = (state, ownProps) => {
  const product = state.products.filter(p => p.id == ownProps.product_id)[0];
  const layout = state.layouts.filter(p => p.id == ownProps.layout_id)[0];
  // let product_datalevels = state.product_datalevels.filter(dl => dl.product_id == ownProps.product_id);
  // let layout_datalevels = layout.layout_datalevels.filter(dl => dl.layout_id == ownProps.layout_id);
  return Object.assign({}, ownProps, {layout: layout, product: product });
};

const mapDispatchToProps = (dispatch) =>bindActionCreators({
  getLayoutDetails
},dispatch);

const LyDlListContainer = connect(mapStateToProps, mapDispatchToProps)(LyDlList);

export default LyDlListContainer;