export const GET_PRODUCT_KEYS = "GET_PRODUCT_KEYS";
export const DELETE_PRODUCT_KEY = "DELETE_PRODUCT_KEY";
export const CREATE_PRODUCT_KEY = "CREATE_PRODUCT_KEY";
export const ADD_ALL_KEYS = "ADD_ALL_KEYS";
export const ADD_KEY = "ADD_KEY";
export const DELETE_KEY = "DELETE_KEY";

export const getKeys = (product_id) => {
  return { type: GET_PRODUCT_KEYS, id: product_id, reducerKey: ADD_ALL_KEYS };
};

export const createKey =(url, method, payload) => {
  return { type: CREATE_PRODUCT_KEY, payload: payload, url: url, method: method };
}


export const deleteKey =(url, method, payload) => {
  return { type: DELETE_PRODUCT_KEY, payload: payload, url: url, method: method };
}