import React from "react";
import { Col, Form, Modal, Button } from "react-bootstrap";

import { ENDPOINTS } from "../../constants/endpoints";
import { FieldGroup } from "../bootstrap/Forms.FieldGroup";
import { formDataToJSON } from "../../adukku_utils/array";
import { authorized, Authorized } from "../../adukku_utils/authorizeUser";

export default class ProductSetKeyVal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
    this.onEditClick = this.onEditClick.bind(this);
    this.hideAddSetOverlay = this.hideAddSetOverlay.bind(this);
    this._handleValidSubmit = this._handleValidSubmit.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state !== nextProps) {
      this.setState(nextProps);
    }
  }

  onEditClick(e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.showOverlay(
      this.state.product_id + "/sets/" + this.state.setno + "/" + this.state.keyname
    );
  }

  hideAddSetOverlay(e) {
    if (e == undefined) return;
    e.preventDefault();
    e.stopPropagation();
    this.props.hideOverlay(
      this.state.product_id + "/sets/" + this.state.setno + "/" + this.state.keyname
    );
  }

  _handleValidSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    let formData = formDataToJSON(new FormData(e.target));
    if (this.state.keyval == null) {
      let endpoint = ENDPOINTS["sets"]["create_key"];
      let url = endpoint.uri;
      let method = endpoint.method;
      this.props.createSetKey(url, method, formData);
    } else {
      let endpoint = ENDPOINTS["sets"]["update_key"];
      let url = endpoint.uri;
      let method = endpoint.method;
      this.props.updateSetKey(url, method, formData);
    }
  }

  onExpandToggle(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ expand: !this.state.expand });
  }

  render() {
    const { role, product_role } = this.state;
    const keyval = this.state;
    const isVisible = this.state.overlay.visible;
    return (
      <tr key={keyval.keyname}>
        <td>{keyval.keyname}</td>
        <td>{keyval.keyval}</td>
        <Authorized
          userRoles={[role, product_role]}
          requiredRoles={["admin", "owner"]}
          roleType="ANY"
        >
          <td>
            <a onClick={this.onEditClick} style={{ paddingLeft: "15px" }} title="Edit">
              <img src="/icons/edit.png" style={{ width: "18px" }} />
            </a>
            <Modal show={isVisible} onHide={this.hideAddSetOverlay}>
              <Form onSubmit={this._handleValidSubmit} className="override_form">
                <Modal.Header closeButton>
                  <Modal.Title>Add Key value</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <FieldGroup
                    id="formControlKeyName"
                    type="text"
                    label="Key:"
                    readonly="readonly"
                    name="keyname"
                    placeholder="Enter set no"
                    defaultValue={keyval.keyname}
                    required="required"
                  />
                  <FieldGroup
                    id="formControlKeyName"
                    type="hidden"
                    name="setno"
                    defaultValue={keyval.setno}
                    required="required"
                  />
                  <FieldGroup
                    id="formControlKeyName"
                    type="hidden"
                    name="product_id"
                    defaultValue={keyval.product_id}
                    required="required"
                  />
                  <FieldGroup
                    id="formControlKeyVal"
                    type="text"
                    label="Key Value:"
                    name="keyval"
                    defaultValue={keyval.keyval}
                    placeholder="Enter key value"
                    required="required"
                  />
                  {/* <FieldGroup
                      id="formControlKeyVal"
                      type="number"
                      label="Key Order"
                      name="keyorder"
                      defaultValue={keyval.keyorder}
                      placeholder="Enter key order"
                      required="required"
                    /> */}
                </Modal.Body>
                <Modal.Footer>
                  <Button type="submit">Submit</Button>
                  {/* <Button onClick={this.hideAddSetOverlay}>Cancel</Button> */}
                </Modal.Footer>
              </Form>
            </Modal>
          </td>
        </Authorized>
      </tr>
    );
  }
}
