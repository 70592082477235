import {connect} from 'react-redux'
import ProductSetKeyValList from '../../components/product_sets/ProductSetKeyValList'
import {bindActionCreators} from 'redux';
import { createSelector } from 'reselect'

const mapStateToProps = (state, ownProps) => {
  return Object.assign(ownProps);
};



const ProductSetKeyValListContainer = connect(mapStateToProps, null)(ProductSetKeyValList);

export default ProductSetKeyValListContainer