export const ENDPOINTS = {
  products: {
    list: { method: "GET", uri: "/api/products" },
    create: { method: "POST", uri: "/api/products" },
    update: { method: "PUT", uri: "/api/products/" },
    clone: { method: "POST", uri: "/api/products/clone" },
    view: { method: "GET", uri: "/api/products/view" },
    share: { method: "POST", uri: "/api/products/share" },
    add_user: { method: "POST", uri: "/api/products/add_user" },
    add_view_product_user: { method: "POST", uri: "/api/products/add_view_product_user" },
    get_view_product_user: { method: "GET", uri: "/api/products/get_view_product_user" },
    delete: { method: "DELETE", uri: "/api/products/:id" },
    presets: { method: "GET", uri: "/api/products/presets" },
  },
  datalevels: {
    list: { method: "GET", uri: "/api/products/:id/datalevels" },
    create: { method: "POST", uri: "/api/products/" },
    update: { method: "PUT", uri: "/api/products/" },
    delete: { method: "DELETE", uri: "/api/products/" },
  },
  attributes: {
    create: { method: "POST", uri: "/api/products/" },
    update: { method: "PUT", uri: "/api/products/" },
    delete: { method: "DELETE", uri: "/api/products/" },
  },
  keys: {
    list: { method: "GET", uri: "/api/products/:product_id/keys" },
    create: { method: "POST", uri: "/api/products/:product_id/keys/:dlname" },
    delete: { method: "DELETE", uri: "/api/products/:product_id/keys/:dlname" },
  },
  sets: {
    list: { method: "GET", uri: "/api/products/:product_id/sets" },
    placeholders: {
      method: "GET",
      uri: "/api/products/:product_id/sets/:setno/placeholders",
    },
    create: { method: "POST", uri: "/api/products/:product_id/sets" },
    delete: { method: "DELETE", uri: "/api/products/:product_id/sets/:setno" },
    update: { method: "PUT", uri: "/api/products/:product_id/sets/:setno" },
    create_key: {
      method: "POST",
      uri: "/api/products/:product_id/sets/:setno/keys",
    },
    update_key: {
      method: "PUT",
      uri: "/api/products/:product_id/sets/:setno/keys/:keyname",
    },
    create_dlval: {
      method: "POST",
      uri: "/api/products/:product_id/sets/:setno/datalevels",
    },
    update_dlval: {
      method: "PUT",
      uri: "/api/products/:product_id/sets/:setno/datalevels/:pageno/:dlname",
    },
    layout_create: {
      method: "POST",
      uri: "/api/products/:product_id/sets/:setno/layouts",
    },
    layout_update: {
      method: "POST",
      uri: "/api/products/:product_id/sets/:setno/layouts/:pageno",
    },
    layout_delete: {
      method: "DELETE",
      uri: "/api/products/:product_id/sets/:setno/layouts/:pageno",
    },
    layout_supported: {
      method: "GET",
      uri: "/api/products/:product_id/sets/:setno/layouts/:pageno/supported",
    },
    price: { method: "GET", uri: "/api/products/:product_id/sets/price" },
    counts: {
      create: {
        method: "POST",
        uri: "/api/products/:product_id/sets/:setno/counts",
      },
      update: {
        method: "PUT",
        uri: "/api/products/:product_id/sets/:setno/counts/:dlname",
      },
      delete: {
        method: "DELETE",
        uri: "/api/products/:product_id/sets/:setno/counts/:dlname",
      },
    },
  },
  layouts: {
    list: { method: "GET", uri: "/api/layouts" },
    create: { method: "POST", uri: "/api/layouts" },
    show: { method: "GET", uri: "/api/layouts/:id" },
    delete: { method: "DELETE", uri: "/api/layouts/:id" },
    update: { method: "PUT", uri: "/api/layouts/:id" },
    clone: { method: "POST", uri: "/api/layouts/:id/clone" },
  },
  layout_datalevels: {
    create: { method: "post", uri: "/api/layouts/:layout_id/datalevels" },
    delete: {
      method: "delete",
      uri: "/api/layouts/:layout_id/datalevels/:dlname",
    },
  },
  layout_datalevels_rel: {
    create: {
      method: "post",
      uri: "/api/layouts/:layout_id/datalevel_relations",
    },
    delete: {
      method: "delete",
      uri: "/api/layouts/:layout_id/datalevel_relations/:pdlname/:cdlname",
    },
  },
  layout_datalevels_keys: {
    create: {
      method: "post",
      uri: "/api/layouts/:layout_id/datalevel_relations/:pdlname/:cdlname/keys",
    },
    delete: {
      method: "delete",
      uri: "/api/layouts/:layout_id/datalevel_relations/:pdlname/:cdlname/keys/:key",
    },
  },
  layout_slotfors: {
    create: {
      method: "post",
      uri: "/api/layouts/:layout_id/slotfors/:slotforcode",
    },
    delete: {
      method: "delete",
      uri: "/api/layouts/:layout_id/slotfors/:slotforcode/:dlname",
    },
    delete_attribute: {
      method: "delete",
      uri: "/api/layouts/:layout_id/slotfors/:slotforcode/:dlname/:attributename",
    },
  },
  csv: {
    download: "/api/products/:product_id/datalevels/:dlname/data/download",
    upload: "/api/products/:product_id/datalevels/:dlname/data/upload",
    uploaded_data: "/api/products/:product_id/datalevels/:dlname/data/uploaded_data",
    list: {
      method: "GET",
      uri: "/api/products/:product_id/datalevels/:dlname/data",
    },

    update: {
      method: "PATCH",
      uri: "/api/products/:product_id/datalevels/:dlname/data/:data_upload_id",
    },
    create: {
      method: "POST",
      uri: "/api/products/:product_id/datalevels/:dlname/data",
    },
    delete: {
      method: "DELETE",
      uri: "/api/products/:product_id/datalevels/:dlname/data/:data_upload_id",
    },
  },
  audio: {
    upload: "/api/products/audio/:product_id/:filename",
  },
  images: {
    upload: "/api/products/:product_id/datalevels/:dlname/images/upload",
    generate: { method: "POST", uri: "/api/products/generate" },
  },
  orders: {
    products: { method: "GET", uri: "/api/orders/products" },
    create: { method: "POST", uri: "/api/orders" },
    capture: { method: "POST", uri: "/api/orders/capture" },
    show: { method: "GET", uri: "/api/orders/:order_id" },
    add_to_cart: { method: "POST", uri: "/api/orders/add_to_cart" },
    list: { method: "GET", uri: "/api/orders" },
  },
  categories: {
    index: { method: "GET", uri: "/api/products/categories" },
  },
  // Bala Add - final_files
  final_files: {
    index: { method: "GET", uri: "api/final_files" },
  },
  audio_files: {
    index: { method: "GET", uri: "api/audio_files" },
  },
  saveaudio_files: {
    index: { method: "POST", uri: "api/saveaudio_files" },
  },
  // Bala Add - mainDbRowCount
  maindbrowcountforaset: {
    index: { method: "GET", uri: "api/maindbrowcountforaset" },
  },
  // Bala Add - productkeyval
  productkeyval: {
    index: { method: "GET", uri: "api/productkeyval" },
  },
  // Bala Add - productsetcount
  productsetcount: {
    index: { method: "GET", uri: "api/productsetcount" },
  },
  files: {
    index: { method: "GET", uri: "api/files" },
    delete: { method: "DELETE", uri: "api/files/:file_path" },
    get: { method: "GET", uri: "api/files/size" },
  },
  user_storages: {
    create: { method: "POST", uri: "/api/user_storages" },
    capture: { method: "POST", uri: "/api/user_storages/capture" },
    show: { method: "GET", uri: "/api/user_storages/:user_storage_id" },
    update: { method: "PUT", uri: "/api/user_storages/:user_storage_id" },
    // add_to_cart: { method: "POST", uri: "/api/orders/add_to_cart" },
    list: { method: "GET", uri: "/api/user_storages" },
    update_defaults: { method: "PUT", uri: "/api/user_storages/update_defaults" },
    get_defaults: { method: "GET", uri: "/api/user_storages/get_defaults" },
  },
};
