export const GET_PRODUCT_SETSAAAA = "GET_PRODUCT_SETSAAAA";
export const GET_PRODUCT_SETS = "GET_PRODUCT_SETS";
export const GET_PRODUCT_SET_PLACEHOLDERS = "GET_PRODUCT_SET_PLACEHOLDERS";
export const DELETE_PRODUCT_SET = "DELETE_PRODUCT_SET";
export const CREATE_PRODUCT_SET = "CREATE_PRODUCT_SET";
export const ADD_ALL_SETS = "ADD_ALL_SETS";
export const ADD_SET = "ADD_SET";
export const DELETE_SET = "DELETE_SET";
export const ADD_SET_KEYVALS = "ADD_SET_KEYVALS";
export const CREATE_PRODUCT_SET_KEY = "CREATE_PRODUCT_SET_KEY";
export const UPDATE_PRODUCT_SET_KEY = "UPDATE_PRODUCT_SET_KEY";

export const CREATE_PRODUCT_SET_DLVAL = "CREATE_PRODUCT_SET_DLVAL";
export const UPDATE_PRODUCT_SET_DLVAL = "UPDATE_PRODUCT_SET_DLVAL";
export const CREATE_PRODUCT_SET_LAYOUT = "CREATE_PRODUCT_SET_LAYOUT";
export const DELETE_PRODUCT_SET_LAYOUT = "DELETE_PRODUCT_SET_LAYOUT";
export const GET_SUPPORTED_PRODUCT_LAYOUTS = "GET_SUPPORTED_PRODUCT_LAYOUTS";
export const ADD_ALL_SUPPORTED_LAYOUTS = "ADD_ALL_SUPPORTED_LAYOUTS";

export const ADD_SET_OCCURENCE_COUNT = "ADD_SET_OCCURENCE_COUNT";
export const ADD_SET_OCCURENCE_COUNT_SAGA = "ADD_SET_OCCURENCE_COUNT_SAGA";
export const UPDATE_SET_OCCURENCE_COUNT = "UPDATE_SET_OCCURENCE_COUNT";
export const UPDATE_SET_OCCURENCE_COUNT_SAGA = "UPDATE_SET_OCCURENCE_COUNT_SAGA";
export const UPDATE_PRODUCT_SET = "UPDATE_PRODUCT_SET";

export const getSets = (product_id) => {
  return { type: GET_PRODUCT_SETS, id: product_id, reducerKey: ADD_ALL_SETS };
};

export const createSet = (url, method, payload) => {
  return {
    type: CREATE_PRODUCT_SET,
    payload: payload,
    url: url,
    method: method,
  };
};

export const deleteSet = (url, method, payload) => {
  return {
    type: DELETE_PRODUCT_SET,
    payload: payload,
    url: url,
    method: method,
  };
};

export const updateSet = (id, url, method, payload) => {
  // return {type: UPDATE_PRODUCT, id: id, payload: payload};
  return {
    type: UPDATE_PRODUCT_SET,
    id: id,
    url: url,
    method: method,
    payload: payload,
  };
};

export const createSetKey = (url, method, payload) => {
  return {
    type: CREATE_PRODUCT_SET_KEY,
    payload: payload,
    url: url,
    method: method,
  };
};

export const updateSetKey = (url, method, payload) => {
  return {
    type: UPDATE_PRODUCT_SET_KEY,
    payload: payload,
    url: url,
    method: method,
  };
};

export const createSetDLVal = (url, method, payload) => {
  return {
    type: CREATE_PRODUCT_SET_DLVAL,
    payload: payload,
    url: url,
    method: method,
  };
};

export const updateSetDLVal = (url, method, payload) => {
  return {
    type: UPDATE_PRODUCT_SET_DLVAL,
    payload: payload,
    url: url,
    method: method,
  };
};

export const getSetPlaceholders = (product_id, setno) => {
  return {
    type: GET_PRODUCT_SET_PLACEHOLDERS,
    id: product_id,
    setno: setno,
    reducerKey: ADD_SET_KEYVALS,
  };
};

export const createSetLayout = (url, method, payload) => {
  return {
    type: CREATE_PRODUCT_SET_LAYOUT,
    payload: payload,
    url: url,
    method: method,
  };
};

export const deleteSetLayout = (url, method, payload) => {
  return {
    type: DELETE_PRODUCT_SET_LAYOUT,
    payload: payload,
    url: url,
    method: method,
  };
};

export const getSupportedLayouts = (product_id, payload = {}) => {
  return {
    type: GET_SUPPORTED_PRODUCT_LAYOUTS,
    id: product_id,
    reducerKey: ADD_ALL_SUPPORTED_LAYOUTS,
    payload: Object.assign({}, payload, { product_id: product_id }),
  };
};

export const createSetOccurenceCount = (url, method, payload) => {
  return {
    type: ADD_SET_OCCURENCE_COUNT,
    payload: payload,
    url: url,
    method: method,
  };
};

export const updateSetOccurenceCount = (url, method, payload) => {
  return {
    type: UPDATE_SET_OCCURENCE_COUNT,
    payload: payload,
    url: url,
    method: method,
  };
};
