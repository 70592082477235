import React, { useState } from 'react';
import { useReducer } from "react";
import CartContext from "./CartContext";
import CartReducer from "./CartReducer";


const CartState = ({ children }) => {
  //   Initial State of the cart
  const initialState = {
    cartItems: {quantity:null},
  };

  //Set up the reducer
  const [state, dispatch] = useReducer(CartReducer, initialState);

   //Function to set the value of quantity
   const setqty = (payload) => {
    dispatch({ type: "SETQTY", payload});
  };
  
  //Function to handle when an item that is in the cart is added again
  const increase = () => {
    dispatch({ type: "INCREASE"});
  };

  //Function to handle when an item is removed from the cart
  const decrease = () => {
    dispatch({ type: "DECREASE"});
  };

  

  return (
    //Add the functions that have been defined above into the Context provider, and pass on to the children
    <CartContext.Provider
      value={{
        cartItems: state.cartItems,
        setqty,
        increase,
        decrease,
        ...state,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

function getCookie (cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export default CartState;
