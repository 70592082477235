import React from "react";
import { ENDPOINTS } from "../../../constants/endpoints";
import { authorized, Authorized } from "../../../adukku_utils/authorizeUser";

export default class LySlotFor extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
    this.onDelete = this.onDelete.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state !== nextProps) {
      this.setState(nextProps);
    }
  }

  onDelete(e, slotfor) {
    if (confirm("Are you sure, you want to delete?")) {
      e.preventDefault();
      e.stopPropagation();
      const dlname = this.state.dlname;
      const layout = this.state.layout;
      const product = this.state.product;

      let endpoint = Object.assign({}, ENDPOINTS.layout_slotfors.delete, {});
      if (slotfor) {
        endpoint = Object.assign(
          {},
          ENDPOINTS.layout_slotfors.delete_attribute,
          {}
        );
        endpoint.uri = endpoint.uri.replace(
          ":attributename",
          slotfor.attributename
        );
      }
      const slotforcode = this.state.slotforcode;
      endpoint.uri = endpoint.uri.replace(":layout_id", layout.id);
      endpoint.uri = endpoint.uri.replace(":dlname", dlname);
      endpoint.uri = endpoint.uri.replace(":slotforcode", slotforcode);
      this.props.deleteLyDLSlotFor(endpoint.uri, endpoint.method, {
        product_id: product.id,
        layout_id: layout.id,
      });
      // if (slotforcode == "MA") {
      //   endpoint.uri = endpoint.uri.replace("MA", "MM");
      //   this.props.deleteLyDLSlotFor(endpoint.uri, endpoint.method, {
      //     product_id: product.id,
      //     layout_id: layout.id
      //   });
      // }
    }
  }

  render() {
    const slotFors = this.state.slotFors;
    const layout = this.state.layout;
    const product = this.state.product;
    const dlname = this.state.dlname;
    const postfix = this.state.slotforcode == "MA" ? "(main)" : "";
    const role = this.state.role;
    return (
      <li>
        {dlname + " " + postfix}
        <Authorized
          userRoles={[role, product.product_role]}
          requiredRoles={["admin", "owner"]}
          roleType="ANY"
        >
          <a
            onClick={(e) => this.onDelete(e)}
            title="Delete"
            style={{ paddingLeft: "15px" }}
          >
            <img src="/icons/delete.png" style={{ width: "17px" }} />
          </a>
        </Authorized>
        <ul>
          {slotFors.map((slotfor) => {
            return (
              <li>
                {slotfor.attributename}
                <Authorized
                  userRoles={[role, product.product_role]}
                  requiredRoles={["admin", "owner"]}
                  roleType="ANY"
                >
                  <a
                    onClick={(e) => this.onDelete(e, slotfor)}
                    style={{ paddingLeft: "15px" }}
                    title="Delete"
                  >
                    <img src="/icons/delete.png" style={{ width: "17px" }} />
                  </a>
                </Authorized>
              </li>
            );
          })}
        </ul>
      </li>
    );
  }
}
