import React, { Component } from "react";
import OrderContainer from "../../containers/orders/OrderContainer";

class OrderList extends Component {
  state = {
    expandFlag: false,
  };
  UNSAFE_componentWillReceiveProps(nextProps) {}

  componentDidMount() {
    this.props.getProducts();
    this.props.getOrders({ status: "pending" });
  }

  getExpandJSX = (expandFlag) => {
    return (
      <span>
        {expandFlag && <i className="icon-collapse" aria-hidden="true"></i>}
        {!expandFlag && <i className="icon-expand" aria-hidden="true"></i>}
      </span>
    );
  };

  onExpandToggle = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ expandFlag: !this.state.expandFlag });
    // this.props.getSets(this.props.product.id);
  };

  render() {
    const products = this.props.products;
    const { expandFlag } = this.state;
    const productList = products.length ? (
      products.map((product) => {
        return (
          <React.Fragment>
            <li>
              <a className="no-textcolor" onClick={this.onExpandToggle}>
                {this.getExpandJSX(expandFlag)} {product.name}
              </a>
            </li>
            {expandFlag && (
              <ul>
                <OrderContainer product_id={product.id} key={product.id} isShowDownloads={true} />
              </ul>
            )}
          </React.Fragment>
          // <React.Fragment>
          //   <li>
          //     <a className="no-textcolor" onClick={this.onExpandToggle}>
          //       {this.getExpandJSX(expand)} {this.state.treeName || "View Final Production Files"}
          //     </a>
          //   </li>
          //   {this.state.expand && <ul>{productSets}</ul>}
          // </React.Fragment>
        );
      })
    ) : (
      <div className="no-orders"> No Orders</div>
    );

    return (
      <div>
        {/*<h3 className="list-heading">My Orders</h3>*/}
        <h3 className="list-heading">View/Pay Products</h3>
        <React.Fragment>
          <ul className="tree">{productList}</ul>
        </React.Fragment>
      </div>
    );
  }
}

export default OrderList;
