import { connect } from 'react-redux'
import AddLyDlRelKey from '../../../components/layouts/lydlrel/AddLyDlRelKey';
import {bindActionCreators} from 'redux';
import unique from 'array-unique';
import { createLyDLRelKey } from '../../../actions/layouts';

const mapStateToProps = (state, ownProps) => {
  const product = state.products.filter(p => p.id == ownProps.product_id)[0];
  const layout = state.layouts.filter(p => p.id == ownProps.layout_id)[0];
  let dlRel = layout.datalevel_relations.filter(rel => rel.id == ownProps.dlrel_id)[0];
  // Common attributes
  let attributes = [];
  let pdl = state.product_datalevels.filter(dl => dl.product_id == ownProps.product_id && dl.dlname == dlRel.pdlname)[0];
  let cdl = state.product_datalevels.filter(dl => dl.product_id == ownProps.product_id && dl.dlname == dlRel.cdlname)[0];
  attributes = attributes.concat(pdl.attributes).concat(cdl.attributes);
  attributes = attributes.reduce((attributeResult, attribute) => {
    attributeResult.push(attribute.name);
    return attributeResult;
  }, []);
  attributes = attributes.reduce(function(acc, el, i, arr) {
    if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) acc.push(el); return acc;
  }, []);

  let product_keys = ((state.product_keys[ownProps.product_id] || {}) || []);
  product_keys = [].concat(...Object.values(product_keys));
  attributes = unique(attributes);
  let product_key_attrs = product_keys.map(k => k.attribute);

  if(dlRel.style == 'P')
    attributes = attributes.filter(attr => product_key_attrs.indexOf(attr) != -1)

  return Object.assign({}, ownProps, {layout: layout, product: product, dlRel: dlRel, attributes: unique(attributes) });
};

const mapDispatchToProps = (dispatch) =>bindActionCreators({
  createLyDLRelKey
},dispatch);

const AddLyDlRelKeyContainer = connect(mapStateToProps, mapDispatchToProps)(AddLyDlRelKey);

export default AddLyDlRelKeyContainer;