import { connect } from "react-redux";
import AddLyDlRel from "../../../components/layouts/lydlrel/AddLyDlRel";
import { createLyDLRel } from "../../../actions/layouts";

import { bindActionCreators } from "redux";
const mapStateToProps = (state, ownProps) => {
  const product = state.products.filter((p) => p.id == ownProps.product_id)[0];
  const layout = state.layouts.filter((p) => p.id == ownProps.layout_id)[0];
  let product_datalevels = state.product_datalevels.filter(
    (dl) => dl.product_id == ownProps.product_id
  );
  let product_keys = state.product_keys[ownProps.product_id] || {} || [];
  product_keys = [].concat(...Object.values(product_keys));
  let product_key_attrs = product_keys.map((k) => k.attribute);
  let layout_rel_dls = layout.datalevel_relations.filter((rel) => rel.style == "P");
  let left_dls = product_datalevels.filter((dl) => {
    let filteredList = dl.attributes.filter((attr) => {
      return product_key_attrs.indexOf(attr.name) != -1;
    });
    return filteredList.length == product_keys.length;
  });
  let right_dls = [];
  if (ownProps.type == "product_key") {
    right_dls = left_dls;
  } else {
    left_dls = left_dls.filter((dl) => {
      return layout_rel_dls.map((rel) => rel.pdlname).indexOf(dl.dlname) != -1;
    });
    right_dls = product_datalevels.filter((dl) => {
      let filteredList = dl.attributes.filter((attr) => {
        return product_key_attrs.indexOf(attr.name) != -1;
      });
      return filteredList.length != product_keys.length;
    });
  }
  if (ownProps.type != "product_key") {
    left_dls = left_dls.filter((dl) => {
      let currentAttr = dl.attributes;
      let isCommonAttr = false;
      right_dls.forEach((rDl) => {
        let currentRightAttr = rDl.attributes;
        let result = currentAttr.filter((o) => currentRightAttr.some((m) => o.name === m.name));
        if (result.length > 0) {
          isCommonAttr = true;
        }
      });
      if (isCommonAttr) {
        return dl;
      }
    });
  }
  return Object.assign({}, ownProps, {
    layout: layout,
    product: product,
    product_datalevels: product_datalevels,
    left_dls: left_dls,
    right_dls: right_dls,
  });
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createLyDLRel,
    },
    dispatch
  );

const AddLyDlRelContainer = connect(mapStateToProps, mapDispatchToProps)(AddLyDlRel);

export default AddLyDlRelContainer;
