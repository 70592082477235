import { connect } from "react-redux";
import ProductSetCountList from "../../components/product_sets/ProductSetCountList";
import { bindActionCreators } from "redux";
import { createSelector } from "reselect";
import {
  toggleOverlay,
  hideOverlay,
  showOverlay
} from "../../actions/misc_actions";

const mapStateToProps = (state, ownProps) => {
  return Object.assign({}, ownProps, { overlay: state.overlays });
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      hideOverlay,
      showOverlay
    },
    dispatch
  );

const ProductSetCountListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductSetCountList);

export default ProductSetCountListContainer;
