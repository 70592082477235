import { connect } from 'react-redux'
import EditLyDlRel from '../../../components/layouts/lydlrel/EditLyDlRel';

import {bindActionCreators} from 'redux';
const mapStateToProps = (state, ownProps) => {
  const product = state.products.filter(p => p.id == ownProps.product_id)[0];
  const layout = state.layouts.filter(p => p.id == ownProps.layout_id)[0];
  let dlRel = layout.datalevel_relations.filter(dlrel => dlrel.id == ownProps.dlrel_id)[0] || {};
  let product_datalevels = state.product_datalevels.filter(dl => dl.product_id == ownProps.product_id);
  return Object.assign({}, ownProps, {layout: layout, dlRel: dlRel, product: product, product_datalevels: product_datalevels });
};

const mapDispatchToProps = (dispatch) =>bindActionCreators({
},dispatch);

const EditLyDlRelContainer = connect(mapStateToProps, mapDispatchToProps)(EditLyDlRel);

export default EditLyDlRelContainer;