import React from "react";
import LyDlListContainer from "../../containers/layouts/lydl/LyDlListContainer";
import LyDlRelListContainer from "../../containers/layouts/lydlrel/LyDlRelListContainer";
import LySlotForListContainer from "../../containers/layouts/lyslotfors/LySlotForListContainer";
import AddLySlotForContainer from "../../containers/layouts/lyslotfors/AddLySlotForContainer";
import { ENDPOINTS } from "../../constants/endpoints";

import { formDataToJSON } from "../../adukku_utils/array";
import ThumbnailPreview from "./ThumbnailPreview";

import {
  Row,
  Col,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Modal,
  Fade,
  utils,
  Button,
} from "react-bootstrap";
import { FieldGroup } from "../bootstrap/Forms.FieldGroup";
import { authorized, Authorized } from "../../adukku_utils/authorizeUser";

export default class Layout extends React.Component {
  constructor(props) {
    super(props);
    const layout = (props && props.layout) || {};
    this.state = {
      ...props,
      isCopyLayoutClicked: false,
      description: "",
      desc: layout.desc,
      is_preset_bg: layout.is_preset_bg,
      is_preset_sticker: layout.is_preset_sticker,
      height: layout.height,
      width: layout.width,
      isSlotForsExpanded: false,
      show: false,
      isEditLayoutClicked: false,
      layoutCategories: [],
      other_category: "",
      sticker_category: layout.sticker_category,
    };
    this.toggleExpand = this.toggleExpand.bind(this);
  }

  toggleExpand(e) {
    e.stopPropagation();
    this.props.getLayoutDetails(this.state.layout.id);
    this.setState(Object.assign({}, this.state, { expand: !this.state.expand }));
  }

  componentDidMount() {
    let expandedProduct = localStorage.getItem("expandedProduct");
    expandedProduct = expandedProduct ? JSON.parse(expandedProduct) : {};

    if (
      expandedProduct.layoutId &&
      this.state.layout.id == expandedProduct.layoutId &&
      !expandedProduct.isSet
    ) {
      this.setState({
        expand: true,
      });
      this.props.getLayoutDetails(this.state.layout.id);
      localStorage.setItem("expandedProduct", JSON.stringify({}));
    }
    let that = this;
    fetch("/api/layouts/categories", {
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        let layoutCategories =
          (response &&
            response.categories &&
            response.categories.map((category) => {
              return {
                label: category,
                value: category,
              };
            })) ||
          [];
        that.setState({ layoutCategories: layoutCategories || [] });
      });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state !== nextProps) {
      this.setState(nextProps);
    }
  }

  _handleDeletelayout(e, layout_id) {
    e.preventDefault();
    e.stopPropagation();
    if (confirm("Are you sure you want to delete the layout along with data?")) {
      let payload = { id: layout_id, product_id: this.state.product.id };
      let endpoint = ENDPOINTS["layouts"]["delete"];
      let url = endpoint.uri;
      let method = endpoint.method;
      this.props.deleteLayout(url, method, payload);
    } else {
      return;
    }
  }

  _handleClonelayout(e, layout_id) {
    e.preventDefault();
    e.stopPropagation();
    if (this.state.isCopyLayoutClicked) {
      if (!this.state.description) return alert("Please enter layout description");
      let payload = {
        id: layout_id,
        product_id: this.state.product.id,
        desc: this.state.description,
      };
      let endpoint = ENDPOINTS["layouts"]["clone"];
      let url = endpoint.uri;
      let method = endpoint.method;
      this.props.cloneLayout(url, method, payload);
      this.setState({
        isCopyLayoutClicked: false,
      });
    } else {
      this.setState({
        isCopyLayoutClicked: true,
      });
    }
  }

  handleCancelLayout = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      isCopyLayoutClicked: false,
    });
  };

  getExpandJSX = (expandFlag) => {
    return (
      <span>
        {expandFlag && <i className="icon-collapse" aria-hidden="true"></i>}
        {!expandFlag && <i className="icon-expand" aria-hidden="true"></i>}
      </span>
    );
  };

  getEditLayoutJSX = (isVisible) => {
    const layout = this.state.layout;
    const { desc, height, width, is_preset_bg, is_preset_sticker } = this.state;
    const layoutCategories = this.state.layoutCategories || [];

    if (isVisible) {
      return (
        <Modal
          show={this.state.isEditLayoutClicked}
          onHide={(e) => {
            this.setState({
              isEditLayoutClicked: false,
            });
          }}
        >
          <Form className="override_form" onSubmit={this.handleEditLayout}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Layout</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FieldGroup
                id="formControlName"
                type="text"
                label="Description:"
                name="desc"
                value={desc}
                onChange={({ target: { name, value } }) => {
                  this.setState({ [name]: value });
                }}
                // disabled={true}
                placeholder="Product Name"
                required="required"
              />
      {/*        <FieldGroup
                id="formControlPages"
                type="number"
                label="Height:"
                name="height"
                value={height}
                onChange={({ target: { name, value } }) => {
                  this.setState({ [name]: value });
                }}
                // disabled={true}
                placeholder="Height"
                required="required"
              />
              <FieldGroup
                id="formControlPages"
                type="number"
                label="Width:"
                name="width"
                value={width}
                onChange={({ target: { name, value } }) => {
                  this.setState({ [name]: value });
                }}
                // disabled={true}
                placeholder="Width"
                required="required"
              /> */}
              {this.state.role === "admin" && (
                <React.Fragment>
                  <FieldGroup
                    id="formControlIsPresetBg"
                    type="checkbox"
                    label="Save as Background:"
                    checked={is_preset_bg}
                    name="is_preset_bg"
                    placeholder="Save as Background"
                    className="override_checkbox"
                    onChange={({ target: { name, checked } }) => {
                      this.setState({ [name]: checked });
                    }}
                  />
                  <FieldGroup
                    id="formControlIsPresetSticker"
                    type="checkbox"
                    label="Save as Sticker:"
                    checked={is_preset_sticker}
                    name="is_preset_sticker"
                    placeholder="Save as Sticker"
                    className="override_checkbox"
                    onChange={({ target: { name, checked } }) => {
                      this.setState({ [name]: checked });
                    }}
                  />

                  <FieldGroup
                    componentClass="select"
                    id="formControlPreset"
                    label="Select Sticker Category:"
                    name="sticker_category"
                    placeholder="Select Sticker Category"
                    onChange={({ target: { name, value } }) => {
                      this.setState({ [name]: value });
                    }}
                    value={this.state.sticker_category}
                  >
                    <option key="None">{null}</option>
                    {layoutCategories.map((category) => {
                      return (
                        <option key={category.value} value={category.value}>
                          {category.label}
                        </option>
                      );
                    })}
                  </FieldGroup>
                  {this.state.sticker_category == "other" && (
                    <FieldGroup
                      id="formControlIsPresetSticker"
                      type="text"
                      label="Sticker Category:"
                      name="other_category"
                      placeholder="Eg: Background"
                      onChange={({ target: { name, value } }) => {
                        this.setState({ [name]: value });
                      }}
                      value={this.state.other_category}
                    />
                  )}
                </React.Fragment>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button type="submit">Submit</Button>
              {/* <Button
                onClick={(e) => {
                  this.setState({
                    isEditLayoutClicked: false,
                  });
                }}
              >
                Cancel
              </Button> */}
            </Modal.Footer>
          </Form>
        </Modal>
      );
    }
  };

  handleEditLayout = (e) => {
    // this.se
    e.preventDefault();
    e.stopPropagation();
    const { layout, product } = this.state;
    let endpoint = ENDPOINTS.layouts.update;
    let url = endpoint.uri;
    let method = endpoint.method;

    let formData = formDataToJSON(new FormData(e.target));
    formData.is_preset_bg = this.state.is_preset_bg;
    formData.is_preset_sticker = this.state.is_preset_sticker;
    formData.sticker_category =
      this.state.sticker_category == "other"
        ? this.state.other_category
        : this.state.sticker_category;
    formData.product_id = product.id;
    this.props.updateLayout(layout.id, url, method, formData);
    this.setState({
      isEditLayoutClicked: false,
    });
  };

  render() {
    const layout = this.state.layout;
    const product = this.state.product;
    const isExpand = this.state.expand;
    const role = this.state.role;
    let children = [];

    const cloneLayoutJSX = (
      <form
        className="form-inline override_form form_flex"
        onSubmit={(e) => this._handleClonelayout(e, layout.id)}
      >
        <div className="form-group">
          <input
            type="text"
            name="description"
            onChange={({ target: { name, value } }) => {
              this.setState({ description: value });
            }}
            className="form-control dlname"
            placeholder="Enter Layout Description"
            required="required"
            defaultValue={null}
          />
        </div>
        <button type="submit" className="btn btn-default">
          Save
        </button>
        <a
          className="btn btn-default btn-danger"
          onClick={(e) => this.handleCancelLayout(e, layout.id)}
        >
          Cancel
        </a>
      </form>
    );
    if (isExpand) {
      children.push(
        <LyDlListContainer layout_id={layout.id} product_id={product.id} role={role} />
      );
      children.push(
        <LyDlRelListContainer
          layout_id={layout.id}
          product_id={product.id}
          type="product_key"
          role={role}
        />
      );
      children.push(
        <LyDlRelListContainer layout_id={layout.id} product_id={product.id} role={role} />
      );
      children.push(
        <ul>
          <li>
            <a className="no-textcolor"
              onClick={(e) => {
                e.stopPropagation();
                this.setState({
                  isSlotForsExpanded: !this.state.isSlotForsExpanded,
                });
              }}
            >
              {this.getExpandJSX(this.state.isSlotForsExpanded)} Layout Slot For's
            </a>
            {this.state.isSlotForsExpanded && (
              <ul>
                {["MA", "OT"].map((slotforcode) => {
                  return (                    
                    <LySlotForListContainer layout_id={layout.id} product_id={product.id} slotforcode={slotforcode} role={role} />
                  );
                })}
                <li>
                  <AddLySlotForContainer layout_id={layout.id} product_id={product.id} role={role} />
                </li>
              </ul> 
            )}
          </li>
        </ul>
      );
    }
    return (
      <li>
        <a className="no-textcolor" onClick={this.toggleExpand}>
          {this.getExpandJSX(isExpand)} {layout.desc}
        </a>
        <Authorized
          userRoles={[role, product.product_role]}
          requiredRoles={["admin", "owner"]}
          roleType="ANY"
        >
          <a
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              let expandedProduct = {
                productId: product.id,
                layoutId: layout.id,
              };
              localStorage.setItem("expandedProduct", JSON.stringify(expandedProduct));
              window.location = "/products/" + product.id + "/layouts/" + layout.id;
            }}
            // href={"/products/" + product.id + "/layouts/" + layout.id}
            style={{ paddingLeft: "15px" }}
            title="Edit Layout"
          >
            <img src="/icons/edit.png" style={{ width: "18px" }} />
          </a>

          {!this.state.isCopyLayoutClicked && (
            <a
              onClick={(e) => {
                this._handleClonelayout(e, layout.id);
              }}
              style={{ paddingLeft: "15px" }}
              title="Copy Layout"
              // confirm="Are you sure you want to delete the layout?"
            >
              <img src="/icons/copy.png" style={{ width: "17px" }} />
            </a>
          )}
          <a
            onClick={(e) => {
              this._handleDeletelayout(e, layout.id);
            }}
            style={{ paddingLeft: "15px" }}
            title="Delete"
            // confirm="Are you sure you want to delete the layout?"
          >
            <img src="/icons/delete.png" style={{ width: "17px" }} />
          </a>
          {!this.state.isEditLayoutClicked && (
            // <Authorized
            //   userRoles={[role, product.product_role]}
            //   requiredRoles={["admin"]}
            //   roleType="ANY"
            // >
            // </Authorized>
            <a
              style={{ paddingLeft: "15px" }}
              title="Edit Layout Name"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                this.setState({
                  isEditLayoutClicked: !this.state.isEditLayoutClicked,
                });
              }}
            >
              <img src="/icons/edit.png" style={{ width: "18px" }} />
            </a>
          )}

          {this.state.isEditLayoutClicked && this.getEditLayoutJSX(this.state.isEditLayoutClicked)}
        </Authorized>
        {this.state.isCopyLayoutClicked && cloneLayoutJSX}
        {layout.preview_url && (
          <div>
            <img
              onClick={() => this.setState({ show: true })}
              style={{
                height: "60px",
                boxShadow: "5px 5px 10px rgba(0,0,0,.5)",
                marginBottom: "15px",
                margin: "10px 0 15px 35px",
                cursor: "pointer",
              }}
              src={`${layout.preview_url}?${new Date()}`}
            />
            {this.state.show && (
              <ThumbnailPreview
                layoutDetails={layout}
                imageSrc={`${layout.preview_url}?${new Date()}`}
                onClose={() => this.setState({ show: false })}
              />
            )}
          </div>
        )}
        {children}
      </li>
    );
  }
}
