import { connect } from 'react-redux'
import {bindActionCreators} from 'redux';
import AddLayout from '../../components/layouts/AddLayout';
import { createLayout } from '../../actions/layouts';
import {
 hideOverlay
 ,showOverlay
} from '../../actions/misc_actions'

const mapStateToProps = (state, ownProps) => {
  let overlay_id = ownProps.product_id+"/layouts";
  return Object.assign({}, ownProps, {overlay_id: overlay_id, overlay: (state.overlays[overlay_id] || { visible: false }) });
};

const mapDispatchToProps = (dispatch) =>bindActionCreators({
  createLayout,
  hideOverlay,
  showOverlay
}, dispatch);

const AddLayoutContainer = connect(mapStateToProps, mapDispatchToProps)(AddLayout);

export default AddLayoutContainer;