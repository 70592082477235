import React from "react";

import ProductKeyContainer from "../../containers/product_keys/ProductKeyContainer";
import EditProductKeyContainer from "../../containers/product_keys/EditProductKeyContainer";
import { authorized, Authorized } from "../../adukku_utils/authorizeUser";

export default class ProductKeyList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      product: props.product,
      product_keys: props.product_keys,
      overlay: props.overlay,
      role: props.role,
      product_role: props.product_role,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps !== this.state) {
      this.setState(nextProps);
    }
  }

  UNSAFE_componentWillMount() {
    this.props.getKeys(this.state.product.id);
  }

  render() {
    const { role, product_role } = this.state;
    const product_keys = this.state.product_keys;
    if (product_keys == null) {
      return null;
    }
    const key_cnt = Object.keys(product_keys).length;
    const labelText = key_cnt > 0 ? "Modify product key" : "Add new product key";
    return (
      <ul className="tree">
        {Object.keys(product_keys).map((k, i) => {
          let product_key = product_keys[k];
          return (
            <ProductKeyContainer
              key={k}
              product_id={this.state.product.id}
              dlname={k}
              attributes={product_key}
            />
          );
        })}
        <Authorized
          userRoles={[role, product_role]}
          requiredRoles={["admin", "owner"]}
          roleType="ANY"
        >
          <EditProductKeyContainer
            key={this.state.product.id + "/keys/create"}
            product_id={this.state.product.id}
            labelText={labelText}
          />
        </Authorized>
      </ul>
    );
  }
}
