import { connect } from "react-redux";
import ViewProductSet from "../../components/view_products/ViewProductSet";
import { bindActionCreators } from "redux";

const mapStateToProps = (state, ownProps) => {
  let product_set = ownProps.product.product_sets.filter(
    p => p.setno === ownProps.setno
  )[0];
  return Object.assign(
    {},
    {},
    {
      product: ownProps.product,
      setno: ownProps.setno,
      product_set: product_set,
      order: ownProps.order,
      layouts: ownProps.layouts
    }
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
    },
    dispatch
  );

const ViewProductSetContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewProductSet);

export default ViewProductSetContainer;
