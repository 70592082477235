import React from "react";
import PropTypes from "prop-types";
import { authorized, Authorized } from "../../adukku_utils/authorizeUser";
import { ENDPOINTS } from "../../constants/endpoints";

export default class ProductKey extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
    this.onDeleteDatalevel = this.onDeleteDatalevel.bind(this);
    this.onExpandToggle = this.onExpandToggle.bind(this);
    this.onClickAddNewAttr = this.onClickAddNewAttr.bind(this);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state !== nextProps) {
      this.setState(nextProps);
    }
  }
  onExpandToggle(e) {
    e.preventDefault();
    e.stopPropagation();
  }
  onDeleteAttribute(attribute, e) {
    e.preventDefault();
    e.stopPropagation();
    const product_id = this.state.product_id;
    const dlname = this.state.product_datalevel.dlname;
    const payload = {
      product_id: product_id,
      dlname: dlname,
      attribute: attribute
    };
    const endpoint = ENDPOINTS["keys"]["delete"];
    const url = endpoint.uri;
    const method = endpoint.method;
    this.props.deleteKey(url, method, payload);
  }
  onDeleteDatalevel(e) {
    e.preventDefault();
    e.stopPropagation();
    const product_id = this.state.product_id;
    const dlname = this.state.product_datalevel.dlname;
    const attributes = this.state.attributes;
    const endpoint = ENDPOINTS["keys"]["delete"];
    const url = endpoint.uri;
    const method = endpoint.method;
    let payload = { product_id: product_id, dlname: dlname };
    this.props.deleteKey(url, method, payload);
  }

  onClickAddNewAttr(e) {
    e.preventDefault();
    e.stopPropagation();
    let datalevel = this.props.product_datalevel;
    this.props.showOverlay(this.state.product.id);
    this.props.selectDatalevel(this.state.product_id, datalevel);
  }

  render() {
    if (
      this.state.product_datalevel == null ||
      this.state.product_datalevel.attributes == null
    ) {
      return null;
    }
    const { role, product_role } = this.state;
    const dlname = this.state.product_datalevel.dlname;
    const product_keys = this.state.product_keys;
    const anchorDLDeleteStyle = {
      paddingLeft: "20px"
    };
    return (
      <li key={dlname}>
        <a onClick={this.onExpandToggle} className="no-textcolor">
          {dlname}(Data Level)
        </a>
        <Authorized
          userRoles={[role, product_role]}
          requiredRoles={["admin", "owner"]}
          roleType="ANY"
        >
          <a style={anchorDLDeleteStyle} onClick={this.onDeleteDatalevel}>
            Delete
          </a>
        </Authorized>
        <ul>
          <li>
            <table className="react-table">
              <thead>
                <tr>
                  <th>Key</th>
                  <th>Order</th>
                </tr>
              </thead>
              <tbody>
                {product_keys.map(key => (
                  <tr key={key.attribute}>
                    <td>{key.attribute}</td>
                    <td>{key.order}</td>
                    <td>
                      <Authorized
                        userRoles={[role, product_role]}
                        requiredRoles={["admin", "owner"]}
                        roleType="ANY"
                      >
                        <a
                          onClick={this.onDeleteAttribute.bind(
                            this,
                            key.attribute
                          )}
                        >
                          Delete
                        </a>
                      </Authorized>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </li>
        </ul>
      </li>
    );
  }
}
