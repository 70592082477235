import React, { Component } from "react";
import ViewProductSetContainer from "../../containers/view_products/ViewProductSetContainer";

class ViewProductSetList extends Component {

  render() {
    const product_sets = this.props.product_sets;
    const product = this.props.product;
    if (product_sets == null) {
      return null;
    }
    return (
      <ul className="tree">
        {Object.keys(product_sets).map((k, i) => {
          let product_set = product_sets[k];
          let props = { ...product_set };
          props.product = product;
          return (
            <ViewProductSetContainer
              product_id={product.id}
              key={i}
              {...props}
            />
          );
        })}
      </ul>
    );
  }
}

export default ViewProductSetList;
