import { connect } from "react-redux";
import { getSets } from "../../actions/product_sets";
import ProductSetList from "../../components/product_sets/ProductSetList";
import { bindActionCreators } from "redux";
import { getKeys } from "../../actions/product_keys";

const mapStateToProps = (state, ownProps) => {
  const product = state.products.filter(p => p.id == ownProps.product_id)[0];
  const product_role = product.product_role;
  const role = ownProps.role;
  const product_sets = state.product_sets[ownProps.product_id] || [];
  const overlays = state.overlays[ownProps.product_id + "_sets"] || {
    visible: false
  };
  return {
    product: product,
    product_sets: product_sets,
    overlay: overlays,
    product_role: product_role,
    role: role
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getSets,
      getKeys
    },
    dispatch
  );

const ProductSetListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductSetList);

export default ProductSetListContainer;
