import React, { Component } from "react";
import ReactDOM from "react-dom";

import { ENDPOINTS } from "../../constants/endpoints";

class CCavenuePayment extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      no_of_files: 0,
      amount: 0.0,
      ...props };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {}

     // All hard coded by Bala - Bear with him!! 
  
     gst = 0.18;
     files_amount = 0.0;
     audio_files_duration = 0.0;
     audio_files_amount =  0.0;
     gst_amount = 0.0;
     amount = 0.0;
  
  componentDidMount() {
    // uncomment below line to generate a image in the background
    // this.props.generateImage(this.state.product_id, this.state.setno);

    this.gst = 0.18;
    if (this.props.prtype!=='NORMAL') {
      this.files_amount = this.props.no_of_files * 75 + this.props.no_of_files * 10;
      this.audio_files_duration = this.props.prtype!=='NORMAL' ? parseInt(this.props.prtype.split('-')[1].split('SEC')[0]) : 0;
      this.audio_files_amount =  this.audio_files_duration===15 ? this.props.no_of_files / this.props.numpages * 127 : this.props.no_of_files / this.props.numpages * 200;
      this.gst_amount= ( this.files_amount + this.audio_files_amount ) * this.gst;
      this.amount = Math.round(this.files_amount + this.audio_files_amount + this.gst_amount).toFixed(2);
      this.setState({amount: this.amount});
    }
    else {
      this.files_amount = this.props.no_of_files * 75 + this.props.no_of_files * 10;
      this.gst_amount= ( this.files_amount ) * this.gst;
      this.amount = Math.round(this.files_amount + this.gst_amount).toFixed(2);
      this.setState({amount: this.amount});
    }
  }

  
  render() {

    const style = {
       height: 25,
       width: 350,
       color: "green"
    };

    const style2 = {
      height: 32,
      width: 350,
      color: "white",
      fontSize: 15,
      background: "Green",
      padding: 4
   };
    
    return (
            
      <div style={style}>
        <form id="redirect" method="post" name="redirect" action="/filetransactions" >
        <input type="hidden" name="amount" value={this.state.amount}/>
        <input type="hidden" name="no_of_files" value={this.props.no_of_files}/>
        <input type="hidden" name="product_id" value={this.props.product_id}/>
        <input type="hidden" name="set_no" value={this.props.set_no}/>
        <input type="hidden" name="page_no" value={this.props.page_no}/>
        <button type="submit" style={style2} className="btn btn-success" >CCAvenue Checkout</button>
        </form>
      </div>
    );
  }
}

export default CCavenuePayment;