import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Layout from "../../components/orders/Layout";
import { getPrice } from "../../actions/orders";

const mapStateToProps = (state, ownProps) => {
  const product = state.products.filter((p) => p.id == ownProps.product_id)[0];
  if (product.product_sets) {
    const product_set = product.product_sets.filter(
      (p) => p.product_id === product.id && p.setno === ownProps.setno
    )[0];
    const set_layouts = product_set.layouts || [];
    const set_layout =
      set_layouts.filter((p) => p.pageno == ownProps.pageno)[0] || {};
    return Object.assign({}, ownProps, {
      product: product,
      layout: set_layout,
      product_set: product_set,
    });
  } else {
    return {
      ...state,
      ownProps,
    };
  }
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getPrice,
    },
    dispatch
  );

const LayoutContainer = connect(mapStateToProps, mapDispatchToProps)(Layout);

export default LayoutContainer;
