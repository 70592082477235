import { combineReducers } from "redux";
import {
  ADD_ALL_LAYOUTS,
  ADD_LAYOUT,
  DELETE_LAYOUT,
  ADD_LY_DATALEVELS,
  DELETE_LY_DATALEVEL
} from "../actions/layouts";

// action -> { product_id, payload}
const layouts = (state = [], action) => {
  let newState = [];
  let t = {};
  let newState2 = [];
  let map = new Map();
  switch (action.type) {
    case ADD_ALL_LAYOUTS:
      for (let i = 0; i < action.payload.length; i++) {
        action.payload[i]["product_id"] = action.id;
        action.payload[i]["datalevel_slotfors"] = [];
        action.payload[i]["datalevel_relations"] = [];
        action.payload[i]["datalevels"] = [];
      }
      // newState = [...state, ...action.payload];
      newState = [...action.payload];
      for (const item of newState) {
        if (!map.has(item.id)) {
          map.set(item.id, true);
          newState2.push(item);
        }
      }
      return newState2;
    case ADD_LAYOUT:
      newState = [...state];
      let layout = newState.filter(layout => layout.id == action.payload.id)[0];
      // let datalevel_slotfors = layout.datalevel_slotfors.reduce((slotforLayout, slotfor) => {
      //   slotforLayout[slotfor.slotforcode] ||= [];
      //   slotforLayout[slotfor.slotforcode].push(slotfor);
      // }, {});
      // delete layout.datalevel_slotfors;
      // layout.datalevel_slotfors = datalevel_slotfors;
      if (layout == null) {
        return state;
      } else {
        newState[newState.indexOf(layout)] = Object.assign({}, action.payload, {
          product_id: layout.product_id
        });
      }
      return newState;
    case DELETE_LAYOUT:
      return state;
    default:
      return state;
  }
};

export default layouts;
