import {connect} from 'react-redux'
import {getSets, createSet, deleteSet} from '../../actions/product_sets'
import {getProductDatalevels} from '../../actions'
import {
  toggleOverlay
 ,hideOverlay
 ,showOverlay
} from '../../actions/misc_actions'
import AddProductSet from '../../components/product_sets/AddProductSet'
import {bindActionCreators} from 'redux';

const mapStateToProps = (state, ownProps) => {
  let datalevels = state.product_datalevels;
  let product = state.products.filter(p => p.id == ownProps.product_id)[0];
  return Object.assign({}, {}, {product: product, overlay: ownProps.overlay, label: ownProps.labelText});
};
const mapDispatchToProps = (dispatch) =>bindActionCreators({
 createSet
 ,hideOverlay
 ,showOverlay
},dispatch);

const AddSetContainer = connect(mapStateToProps, mapDispatchToProps)(AddProductSet);

export default AddSetContainer;