import React from "react";
import PropTypes from "prop-types";
import { TOGGLE_EDIT_DATALEVEL_ATTRIBUTE } from "../actions";
import { formDataToJSON } from "../adukku_utils/array";
import { FormGroup, ControlLabel, FormControl, Button } from "react-bootstrap";
import { authorized, Authorized } from "../adukku_utils/authorizeUser";

class ProductDLAttr extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      attribute: props.attribute,
      isEdit: props.isEdit,
      role: props.role,
      product_role: props.product_role,
    };
    this.onDelete = this.onDelete.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  onDelete(e) {
    e.stopPropagation();
    e.preventDefault();
    if (confirm("Are you sure ?")) {
      this.props.deleteDataLevelAttribute(this.state.attribute.id);
    }
  }

  toggleEdit(e) {
    e.stopPropagation();
    e.preventDefault();
    this.props.deleteDataLevelAttribute(this.state.attribute.id, false);
    // this.setState(Object.assign({}, this.state, {isEdit: !this.state.isEdit}))
  }

  handleSubmit(e) {
    e.stopPropagation();
    e.preventDefault();
    let formData = formDataToJSON(new FormData(e.target));
    formData.name = formData.name.trim()
    let attribute = Object.assign({}, {}, this.state.attribute);
    let product_id = attribute.product_id;
    let dlname = attribute.dlname;
    attribute.name = formData.name;
    attribute.type = formData.type;
    let dlId = product_id + "/datalevels/" + dlname;
    this.props.createDataLevelAttribute(dlId, attribute); // : this.props.updateDataLevelAttribute(attribute)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state !== nextProps) {
      this.setState(nextProps);
    }
  }

  render() {
    const { role, product_role } = this.state;
    const attr = this.state.attribute;
    const isEdit = this.state.isEdit;
    if (attr == null) {
      return null;
    }
    const readOnly = (
      <tr>
        <td>{attr.name}</td>
        <td>{attr.type}</td>
        {/*<td><a onClick={this.toggleEdit}> Edit</a></td>*/}
        <Authorized
          userRoles={[role, product_role]}
          requiredRoles={["admin", "owner"]}
          roleType="ANY"
        >
          <td>
            <a onClick={this.onDelete} style={{ paddingLeft: "15px" }} title="Delete">
              <img src="/icons/delete.png" style={{ width: "17px" }} />
            </a>
          </td>
        </Authorized>
      </tr>
    );
    const editOnly = (
      <tr>
        <td colSpan="4">
          <form className="form-inline override_form" onSubmit={this.handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                pattern="[a-zA-Z0-9_\-]*"
                title="Only Alphabets,numbers,-, and _ allowed"                
                className="form-control margin-right"
                name="name"
                placeholder="Enter attribute name"
                required="required"
                defaultValue={null}
              />
            </div>
            <FormGroup controlId="formHorizontalDLAttr_1">
              <FormControl
                componentClass="select"
                placeholder="please select"
                required="required"
                defaultValue={null}
                name="type"
                className="margin-right"
              >
                {["", "IMAGE", "TEXT"].map((type, i) => {
                  return (
                    <option key={i} value={type}>
                      {type}
                    </option>
                  );
                })}
              </FormControl>
            </FormGroup>
            <button type="submit" className="btn btn-default">
              Save
            </button>
            <a onClick={this.toggleEdit} className="btn btn-default btn-danger">
              Cancel
            </a>
          </form>
        </td>
      </tr>
    );

    return (
      <table key={attr.id} className="react-table mb-3">
        <tbody>{isEdit ? editOnly : readOnly}</tbody>
      </table>
    );
  }
}

ProductDLAttr.propTypes = {
  attribute: PropTypes.shape({
    product_id: PropTypes.number.isRequired,
    dlname: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
};

export default ProductDLAttr;
