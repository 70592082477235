import { connect } from "react-redux";
import { getKeys, createKey, deleteKey } from "../../actions/product_keys";
import { getProductDatalevels } from "../../actions";
import {
  toggleOverlay,
  hideOverlay,
  showOverlay
} from "../../actions/misc_actions";
import EditProductKey from "../../components/product_keys/EditProductKey";
import { bindActionCreators } from "redux";
import { createSelector } from "reselect";

const dlSelector = createSelector(
  [state => state.id, state => state.datalevels],
  (filter, datalevels) => {
    return datalevels.filter(
      datalevel => datalevel.product_id == filter.product_id
    );
  }
);

const mapStateToProps = (state, ownProps) => {
  let datalevels = state.product_datalevels;
  let product = state.products.filter(p => p.id == ownProps.product_id)[0];
  let overlay = state.overlays[ownProps.product_id + "/keys"] || {
    visible: false
  };
  let datalevel =
    datalevels.filter(
      datalevel =>
        datalevel.product_id == ownProps.product_id &&
        datalevel.dltype == "main"
    )[0] || {};
  const product_keys =
    (state.product_keys[ownProps.product_id] || {})[datalevel.dlname] || [];
  let selectedKeys = {};
  let currentKeyOrder = 0;
  product_keys.map(key => {
    if (key.order > currentKeyOrder) currentKeyOrder = key.order;
    selectedKeys[key.attribute] = {
      checked: true,
      saved: true,
      order: key.order
    };
  });
  if (datalevel != null && datalevel.attributes != null) {
    datalevel.attributes.map(key => {
      if (selectedKeys[key.name] == null) {
        selectedKeys[key.name] = { checked: false, saved: false, order: 0 };
      }
    });
  }

  return Object.assign(
    {},
    {},
    {
      product: product,
      product_datalevel: datalevel,
      overlay: overlay,
      product_keys: product_keys,
      label: ownProps.labelText,
      selectedKeys: selectedKeys,
      currentKeyOrder: currentKeyOrder
    }
  );
};
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getProductDatalevels,
      getKeys,
      createKey,
      deleteKey,
      toggleOverlay,
      hideOverlay,
      showOverlay
    },
    dispatch
  );

const EditProductKeyContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditProductKey);

export default EditProductKeyContainer;
