import { connect } from 'react-redux'
import LyDlRel from '../../../components/layouts/lydlrel/LyDlRel';
import { deleteLyDLRel } from '../../../actions/layouts';

import {bindActionCreators} from 'redux';
const mapStateToProps = (state, ownProps) => {
  const product = state.products.filter(p => p.id == ownProps.product_id)[0];
  const layout = state.layouts.filter(p => p.id == ownProps.layout_id)[0];
  let dlRel = layout.datalevel_relations.filter(dlrel => dlrel.id == ownProps.dlrel_id)[0];
  return Object.assign({}, ownProps, {layout: layout, dlRel: dlRel, product: product });
};

const mapDispatchToProps = (dispatch) =>bindActionCreators({
  deleteLyDLRel
},dispatch);

const LyDlRelContainer = connect(mapStateToProps, mapDispatchToProps)(LyDlRel);

export default LyDlRelContainer;