import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createSelector } from "reselect";
import EditProductData from "../components/EditProductData";

import { getUploadedData, updateUploadedData, getProducts } from "../actions";

const mapStateToProps = (state, ownProps) => {
  const product = state.products.filter(p => p.id == ownProps.product_id)[0];
  const main_dl = state.product_datalevels.find(pdl => pdl.dltype === "main");
  const main_dlname = main_dl && main_dl.dlname;
  const dlname = ownProps.dlname;
  const product_id = ownProps.product_id;
  const uploaded_data = product.uploaded_data;
  const product_keys =
    (state.product_keys[ownProps.product_id] || {})[main_dlname] || [];
  return Object.assign(
    {},
    {},
    {
      product: product,
      product_id: product_id,
      dlname: dlname,
      uploaded_data: uploaded_data,
      product_keys: product_keys
    }
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getProducts,
      getUploadedData,
      updateUploadedData
    },
    dispatch
  );

const EditProductDataContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditProductData);

export default EditProductDataContainer;
