import {connect} from 'react-redux';
import ProductSetKeyVal from '../../components/product_sets/ProductSetKeyVal';
import {bindActionCreators} from 'redux';
import {
  toggleOverlay
 ,hideOverlay
 ,showOverlay
} from '../../actions/misc_actions'
import {createSetKey, updateSetKey} from '../../actions/product_sets'


const mapStateToProps = (state, ownProps) => {
  return Object.assign({}, ownProps, {overlay: state.overlays[ownProps.product_id+"/sets/"+ownProps.setno+"/"+ownProps.keyname] || {visible: false}});
};

const mapDispatchToProps = (dispatch) =>bindActionCreators({
 hideOverlay
 ,showOverlay
 ,createSetKey
 ,updateSetKey
},dispatch);


const ProductSetKeyValContainer = connect(mapStateToProps, mapDispatchToProps)(ProductSetKeyVal);

export default ProductSetKeyValContainer;