import { connect } from "react-redux";
import ViewProductSetList from "../../components/view_products/ViewProductSetList";
import { bindActionCreators } from "redux";

const mapStateToProps = (state, ownProps) => {
  let product = state.products.filter(e => e.id === ownProps.product_id)[0];
  return {
    product: product,
    product_sets: product.product_sets
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

const ViewProductSetListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewProductSetList);

export default ViewProductSetListContainer;
