import { connect } from 'react-redux'
import LyDl from '../../../components/layouts/lydl/LyDl';
import { getLyDatalevels } from '../../../actions/layouts';

import {bindActionCreators} from 'redux';
const mapStateToProps = (state, ownProps) => {
  const layouts = state.layouts.filter(p => p.id == ownProps.layout_id);
  const layout = layouts.filter(p => p.id == ownProps.layout_id)[0];
  let layout_datalevel = layout.datalevels.filter(dl => dl.layout_id == ownProps.layout_id && dl.dlname == ownProps.dlname)[0];
  return Object.assign({}, ownProps, {layout: layout, layout_datalevel: layout_datalevel });
};

const mapDispatchToProps = (dispatch) =>bindActionCreators({
},dispatch);

const LyDlContainer = connect(mapStateToProps, mapDispatchToProps)(LyDl);

export default LyDlContainer;