import React from 'react';
import { FormControl, Col, Row } from 'react-bootstrap';

export default class EditLyDlRel extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
    this.toggleExpand = this.toggleExpand.bind(this);
  }

  toggleExpand(e) {
    e.stopPropagation();
    this.setState(Object.assign({}, this.state, { expand: !this.state.expand }));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state !== nextProps) {
      this.setState(nextProps);
    }
  }

  render() {
    const dlRel = this.state.dlRel;
    const isExpand = this.state.expand;
    const datalevels = this.state.product_datalevels;
    return <div>
      <Row onClick={this.toggleExpand}>
        <Col md={2}>
          <FormControl
            componentClass="select"
            placeholder="please select"
            required="required"
            defaultValue={dlRel.pdlname}
            name={"lydlrel[" + this.state.index + "][pdlname]"}>
            {datalevels.map((datalevel, i) => {
              return <option key={i} value={datalevel.dlname}>{datalevel.dlname}</option>;
            }
            )}
          </FormControl>
        </Col>
        <Col md={2}>
          <FormControl
            componentClass="select"
            placeholder="please select"
            required="required"
            defaultValue={dlRel.cdlname}
            name={"lydlrel[" + this.state.index + "][cdlname]"}>
            {datalevels.map((datalevel, i) => {
              return <option key={i} value={datalevel.dlname}>{datalevel.dlname}</option>;
            }
            )}
          </FormControl>
        </Col>
        <Col md={2}>
          <FormControl
            componentClass="select"
            placeholder="please select"
            required="required"
            defaultValue={dlRel.style}
            name={"lydlrel[" + this.state.index + "][style]"}>
            {[["S", "SINGLE"], ["R", "REPEAT"], ["M", "MAIN"]].map((style, i) => {
              return <option key={i} value={style[0]}>{style[1]}</option>;
            }
            )}
          </FormControl>
        </Col>
        <Col md={2}>
          <input type="checkbox"
            className="form-control"
            name={"lydlrel[" + this.state.index + "][selected]"}
            defaultValue={1}
            defaultChecked={dlRel.cdlname != null} />
        </Col>
      </Row>
    </div>;
  }
};