import React, { Component } from "react";
import ReactDOM from "react-dom";

import { ENDPOINTS } from "../../constants/endpoints";

class StoragePayment extends Component {
  constructor(props) {
    super(props);
    this.state = { no_of_gb: 1, ...props };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {}

  componentDidMount() {
    // uncomment below line to generate a image in the background
    // this.props.generateImage(this.state.product_id, this.state.setno);
  }

  createOrder = (data, actions) => {
    if (this.state.pg_order_id) return this.state.pg_order_id;
    const payload = { no_of_gb: this.props.no_of_gb };
    let uri = ENDPOINTS.user_storages.create.uri;
    return fetch(uri, {
      method: ENDPOINTS.user_storages.create.method,
      headers: { "content-type": "application/json" },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({ pg_order_id: response.pg_order_id });
        return response.pg_order_id;
      });
  };

  onApprove = (data, actions) => {
    localStorage.setItem("isStoragePaid", "true");
    let uri = ENDPOINTS.user_storages.capture.uri;
    let storage_unit = this.props.storage_unit;
    return fetch(uri, {
      method: ENDPOINTS.user_storages.capture.method,
      headers: { "content-type": "application/json" },
      body: JSON.stringify({
        paypal_order_id: data.orderID,
        storage_unit: storage_unit,
      }),
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        alert("payment done successfully");
        window.location.reload();
        return data.pg_order_id;
      });
  };

  onSuccess = (details, data) => {
    alert("payment done successfully");
  };

  onCancel = (data, actions) => {
    alert("Payment cancelled");
  };

  onError = (data, actions) => {
    localStorage.removeItem("isStoragePaid", "true");
    alert("Something went wrong please try again later");
  };

  render() {
    const PaypalButton = window.paypal.Buttons.driver("react", {
      React,
      ReactDOM,
    });
    return (
      <PaypalButton
        {...this.props}
        createOrder={this.createOrder}
        onApprove={this.onApprove}
        // onApprove={e => this.onApprove(e)}
        onSuccess={this.onSuccess}
        onError={this.onError}
        onCancel={this.onCancel}
        // style={style}
      />
    );
  }
}

export default StoragePayment;
