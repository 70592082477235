import {connect} from 'react-redux'
import {getProducts} from '../actions'
import ProductList from '../components/ProductList'
import {bindActionCreators} from 'redux';


const mapStateToProps = (state, ownProps) => {
    const products = state.products;
    return {products: products}
};

const mapDispatchToProps = (dispatch) =>bindActionCreators({
  getProducts
},dispatch);

const ProductListContainer = connect(mapStateToProps, mapDispatchToProps)(ProductList);

export default ProductListContainer