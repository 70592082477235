import React from "react";
import { Row, Col, Form, Modal, Button } from "react-bootstrap";
import serialize from "form-serialize";

import EditLyDlRelContainer from "../../../containers/layouts/lydlrel/EditLyDlRelContainer";
import AddLyDlRelContainer from "../../../containers/layouts/lydlrel/AddLyDlRelContainer";
import { ENDPOINTS } from "../../../constants/endpoints";

export default class EditLyDlRelList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
    this._handleValidSubmit = this._handleValidSubmit.bind(this);
    this.hideOverlay = this.hideOverlay.bind(this);
    this.showOverlay = this.showOverlay.bind(this);
    this.addRelKey = this.addRelKey.bind(this);
  }

  addRelKey(e) {
    e.preventDefault();
    this.setState(Object.assign({}, this.state, { isAddVisible: true }));
  }

  hideOverlay(e) {
    e.stopPropagation();
    this.setState(Object.assign({}, this.state, { visible: false }));
  }

  showOverlay(e) {
    e.stopPropagation();
    this.setState(Object.assign({}, this.state, { visible: true }));
  }

  _handleValidSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    let formData = serialize(e.target, { hash: true, empty: true });
    const layout = this.state.layout;
    const product = this.state.product;
    formData.lydlrel.map((data) => {
      if (data.selected.length == 0) {
        let endpoint = Object.assign({}, ENDPOINTS.layout_datalevels_rel.delete, {});
        let url = endpoint.uri.replace(":layout_id", layout.id).replace(":pdlname", data.pdlname);
        url = url.replace(":cdlname", data.cdlname);
        data.product_id = product.id;
        this.props.deleteLyDLRel(url, endpoint.method, data);
        // delete
      } else {
        // create
        let endpoint = Object.assign({}, ENDPOINTS.layout_datalevels_rel.create, {});
        let url = endpoint.uri.replace(":layout_id", layout.id);
        data.product_id = product.id;
        this.props.createLyDLRel(url, endpoint.method, data);
      }
    });
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state !== nextProps) {
      this.setState(nextProps);
    }
  }

  render() {
    const layout = this.state.layout;
    const product = this.state.product;
    const isVisible = this.state.visible;
    const isAddVisible = this.state.isAddVisible;
    const product_datalevels = this.state.product_datalevels;
    const datalevel_relations = layout.datalevel_relations;
    if (product_datalevels == null) {
      return null;
    }
    let children = [];
    if (isVisible) {
      for (let i = 0; i < product_datalevels.length; i++) {
        let lyDlRel = datalevel_relations[i] || {};
        children.push(
          <EditLyDlRelContainer
            index={i}
            key={product_datalevels[i].id}
            layout_id={layout.id}
            dlrel_id={lyDlRel.id}
            product_id={product.id}
          />
        );
      }
    }
    return (
      <li>
        <a onClick={this.showOverlay}>Manage Layout relationships</a>
        <Modal size={"lg"} show={isVisible} onHide={this.hideOverlay}>
          <Form className="override_form" onSubmit={this._handleValidSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Manage Layout datalevel relationships</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <Row>
                  <Col md={2}>Parent Data Level</Col>
                  <Col md={2}>Child Data Level</Col>
                  <Col md={2}>Datalevel style</Col>
                  <Col md={2}>Action</Col>
                </Row>
                {children}
              </div>
            </Modal.Body>
            <li>
              <a onClick={this.addRelKey}>Add new</a>
            </li>
            <AddLyDlRelContainer
              isVisible={isAddVisible}
              dlRel={{}}
              layout_id={layout.id}
              product_id={product.id}
            />
            <Modal.Footer>
              <Button type="submit">Save</Button>
              <Button onClick={this.hideOverlay}>Cancel</Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </li>
    );
  }
}
