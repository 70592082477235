import React from "react";
import LayoutContainer from "../../containers/layouts/LayoutContainer";

export default class LayoutList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state !== nextProps) {
      this.setState(nextProps);
    }
  }

  UNSAFE_componentWillMount() {
    this.props.getLayouts(this.state.product.id);
  }

  render() {
    let view = [];
    for (let i = 0; i < this.state.layouts.length; i++) {
      view.push(
        <LayoutContainer
          key={this.state.layouts[i].id}
          layout_id={this.state.layouts[i].id}
          product_id={this.state.product.id}
          role={this.state.role}
        />
      );
    }
    return <ul>{view}</ul>;
  }
}
