import { connect } from "react-redux";
import OrderList from "../../components/orders/OrderList";
import { getProducts } from "../../actions";
import { getOrders } from "../../actions/orders";
import { bindActionCreators } from "redux";

const mapStateToProps = state => {
  return {
    products: state.products
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getProducts,
      getOrders
    },
    dispatch
  );

const OrderListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderList);

export default OrderListContainer;
