import React from "react";
import LySlotForContainer from "../../../containers/layouts/lyslotfors/LySlotForContainer";



export default class LySlotForList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
    this.toggleExpand = this.toggleExpand.bind(this);
  }

  toggleExpand(e) {
    e.stopPropagation();
    this.setState(
      Object.assign({}, this.state, { expand: !this.state.expand })
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state !== nextProps) {
      this.setState(nextProps);
    }
  }

  render() {
    const layout = this.state.layout;
    const product = this.state.product;
    const dlnames = this.state.dlnames;
    const slotforcode = this.state.slotforcode;
    const role = this.state.role;
    if (layout == null || dlnames == null) {
      return null;
    }
    const isExpand = true;
    // let list = {dlname: nil, children: []};
    let list = [];
    if (isExpand) {
      for (let i = 0; i < dlnames.length; i++) {
        let dlname = dlnames[i];
        let list_dlname = list.filter((r) => r.dlname == dlname)[0];
        list_dlname = list_dlname || { dlname: dlname, children: [] };
        list_dlname.children.push(
          <LySlotForContainer
            key={"slotfor_" + i}
            product_id={product.id}
            layout_id={layout.id}
            dlname={dlname}
            slotforcode={slotforcode}
            role={role}
          />
        );
        list.push(list_dlname);
      }
            
      
    }
    if (list.length == 0) return null;
    else
      return (
        <React.Fragment>
          {list.map((row, i) => {
            return row.children;
          })}
        </React.Fragment>
      );
  }
}
