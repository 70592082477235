export const SHOW_OVERLAY = "SHOW_OVERLAY";
export const HIDE_OVERLAY = "HIDE_OVERLAY";
export const TOGGLE_OVERLAY = "TOGGLE_OVERLAY";
export const SELECTED_DATALEVEL = "SELECTED_DATALEVEL_OVERLAY";
export const SELECTED_DATALEVEL_ATTRIBUTE = "SELECTED_DATALEVEL_ATTRIBUTE_OVERLAY";
export const SELECTED_ORDER = "SELECTED_ORDER_OVERLAY";
export const EXPAND_SECTION = "EXPAND_SECTION";
export const HIDE_SECTION = "HIDE_SECTION";

export const showOverlay = (id) => {
  return { type: SHOW_OVERLAY, id: id };
};
export const hideOverlay = (id) => {
  return { type: HIDE_OVERLAY, id: id };
};

export const expandSection = (id) => {
  return { type: EXPAND_SECTION, id: id };
}

export const hideSection = (id) => {
  return { type: HIDE_SECTION, id: id };
}

export const toggleOverlay = (id) => {
  return { type: TOGGLE_OVERLAY, id: id };
};

export const selectDatalevel = (id, datalevel) => {
  return { type: SELECTED_DATALEVEL, id: id, payload: datalevel };
}

export const selectDatalevelAttribute = (id, attribute) => {
  return { type: SELECTED_DATALEVEL_ATTRIBUTE, id: id, payload: attribute };
}

export const selectKeyOrder = (id, order) => {
  return { type: SELECTED_ORDER, id: id, payload: order };
}

export const toggleProductSets = (id) => {
  return { type: TOGGLE_PRODUCTSET, id: id };
}