import { connect } from "react-redux";
import { createProduct, getCategories } from "../actions";
import { hideOverlay, showOverlay } from "../actions/misc_actions";
import Admin from "../components/Admin/Admin";
import { bindActionCreators } from "redux";

const mapStateToProps = (state, ownProps) => {
  return Object.assign({}, ownProps, {
    overlay: state.overlays["addProduct"] || { visible: false },
    categories: state.categories,
  });
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createProduct,
      hideOverlay,
      showOverlay,
      getCategories,
    },
    dispatch
  );

const AdminContainer = connect(mapStateToProps, mapDispatchToProps)(Admin);

export default AdminContainer;
