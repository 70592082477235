import { connect } from "react-redux";
import ProductSetCount from "../../components/product_sets/ProductSetCount";
import { bindActionCreators } from "redux";
import {
  toggleOverlay,
  hideOverlay,
  showOverlay
} from "../../actions/misc_actions";
import {
  createSetOccurenceCount,
  updateSetOccurenceCount
} from "../../actions/product_sets";

const mapStateToProps = (state, ownProps) => {
  return Object.assign({}, ownProps, { overlays: state.overlays });
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      hideOverlay,
      showOverlay,
      createSetOccurenceCount,
      updateSetOccurenceCount
    },
    dispatch
  );

const ProductSetCountContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductSetCount);

export default ProductSetCountContainer;
