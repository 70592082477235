import React from "react";
import {
  Col,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Modal,
  Fade,
  utils,
  Button,
  Table,
} from "react-bootstrap";
import { formDataToJSON } from "../../../adukku_utils/array";
import { ENDPOINTS } from "../../../constants/endpoints";

export default class ManageLyDl extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
    this.toggleExpand = this.toggleExpand.bind(this);
    this._handleValidSubmit = this._handleValidSubmit.bind(this);
    this.hideLayoutDLOverlay = this.hideLayoutDLOverlay.bind(this);
    this.showLayoutDLOverlay = this.showLayoutDLOverlay.bind(this);
  }

  _handleValidSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    let formData = formDataToJSON(new FormData(e.target));
    const datalevels = this.state.datalevels;
    let endpoint = ENDPOINTS["layout_datalevels"]["create"];
    let url = endpoint.uri;
    let method = endpoint.method;
    let data = {};
    let product = this.state.product;
    let layout = this.state.layout;
    for (let i = 0; i < datalevels.length; i++) {
      let datalevel = datalevels[i];
      data["dlname"] = formData["dl[" + i + "][dlname]"];
      data["product_id"] = product.id;
      data["layout_id"] = layout.id;
      if (formData["dl[" + i + "][selected]"] == null && datalevel.saved) {
        let endpoint = ENDPOINTS["layout_datalevels"]["delete"];
        let url = endpoint.uri;
        let method = endpoint.method;
        this.props.deleteLyDL(url, method, data);
      }
      if (formData["dl[" + i + "][selected]"] != null && !datalevel.saved) {
        let endpoint = ENDPOINTS["layout_datalevels"]["create"];
        let url = endpoint.uri;
        let method = endpoint.method;
        this.props.createLyDL(url, method, data);
      }
    }
    this.hideLayoutDLOverlay(e);
  }

  toggleExpand(e) {
    e.stopPropagation();
    this.setState(Object.assign({}, this.state, { expand: !this.state.expand }));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state !== nextProps) {
      this.setState(nextProps);
    }
  }

  hideLayoutDLOverlay(e) {
    e.stopPropagation();
    this.setState(Object.assign({}, this.state, { visible: false }));
  }

  showLayoutDLOverlay(e) {
    e.stopPropagation();
    this.setState(Object.assign({}, this.state, { visible: true }));
  }

  render() {
    const layout = this.state.layout;
    const isVisible = this.state.visible;
    const datalevels = this.state.datalevels;
    let tbodyDOM = [];
    for (let i = 0; i < datalevels.length; i++) {
      let datalevel = datalevels[i];
      tbodyDOM.push(
        <tr>
          <FormControl
            type="hidden"
            name={"dl[" + i + "][dlname]"}
            defaultValue={datalevel.dlname}
            required="required"
          />
          <td>{datalevel.dlname}</td>
          <td>
            <input
              type="checkbox"
              className="form-control"
              name={"dl[" + i + "][selected]"}
              defaultValue="1"
              defaultChecked={datalevel.saved}
              style={{ boxShadow: "none", height: "20px" }}
            />
          </td>
        </tr>
      );
    }
    return (
      <li>
        <a onClick={this.showLayoutDLOverlay}>{this.props.labelText}</a>
        <Modal show={isVisible} onHide={this.hideLayoutDLOverlay}>
          <Form onSubmit={this._handleValidSubmit} className="override_form">
            <Modal.Header closeButton>
              <Modal.Title>Manage Layout Data Levels</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Table className="react-table override_form">
                <thead>
                  <tr>
                    <th>Product Data Levels</th>
                    <th className="text-center">Add</th>
                  </tr>
                </thead>
                <tbody>{tbodyDOM}</tbody>
              </Table>
            </Modal.Body>
            <Modal.Footer>
              <Button type="submit">Save</Button>
              {/* <Button onClick={this.hideLayoutDLOverlay}>Cancel</Button> */}
            </Modal.Footer>
          </Form>
        </Modal>
      </li>
    );
  }
}
