import { combineReducers } from "redux";
import { ADD_ALL_KEYS, ADD_KEY, DELETE_KEY } from "../actions/product_keys";

// action -> { product_id, payload}
const X = (state = {}, action) => {
  let newState = {};
  switch (action.type) {
    case ADD_ALL_KEYS:
      let keys = action.payload.reduce((result, key) => {
        if (result[key.dlname] == null) {
          result[key.dlname] = [];
        }
        result[key.dlname].push(key);
        return result;
      }, {});
      newState[action.id] = keys;
      return Object.assign({}, state, newState);
    case ADD_KEY:
      return { ...state, ...newState };
    case DELETE_KEY:
      return { ...state, ...newState };
    default:
      return state;
  }
};
export default X;
